// @owners { team: patients-team }
import {
  ONBOARD_STATE_TO_ROUTE,
  type Route,
  currentStep,
  nextOnboardingRoute,
  previousOnboardingRoute,
} from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { ROUTE_TO_URL, URL_TO_ROUTE } from '~shared/features/onboarding/helpers/routeToPath';
import { type ReduxStateShared } from '~shared/types';

export function nextUrl({
  state,
  currentRoute,
  useOnboardingRoutingV2,
  desiredRoute,
}: {
  state: ReduxStateShared;
  currentRoute: Route;
  useOnboardingRoutingV2: boolean;
  desiredRoute?: Route;
}): string {
  const nextRoute = nextOnboardingRoute({ state, currentRoute, desiredRoute, useOnboardingRoutingV2 });

  // TODO: handle when nextRoute === currentRoute
  return ROUTE_TO_URL[nextRoute];
}

export function previousUrl({
  state,
  currentRoute,
  useOnboardingRoutingV2,
}: {
  state: ReduxStateShared;
  currentRoute: Route;
  useOnboardingRoutingV2: boolean;
}): string | null | undefined {
  const previousRoute = previousOnboardingRoute({ state, currentRoute, useOnboardingRoutingV2 });

  // TODO: handle when previousRoute === currentRoute
  if (previousRoute) {
    return ROUTE_TO_URL[previousRoute];
  }

  return null;
}

export function currentUrlStep({ useOnboardingRoutingV2, url }: { useOnboardingRoutingV2: boolean; url: string }) {
  return currentStep({ route: URL_TO_ROUTE[url], useOnboardingRoutingV2 });
}

export function urlFromOnboardState(onboardState: string | null | undefined): string | null | undefined {
  if (onboardState) {
    const route = ONBOARD_STATE_TO_ROUTE[onboardState];

    if (route) {
      return ROUTE_TO_URL[route] || null;
    }
  }

  return null;
}
