// @owners { team: patients-team }
import { Experimentation } from '@alto/experimentation';
import React from 'react';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { getAvailableManualCoupon } from '~shared/features/pricing/selectors/getAvailableManualCoupon';
import { useSelectorShared } from '~shared/store';
import { CouponCard } from './CouponCard';
import { ManualCouponInfoBox } from './ManualCouponInfoBox';

type Props = {
  readonly prescriptionID: number;
  readonly prescriptionUserID: number;
};

export const AddCouponCard = ({ prescriptionID, prescriptionUserID }: Props) => {
  const { value: isCopayCardEnabled } = Experimentation.useFeatureFlag('pfizer_copay_card');
  const availableManualCoupon = useSelectorShared((state) => getAvailableManualCoupon(state, { prescriptionID }));
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));
  const showCouponCard = isCopayCardEnabled && availableManualCoupon?.is_auto_assigned;

  if (!availableManualCoupon || !prescription) return null;

  if (showCouponCard) {
    return (
      <CouponCard
        couponID={availableManualCoupon.id}
        prescription={prescription}
      />
    );
  }

  return (
    <ManualCouponInfoBox
      prescriptionID={prescriptionID}
      prescriptionUserID={prescriptionUserID}
      coupon={availableManualCoupon}
    />
  );
};
