// @owners { team: patients-team }
import { ActionSheetContext, ActionSheetV2, Button } from '@alto/design-system';
import React, { useContext } from 'react';
import type { OriginName } from '~shared/lib/analytics/src/getOrigin';
import { AddressFormActionSheet } from '../../address';

type Props = {
  readonly origin?: OriginName;
};

export const MissingAddressActionSheet = ({ origin }: Props) => {
  const { overrideActionSheetStack } = useContext(ActionSheetContext);

  const handleAddressPress = () => {
    overrideActionSheetStack([
      <AddressFormActionSheet
        key="AddressFormActionSheet"
        origin={origin}
      />,
    ]);
  };

  return (
    <ActionSheetV2
      title="Missing address"
      analyticsName="missing address"
      description="Please add an address to your account to see your available scheduling options."
      buttons={[
        <Button
          key="add-address"
          label="Add address"
          onPress={handleAddressPress}
        />,
      ]}
    />
  );
};
