// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import {
  Button,
  Card,
  LgPadding,
  LoadingEmptyState,
  SecondaryPage,
  Toast,
  ToastContext,
  XlPadding,
} from '@alto/design-system';
import { useQueryClient } from '@tanstack/react-query';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { goBack, push } from 'react-router-redux';
// eslint-disable-next-line  import/no-deprecated
import { clearInsuranceError, createPhotoInsurance } from '~shared/actions/insurances';
import getIsPhotoUpload from '~shared/features/insurances/helpers/getIsPhotoUpload';
import { getInsuranceByID } from '~shared/features/insurances/selectors/getInsuranceById';
import { getEditingOnboardingInsuranceID } from '~shared/features/onboarding/selectors/getOnboarding';
import { getSelectedUserID } from '~shared/features/ui/selectors/getUsers';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { queries } from '~shared/queries/query-keys';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { InfoRequestHeader } from './InfoRequestHeader';
import { NavBarLogo } from './NavBarLogo';
import { useQueryInsurancePageContent } from '~web/features/info-request/hooks';
import { useStatusBarColor } from '~web/features/info-request/hooks/useStatusBarColor';
import { InsurancePhotoUploadButtons } from '~web/features/insurances/components/InsurancePhotoUploadButtons';

type Props = {
  params: {
    externalUUID: string;
    hubReferralID: string;
  };
};

type InsurancePhotoInputValues = {
  image_url: File[];
  image_url_2: File[];
};

export const InsuranceInputPhotoPageComponent = (props: Props) => {
  const { hubReferralID: hubReferralIDParam } = props.params || {};
  const hubReferralID = Number(hubReferralIDParam || 0);
  const queryClient = useQueryClient();
  const dispatch = useDispatchShared();
  const { addToast } = useContext(ToastContext);
  const { trackEvent, trackPageView } = useAnalytics();
  const loading = useSelectorShared((state) => state.ui.loading.createPhotoInsuranceLoading);
  const insuranceID = useSelectorShared(getEditingOnboardingInsuranceID);
  const insurance = useSelectorShared((state) => getInsuranceByID(state, insuranceID));
  const createPhotoInsuranceError = useSelectorShared((state) => state.ui.errors.createPhotoInsuranceError);
  const currentUserID = useSelectorShared(getCurrentUserID);
  const selectedUserID = useSelectorShared(getSelectedUserID);
  const currentPath = window.location.pathname;
  const nextURL = currentPath.replace('/insurance-input-photo/', '/insurance/');

  const userID = selectedUserID || currentUserID;
  const viewOnly = getIsPhotoUpload(insurance);
  const { isFetching, headerLogoUrl, redirectURL } = useQueryInsurancePageContent({
    hubReferralID,
  });

  const { setValue, handleSubmit } = useForm({
    defaultValues: {
      image_url: [] as File[],
      image_url_2: [] as File[],
    },
  });

  const handleGoBack = () => {
    dispatch(goBack());
  };

  const onSubmit = async ({ image_url, image_url_2 }: InsurancePhotoInputValues) => {
    const frontImage = image_url[0];
    const backImage = image_url_2[0];

    trackEvent({
      event: EVENTS.INFO_REQUEST__SAVE_INSURANCE_CLICKED,
      params: {
        hub_referral_id: hubReferralID,
        insurance_input_type: 'photo',
        has_front_image: !!frontImage,
        has_back_image: !!backImage,
        next_url: nextURL,
      },
    });

    if (!frontImage) {
      addToast(<Toast variant="error">Please add a photo for the front of your insurance card.</Toast>);
      return;
    }
    if (!backImage) {
      addToast(<Toast variant="error">Please add a photo for the back of your insurance card.</Toast>);
      return;
    }

    dispatch(clearInsuranceError());
    // @ts-expect-error using createPhotoInsurance wrong here but it's out-of-scope of this PR to fix it
    // eslint-disable-next-line import/no-deprecated
    const insurance = await dispatch(createPhotoInsurance([frontImage, backImage], userID, hubReferralID));

    if (typeof insurance !== 'boolean') {
      trackEvent({
        event: EVENTS.INSURANCE_ADDED,
        params: {
          type: 'photo',
          source: 'hub-referral-info-request',
          entity_name: insurance.insurance_plan_name ?? '',
          next_url: nextURL,
        },
      });
      queryClient.invalidateQueries({ queryKey: queries.insurances.fetchAll._def });
      addToast(<Toast variant="success">Prescription insurance successfully added.</Toast>);

      dispatch(push(nextURL));
    } else {
      addToast(
        <Toast variant="error">{createPhotoInsuranceError?.message ?? 'Unable to save insurance card photos'}</Toast>,
      );
      trackEvent({
        event: EVENTS.INSURANCE_ADD_FAILED,
        params: {
          type: 'photo',
          source: 'hub-referral-info-request',
          next_url: nextURL,
        },
      });
    }
  };

  useStatusBarColor(COLORS.BACKGROUND_COLORS.GREY_LIGHTEST);

  useEffect(() => {
    trackPageView({
      event: EVENTS.INFO_REQUEST__INSURANCE_INPUT_PHOTO_PAGE_VIEWED,
      params: { hubReferralID },
    });
  }, [trackPageView, hubReferralID]);

  useEffect(() => {
    if (redirectURL) {
      dispatch(push(redirectURL));
    }
  }, [redirectURL, dispatch]);

  if (isFetching) {
    <XlPadding>
      <LoadingEmptyState />
    </XlPadding>;
  }

  return (
    <SecondaryPage
      footerPlacementContext="webScreenWithoutPolicyFooter"
      withoutWebFramingElementHeights
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      dismissIcon="chevronleft"
      onDismiss={handleGoBack}
      NavBarCenterContent={headerLogoUrl ? <NavBarLogo illustrationSrc={headerLogoUrl} /> : undefined}
      HeaderContent={
        <InfoRequestHeader
          title="Upload a photo of your insurance card"
          subtitle="Include both sides of your benefits card, and take the photos on a neutral background."
        />
      }
      buttons={[
        <Button
          key="cancel"
          width="full"
          onPress={handleGoBack}
          label="Cancel"
          type="tertiary"
        />,
        <Button
          key="save"
          width="full"
          loading={loading}
          onPress={handleSubmit(onSubmit)}
          label="Save insurance"
        />,
      ]}
    >
      <Card>
        <LgPadding>
          <InsurancePhotoUploadButtons
            loading={loading}
            onFormChange={setValue}
            initialFrontImageUrl={insurance?.image_url ?? ''}
            initialBackImageUrl={insurance?.image_url_2 ?? ''}
            disabled={viewOnly}
          />
        </LgPadding>
      </Card>
    </SecondaryPage>
  );
};

export const InsuranceInputPhotoPage = withRouter(InsuranceInputPhotoPageComponent);
