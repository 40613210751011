// @owners { team: patients-team }
import {
  Body,
  Button,
  Card,
  LgPadding,
  LoadingEmptyState,
  MdSpacing,
  SecondaryPage,
  XlPadding,
} from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { useQueryMedicalInfoConfigs } from '@alto/features';
import { useNavigation } from '@alto/navigation';
import { type AllergenParams } from '@alto/scriptdash/alto/wunderbar/patients/types/v1/allergen_params';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { type FormikProps } from 'formik';
import React from 'react';
import { push } from 'react-router-redux';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { type AsyncValue } from '~shared/helpers/formatAsyncSelectValues';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { FAQs } from '~web/features/onboarding/components/redesign/FAQs';
import { OnboardingHeader } from '~web/features/onboarding/components/redesign/OnboardingHeader';
import { OnboardingNavBarLogo } from '~web/features/onboarding/components/redesign/OnboardingNavBarLogo';
import { previousUrl as getOnboardingPreviousUrl } from '~web/features/onboarding/helpers/urlHandler';
import { AllergensFields } from '~web/features/profile/components/AllergensFields';
import MedicalConditionsField from '~web/features/profile/components/MedicalConditionsField';

type Values = {
  allergies: string;
  allergens: AllergenParams[];
  medical_conditions: string;
  onboarding_other_scripts: string;
  other_scripts_list: (AsyncValue | null | undefined) | (AsyncValue[] | null | undefined);
};

export type Props = {
  readonly formikProps: FormikProps<Values>;
};

export const OnboardingMedicalInfoPage = ({ formikProps }: Props) => {
  const { updateOnboardingUserLoading, fetchUsersLoading } = useSelectorShared(getLoading);
  const loading = updateOnboardingUserLoading || fetchUsersLoading;
  const dispatch = useDispatchShared();
  const { getCurrentRouteName } = useNavigation();
  const routeName = getCurrentRouteName() || '';
  const currentRoute = routeFromPath(routeName);
  const { value: shouldRemoveMedVerification } = Experimentation.useFeatureFlag('remove_onboarding_med_verification');

  const onboardingPreviousUrl = useSelectorShared((state) =>
    getOnboardingPreviousUrl({ state, currentRoute, useOnboardingRoutingV2: shouldRemoveMedVerification }),
  );
  const { headerBackgroundColor, headerLogoUrl, isFetching } = useQueryMedicalInfoConfigs();
  const onBack = () => {
    if (onboardingPreviousUrl) {
      dispatch(push(onboardingPreviousUrl));
    }
  };

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  return (
    <SecondaryPage
      withoutWebFramingElementHeights
      onDismiss={onBack}
      dismissIcon="chevronleft"
      buttons={[
        <Button
          type="primary"
          key="continue"
          label="Continue"
          onPress={formikProps.handleSubmit}
        />,
      ]}
      footerPlacementContext="webScreenWithoutPolicyFooter"
      headerBackgroundColor={headerBackgroundColor}
      HeaderContent={
        <OnboardingHeader
          title="For your safety, a few health questions"
          subtitle={
            <Body center>Our pharmacists review every order, and use this info to ensure your well-being.</Body>
          }
        />
      }
      NavBarCenterContent={<OnboardingNavBarLogo illustrationSrc={headerLogoUrl} />}
      NavBarRightPressable={<FAQs />}
    >
      {loading ? (
        <LoadingEmptyState />
      ) : (
        <Card>
          <LgPadding>
            <AllergensFields />
            <MdSpacing />
            <MedicalConditionsField />
          </LgPadding>
        </Card>
      )}
    </SecondaryPage>
  );
};
