// @owners { team: patients-team }
import { BORDERS, COLORS, SPACING, TYPOGRAPHY } from '@alto/design-library-tokens';
import { Body, Column, Description, MdSpacing, Row, XxsSpacing } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { ParentWidthColumn } from '@alto/pocky';
import {
  type PrescriptionStatus,
  PrescriptionStatusMap,
} from '@alto/scriptdash/alto/patient_app/types/v1/prescription_status';
import React from 'react';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import { formatDollarsWithPlaceholder } from '~shared/helpers/currency';
import { MedicationCTA } from '~web/features/homescreen/sections/MedicationCTA';
import IconPill from '~web/images/bluePill.svg';

const QuantityRowStyledText = styledNative(Description)`
  font-size: ${TYPOGRAPHY.TEXT.BODY.SM.px};
  font-weight: 400;
`;

const PrescriptionImage = styled.img`
  width: ${SPACING.STATIC.LG.value + SPACING.STATIC.SM.value}px;
  height: ${SPACING.STATIC.LG.value + SPACING.STATIC.SM.value}px;
  border-radius: ${BORDERS.RADIUS.MD.px};
`;

const StyledNeedleRow = styled(ParentWidthColumn)`
  padding: ${SPACING.STATIC.MD.px} ${SPACING.STATIC.NONE.px};
`;

export type NeedleRowPrescription = {
  id: number;
  status: PrescriptionStatus;
  image_url: string;
  quantity: number;
  price: number | null | undefined;
  name: string;
  family_member_name: string;
  auto_refill_eligible: boolean;
  manual_coupon_eligible: boolean;
};

type Props = {
  readonly prescriptions: NeedleRowPrescription[];
};

const NeedleRowList = ({ prescriptions }: Props) => {
  const { READY } = PrescriptionStatusMap;

  const img = (prescription: { image_url: string }) => {
    return prescription.image_url ? prescription.image_url : IconPill;
  };

  const price = (prescription: { status: PrescriptionStatus; price: number | null | undefined }) => {
    return (
      prescription.status === READY && (
        <QuantityRowStyledText color={COLORS.TEXT_COLORS.GREY}>
          {typeof prescription.price === 'number'
            ? formatDollarsWithPlaceholder(prescription.price, '')
            : 'View Price in Cart'}
        </QuantityRowStyledText>
      )
    );
  };

  const quantity = (prescription: NeedleRowPrescription) => {
    return (
      prescription.quantity && (
        <QuantityRowStyledText color={COLORS.TEXT_COLORS.GREY}>Qty: {prescription.quantity}</QuantityRowStyledText>
      )
    );
  };

  return (
    <>
      {prescriptions.map((prescription: NeedleRowPrescription) => (
        <StyledNeedleRow
          evenlySpaceContent
          key={prescription.id}
        >
          <Row>
            <Column>
              <PrescriptionImage src={img(prescription)} />
            </Column>
            <MdSpacing />
            <Column>
              <Body color={COLORS.TEXT_COLORS.PRIMARY}>{prescription.name}</Body>
              <XxsSpacing />
              {quantity(prescription)}
              {prescription.quantity ? <XxsSpacing /> : null}
              {price(prescription)}
            </Column>
          </Row>
          <Column centerVertically>
            <MedicationCTA
              fullWidthButton
              prescription={prescription}
            />
          </Column>
        </StyledNeedleRow>
      ))}
    </>
  );
};

export default NeedleRowList;
