// @owners { team: patients-team }
import { useEffect } from 'react';

export const useStatusBarColor = (color: string) => {
  useEffect(() => {
    const updateStatusBarColor = (color: string) => {
      let themeColorMetaTag = document.querySelector('meta[name="theme-color"]');

      if (!themeColorMetaTag) {
        themeColorMetaTag = document.createElement('meta');
        themeColorMetaTag.setAttribute('name', 'theme-color');
        document.head.appendChild(themeColorMetaTag);
      }

      if (themeColorMetaTag instanceof HTMLMetaElement) {
        themeColorMetaTag.content = color;
      }
    };

    updateStatusBarColor(color);

    return () => {
      updateStatusBarColor('#FFFFFF');
    };
  }, [color]);
};
