// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { AltoIcon, Card, ListItem, LoadingEmptyState, MdSpacing, SecondaryPage, XlPadding } from '@alto/design-system';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { goBack, push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { declineInsurance } from '~shared/actions/insurances';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { InfoRequestHeader } from './InfoRequestHeader';
import { NavBarLogo } from './NavBarLogo';
import { useQueryInsurancePageContent } from '~web/features/info-request/hooks';
import { useStatusBarColor } from '~web/features/info-request/hooks/useStatusBarColor';

type Props = {
  params: {
    readonly externalUUID: string;
    readonly hubReferralID: string;
  };
};

const InsuranceInputPageComponent = ({ params }: Props) => {
  const { hubReferralID: hubReferralIDParam } = params || {};
  const hubReferralID = Number(hubReferralIDParam || 0);
  const { trackEvent, trackPageView } = useAnalytics();
  const dispatch = useDispatchShared();
  const currentPath = window.location.pathname;
  const declineInsuranceLoading = useSelectorShared((state) => state.ui.loading.declineInsuranceLoading);
  const { isFetching, headerLogoUrl, nextURL, redirectURL } = useQueryInsurancePageContent({
    hubReferralID,
  });

  const handleGoBack = () => {
    dispatch(goBack());
  };

  const handleUploadPhoto = () => {
    trackEvent({
      event: EVENTS.INFO_REQUEST__UPLOAD_PHOTO_CLICKED,
    });

    const uploadPhotoURL = currentPath.replace('/insurance-input/', '/insurance-input-photo/');
    dispatch(push(uploadPhotoURL));
  };

  const handleEnterManually = () => {
    trackEvent({
      event: EVENTS.INFO_REQUEST__ENTER_INSURANCE_MANUALLY_CLICKED,
    });

    const enterManualURL = currentPath.replace('/insurance-input/', '/insurance-input-manual/');
    dispatch(push(enterManualURL));
  };

  const handleDeclineInsurance = async () => {
    trackEvent({
      event: EVENTS.INFO_REQUEST__DECLINE_INSURANCE_CLICKED,
      params: { hub_referral_id: hubReferralID, next_url: nextURL },
    });

    // eslint-disable-next-line import/no-deprecated
    const success = await dispatch(declineInsurance());

    if (success) {
      trackEvent({
        event: EVENTS.INFO_REQUEST__DECLINE_INSURANCE_SUCCEEDED,
        params: { hub_referral_id: hubReferralID, next_url: nextURL },
      });
    } else {
      trackEvent({
        event: EVENTS.INFO_REQUEST__DECLINE_INSURANCE_FAILED,
        params: { hub_referral_id: hubReferralID, next_url: nextURL },
      });
    }

    if (success && nextURL) {
      dispatch(push(nextURL));
    }
  };

  useStatusBarColor(COLORS.BACKGROUND_COLORS.GREY_LIGHTEST);

  useEffect(() => {
    trackPageView({
      event: EVENTS.INFO_REQUEST__INSURANCE_INPUT_PAGE_VIEWED,
      params: { hubReferralID },
    });
  }, [trackPageView, hubReferralID]);

  useEffect(() => {
    if (redirectURL) {
      dispatch(push(redirectURL));
    }
  }, [redirectURL, dispatch]);

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  return (
    <SecondaryPage
      footerPlacementContext="webScreenWithoutPolicyFooter"
      withoutWebFramingElementHeights
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      dismissIcon="chevronleft"
      onDismiss={handleGoBack}
      NavBarCenterContent={headerLogoUrl ? <NavBarLogo illustrationSrc={headerLogoUrl} /> : undefined}
      HeaderContent={
        <InfoRequestHeader
          title="Add prescription insurance"
          subtitle='Please upload your prescription insurance information so we can verify your coverage. Typically your prescription insurance card will have an "Rx" symbol, and "BIN" and "PCN" numbers.'
        />
      }
    >
      <Card>
        <ListItem
          title="Upload a photo"
          LeftContent={<AltoIcon name="camera-duo" />}
          RightContent={
            <AltoIcon
              name="chevronright-small"
              type="grey"
            />
          }
          onPress={handleUploadPhoto}
        />
      </Card>
      <MdSpacing />
      <Card>
        <ListItem
          title="Enter it manually"
          LeftContent={<AltoIcon name="signpaper-duo" />}
          RightContent={
            <AltoIcon
              name="chevronright-small"
              type="grey"
            />
          }
          onPress={handleEnterManually}
        />
      </Card>
      <>
        <MdSpacing />
        <Card>
          <ListItem
            title="I don't have any benefits"
            LeftContent={<AltoIcon name="shieldslash-duo" />}
            RightContent={
              <AltoIcon
                name="chevronright-small"
                type="grey"
              />
            }
            onPress={handleDeclineInsurance}
            loading={declineInsuranceLoading}
          />
        </Card>
      </>
    </SecondaryPage>
  );
};

export const InsuranceInputPage = withRouter(InsuranceInputPageComponent);
