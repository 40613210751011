// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { AltoIcon, Body, Column, Description, LgPadding, Link, Row, Separator, SmSpacing } from '@alto/design-system';
import {
  AddCouponCard,
  BackorderedTag,
  ORDER_TYPE_CONTEXT,
  OrderTypeSelectionSection,
  useBackorderedMedication,
  useEditEssential,
  useQueryEssentials,
  useRemoveFromCart,
} from '@alto/features';
import { type CartItem } from '@alto/orders_api/types/v1/cart_item';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import { type AncillaryItemPrescription } from '@alto/scriptdash/alto/prescriptions/types/v3/ancillary_item_prescription';
import React, { useCallback, useState } from 'react';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { prescriptionToItemKey } from '~shared/features/checkout/helpers';
import { useCourierAgeRestriction } from '~shared/features/checkout/hooks/useCourierAgeRestriction';
import getAllowPriceOptionChange from '~shared/features/checkout/selectors/getAllowPriceOptionChange';
import { getOrderIndex } from '~shared/features/checkout/selectors/getOrdersByIndex';
import { getEssentialsAddress } from '~shared/features/essentials/selectors/getEssentialsAddress';
import { getPrescriptionByID } from '~shared/features/prescriptions/selectors/getPrescriptionByID';
import { getPriceOptionsForPrescriptionInOrder } from '~shared/features/price-options/selectors/getPriceOptionsForPrescriptionInOrder';
import { getCartSelectedPricingInfoForPrescription } from '~shared/features/pricing/selectors/getPricingInfoForPrescription';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { getIsFertilityUser } from '~shared/features/users/selectors/getIsFertilityUser';
import { getIsProgynyUser } from '~shared/features/users/selectors/getIsProgynyUser';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { formatQuantity, isControl } from '~shared/helpers/helper';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useSelectorShared } from '~shared/store';
import ClickableAltoIcon from '~web/components/ClickableAltoIcon';
import { PricingSection } from '~web/features/checkout/components/PricingSection';
import UserTag from '~web/features/family-accounts/components/UserTagContainer';
import { AncillaryItemsTag } from '~web/features/my-meds/components/AncillaryItemsTag';
import { NextAvailableDateInfoRow } from '~web/features/my-meds/components/NextAvailableDateInfoRow';
import { PhotoIDRequiredTag } from '~web/features/my-meds/components/PhotoIDRequiredTag';
import RefrigerationRequiredTag from '~web/features/my-meds/components/RefrigerationRequiredTag';
import { Ancillaries } from '~web/features/my-meds/components/med-list/ancillaries/Ancillaries';
import { ChangeQuantityLink } from '~web/features/quantity-change/components/ChangeQuantityLink';
import { ChangeQuantityModal } from '~web/features/quantity-change/components/ChangeQuantityModal';

type PrescriptionItemProps = {
  cartItem: CartItem;
  ancillaries: AncillaryItemPrescription[];
};

export const PrescriptionItemV2 = ({ cartItem, ancillaries }: PrescriptionItemProps) => {
  // selectors
  const prescriptionID = cartItem.resource_id;
  const userID = useSelectorShared(getCurrentUserID);
  const { fetchOrderPricingLoading: priceLoading } = useSelectorShared(getLoading);
  const pricingInfoForPrescription = useSelectorShared((state) =>
    getCartSelectedPricingInfoForPrescription(state, prescriptionID),
  );
  const allowPriceChange = useSelectorShared((state) => getAllowPriceOptionChange(state, { prescriptionID }));
  const priceOptions = useSelectorShared((state) => getPriceOptionsForPrescriptionInOrder(state, { prescriptionID }));
  const isFertilityUser = useSelectorShared((state) => getIsFertilityUser(state, { userID }));
  const isProgynyUser = useSelectorShared((state) => getIsProgynyUser(state, { userID }));
  const prescription = useSelectorShared((state) => getPrescriptionByID(state, prescriptionID));
  const address = useSelectorShared((state) => getEssentialsAddress(state, { shipmentID: null }));
  const orderIndex = useSelectorShared(getOrderIndex);

  // hooks
  const { essentialsHash } = useQueryEssentials({ addressID: address?.id });
  const { handleRemoveFromCart } = useRemoveFromCart();
  const isBackorderedMedication = useBackorderedMedication(prescriptionID);
  const { deliveryMethods } = useCartDeliveryMethods();
  const courierAgeRestrictionEnabled = useCourierAgeRestriction(
    address?.dispensing_facility_id,
    deliveryMethods[orderIndex]?.mode,
  );

  //state
  const [showChangeQuantityModal, setShowChangeQuantityModal] = useState(false);

  // constants
  const showChangeQuantityLink = isFertilityUser && !isProgynyUser;
  const hasControl = prescription && isControl(prescription);

  let essential: AddOnOtc | undefined;
  if (prescription?.is_add_on_otc && prescription?.product_id) {
    essential = essentialsHash[prescription.product_id];
  }
  const { onChangeEssential } = useEditEssential({ essential });

  // memoized functions
  const handleRemove = useCallback(async () => {
    if (!prescription) return;
    await handleRemoveFromCart({ item: prescriptionToItemKey(prescription.id) });
  }, [handleRemoveFromCart, prescription]);

  if (!prescription) return null;

  return (
    <LgPadding>
      <Row
        spaceBetween
        wrap={false}
      >
        <Body
          color={COLORS.TEXT_COLORS.BLACK}
          fontFamily={'semibold'}
          numberOfLines={1}
        >
          {prescription.medication_name}
        </Body>
        <Link onPress={handleRemove}>Remove</Link>
      </Row>
      <Column gap="XXS">
        <Description>{prescription.dosage_form}</Description>
        <Description>Qty: {formatQuantity(prescription)}</Description>
        {showChangeQuantityLink && (
          <ChangeQuantityLink
            prescriptionID={prescription.id}
            origin={ORIGIN_NAMES.CART}
            showQtyModal={() => {
              setShowChangeQuantityModal(true);
            }}
          />
        )}
        <UserTag
          userIDs={[prescription.user_id]}
          small
        />
        <Column centerVertically>
          {essential && (
            <Row centerVertically>
              <Link onPress={onChangeEssential}>Edit item</Link>
              <SmSpacing />
              <ClickableAltoIcon
                onClick={onChangeEssential}
                AltoIcon={
                  <AltoIcon
                    name="edit-small"
                    accessibilityLabel="Edit"
                    type="secondary"
                  />
                }
              />
            </Row>
          )}
        </Column>
        {!isBackorderedMedication && (
          <NextAvailableDateInfoRow
            prescription={prescription}
            orderTypeContext={ORDER_TYPE_CONTEXT.CART}
          />
        )}
        {prescription.has_ancillary_items && <AncillaryItemsTag medicationName={prescription.medication_name || ''} />}
        {prescription.is_refrigerated && (
          <RefrigerationRequiredTag medicationName={prescription.medication_name || ''} />
        )}
        {hasControl && (
          <PhotoIDRequiredTag
            medicationName={prescription.medication_name || ''}
            courierAgeRestrictionEnabled={courierAgeRestrictionEnabled}
          />
        )}
        {prescription.auto_refill_eligible && (
          <OrderTypeSelectionSection
            orderTypeContext={ORDER_TYPE_CONTEXT.CART}
            prescriptionID={prescription.id}
            initialAutoRefillEnabledValue={cartItem.auto_refill_enabled}
          />
        )}
        {prescription.has_ancillary_items && ancillaries.length > 0 && (
          <>
            <Separator />
            <Ancillaries
              collapsible
              ancillaries={ancillaries}
            />
            <Separator />
          </>
        )}
        <PricingSection
          cartItem={cartItem}
          priceLoading={priceLoading}
          pricingInfoForPrescription={pricingInfoForPrescription}
          allowPriceOptionChange={allowPriceChange}
          priceOptions={priceOptions}
        />
        {isBackorderedMedication && (
          <BackorderedTag
            origin={ORDER_TYPE_CONTEXT.CART}
            prescriptionId={prescription.id}
          />
        )}
        {prescription.user_id && prescription.id && (
          <AddCouponCard
            prescriptionID={prescription.id}
            prescriptionUserID={prescription.user_id}
          />
        )}
        {showChangeQuantityModal && (
          <ChangeQuantityModal
            onClose={() => {
              setShowChangeQuantityModal(false);
            }}
            prescriptionID={prescription.id}
          />
        )}
      </Column>
    </LgPadding>
  );
};
