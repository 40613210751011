// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { type KeyDateType, KeyDateTypeMap } from '@alto/operations_api/v1/types';
import { isAfter, parse } from 'date-fns';
import { useMemo } from 'react';
import { useCartNextAvailableDate } from '~shared/features/cart/hooks/useCartNextAvailableDate';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import {
  DATE_INVALID_MAIL,
  DATE_LOADING,
  DATE_REFRIGERATED,
  DATE_TOO_EARLY,
  DATE_TOO_LATE,
  NO_DATE_SELECTED,
  NO_WINDOW_SELECTED,
} from '~shared/features/checkout/constants';
import { getOrder, getOrderAddress } from '~shared/features/checkout/selectors/getOrder';
import { getOrderIndex } from '~shared/features/checkout/selectors/getOrdersByIndex';
import { type DateInfoKey, type Order } from '~shared/features/checkout/types';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { DATE_FORMATS } from '~shared/helpers/date';
import { isMail, isPickup } from '~shared/helpers/order';
import { shouldExcludeOrder } from '~shared/helpers/shouldExcludeOrder';
import { useSelectorShared } from '~shared/store';
import { type Address, type ExcludedDate, type ReduxStateShared } from '~shared/types';

const getDateWindowLoading = (state: ReduxStateShared) => {
  const { fetchNextAvailableDatesLoading, fetchDeliveryWindowsLoading } = getLoading(state);
  return fetchNextAvailableDatesLoading || fetchDeliveryWindowsLoading;
};

export const getInvalidDateKey = ({
  date,
  earliestAvailableDate,
  excludedDates,
  lastAvailableDate,
}: {
  date?: Date | string;
  earliestAvailableDate: string;
  excludedDates: ExcludedDate[];
  lastAvailableDate: string;
}): DateInfoKey | KeyDateType | null => {
  if (!earliestAvailableDate || !date) {
    return null;
  }

  const earliest = parse(earliestAvailableDate, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0);
  const latest = parse(lastAvailableDate, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0);
  const selected = date instanceof Date ? date : parse(date, DATE_FORMATS.YEAR_MONTH_DAY_DASHED, 0);

  if (isAfter(earliest, selected)) {
    return DATE_TOO_EARLY;
  } else if (isAfter(selected, latest)) {
    return DATE_TOO_LATE;
  }

  const { reason } = excludedDates.find((excluded) => shouldExcludeOrder(excluded, selected)) || {};
  switch (reason) {
    // enumerate MailCarrierType lowercased
    case 'ups':
    case 'fedex':
    case 'usps':
    case 'unknown_mail_carrier':
      return DATE_INVALID_MAIL;
    case 'refrigerated':
      return DATE_REFRIGERATED;
    case KeyDateTypeMap.HOLIDAY:
    case KeyDateTypeMap.EMERGENCY_CLOSURE:
    case KeyDateTypeMap.EMERGENCY_CLOSURE_PATIENT_ONLY:
      return reason;
    default:
  }

  return null;
};

type GetDateInfoKeyProps = {
  order: Order;
  address: Address | undefined;
  invalidDateKey: DateInfoKey | KeyDateType | null;
  dateWindowLoading: boolean;
  deliveryMethod: DeliveryMethod | null;
};

const getDateInfoKey = ({ order, address, invalidDateKey, dateWindowLoading, deliveryMethod }: GetDateInfoKeyProps) => {
  const { date, deliver_after, deliver_before, delivery_method } = order;

  if (dateWindowLoading) {
    return DATE_LOADING;
  }

  if (!date) {
    return NO_DATE_SELECTED;
  }

  const deliveryViaMail = isMail(deliveryMethod?.mode);
  // This can not use the `deliveryMethod` from the props as that is what is pre-determined from the server and not
  // what is selected by the user. Ideally we would use the `deliveryMethod` from the props and properly set it
  // but that would require a refactor of the entire checkout flow.
  const pickup = isPickup(delivery_method === 'pickup' ? 'PICKUP' : undefined);
  const requiresWindow = address?.in_courier_zone && !deliveryViaMail && !pickup;

  if (date && (!deliver_after || !deliver_before) && requiresWindow) {
    return NO_WINDOW_SELECTED;
  }

  // restriction of refrigerated items shipping to arrive on Monday does not apply to pickup orders
  if (invalidDateKey === DATE_REFRIGERATED && pickup) {
    return null;
  }

  if (invalidDateKey) {
    // Temporarily cast to expected type.
    // Refer to comments in `invalidDateKey()` for more details.
    return invalidDateKey;
  }

  return null;
};

export const useCartDateInfoKey = () => {
  const order = useSelectorShared(getOrder);
  const address = useSelectorShared(getOrderAddress);
  const dateWindowLoading = useSelectorShared(getDateWindowLoading);
  const { earliestAvailableDate, excludedDates = [], lastAvailableDate } = useCartNextAvailableDate();
  const orderIndex = useSelectorShared(getOrderIndex);
  const { deliveryMethods } = useCartDeliveryMethods();

  const { date } = order;
  const invalidDateKey = useMemo(
    () =>
      getInvalidDateKey({
        date: date || '',
        earliestAvailableDate,
        excludedDates,
        lastAvailableDate,
      }),
    [date, earliestAvailableDate, excludedDates, lastAvailableDate],
  );
  const deliveryMethod = deliveryMethods.length ? deliveryMethods[orderIndex] : null;
  const dateInfoKey = useMemo(
    () =>
      getDateInfoKey({
        order,
        address,
        invalidDateKey,
        dateWindowLoading,
        deliveryMethod,
      }),
    [order, address, invalidDateKey, dateWindowLoading, deliveryMethod],
  );

  return {
    dateInfoKey,
    invalidDateKey,
  };
};
