import { createSelector } from 'reselect';
import { getPrescriptions } from './getPrescriptions';
import { type Prescription, type ReduxStateShared } from '~shared/types';

export const getPrescriptionID = (_: ReduxStateShared, prescriptionID?: number | null) => prescriptionID;

export const getPrescriptionIDs = (_: ReduxStateShared, prescriptionIDs: number[]) => prescriptionIDs;

type PrescriptionHash = Record<number, Prescription>;

export const getPrescriptionsHash = createSelector([getPrescriptions], (prescriptions): PrescriptionHash => {
  return prescriptions.reduce<Record<number, Prescription>>((map, prescription) => {
    map[prescription.id] = prescription;
    return map;
  }, {});
});

export type PrescriptionHashWithUpdatedAt = { updatedAt: number; prescriptions: PrescriptionHash };

export const getPrescriptionsHashWithUpdatedAt = createSelector(
  [getPrescriptionsHash],
  (prescriptions): PrescriptionHashWithUpdatedAt => {
    const updatedAt = Date.now();
    return { prescriptions, updatedAt };
  },
);

export const getPrescriptionByID = createSelector(
  [getPrescriptionsHash, getPrescriptionID],
  (prescriptions, prescriptionID): Prescription | undefined => {
    if (!prescriptionID) return;
    return prescriptions[prescriptionID];
  },
);

export const getPrescriptionsByIDs = createSelector(
  [getPrescriptionsHash, getPrescriptionIDs],
  (prescriptionsHash, prescriptionIDs): Prescription[] => {
    const prescriptions: Prescription[] = [];
    prescriptionIDs.forEach((id) => {
      if (!prescriptionsHash[id]) return;
      prescriptions.push(prescriptionsHash[id]);
    });
    return prescriptions;
  },
);

export const getPrescriptionNameSentenceCaseByIDs = createSelector(
  [getPrescriptionsHash, getPrescriptionIDs],
  (prescriptionsHash, prescriptionIDs): string => {
    const prescriptionNames: string[] = [];
    prescriptionIDs.forEach((id) => {
      if (!prescriptionsHash[id]) return;
      prescriptionNames.push(prescriptionsHash[id].medication_name ?? '');
    });
    return prescriptionNames.reduce((acc, name, index) => {
      if (index === 0) return name;
      if (index === prescriptionNames.length - 1) return `${acc}, and ${name}`;
      return `${acc}, ${name}`;
    }, '');
  },
);
