// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  Column,
  Description,
  InlineAlert,
  InputCheckbox,
  InputCheckboxGroup,
  InputGroup,
  InputSelect,
  InputText,
  LgPadding,
  LgSpacing,
  ListDescription,
  MdSpacing,
  Separator,
  SmSpacing,
  XsSpacing,
} from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  ADDRESS_KIND_OPTIONS,
  DELIVERY_INSTRUCTIONS_PLACEHOLDER,
  SAFE_PLACES,
  SAFE_PLACE_INSTRUCTIONS_OPTIONS,
  SAFE_PLACE_INSTRUCTIONS_PLACEHOLDER,
  SAFE_PLACE_OPTIONS,
} from '~shared/constants';
import { type Address } from '~shared/types';
import { ADDRESS_FORM_ERROR_MESSAGES } from '../constants';
import { getAddressKind, hasMailbox } from './helpers';

const STREET_ADDRESS_2 = 'Apartment or Suite (optional)';
const SAFE_PLACE_LEGACY_LABEL = 'Is there a safe place to leave deliveries?';
const SAFE_PLACE_LABEL = 'Can we leave packages at this address?';
const SAFE_PLACE_INSTRUCTIONS_LABEL = 'Where should we leave packages?';
const SAFE_PLACE_INSTRUCTIONS_LEGACY_LABEL = 'Please describe your safe place';
const DELIVERY_INSTRUCTIONS = 'Additional information';
// These ensure that the total courier instructions length is less than 280 characters,
// including other instructions and the shipment ID.
const SAFE_PLACE_INSTRUCTIONS_MAX_LENGTH = 100;
const DELIVERY_INSTRUCTIONS_MAX_LENGTH = 125;
// give users a little space to write so they can see the error message
// if they go over the limit, but still truncate before they write too much
const INSTRUCTIONS_CUTOFF_OFFSET = 15;
const SAFE_PLACE_OPTIONS_WITH_DESCRIPTIONS = SAFE_PLACE_OPTIONS.map((opt) => ({
  ...opt,
  descriptions: opt.descriptions
    ? opt.descriptions.map((description) => <ListDescription key={description}>{description}</ListDescription>)
    : undefined,
}));

type Props = {
  readonly address: Address | undefined;
  readonly isEditingSafePlace?: boolean;
  readonly showDeliveryInstructions?: boolean;
};

export const AddressFormAdditionalFields = ({
  address,
  isEditingSafePlace = false,
  showDeliveryInstructions = false,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: Props) => {
  const { value: isSafePlaceInstructionRemoved } = Experimentation.useFeatureFlag(
    'remove_safe_place_instructions_field',
  );
  const { control, trigger } = useFormContext();
  const streetAddress2Controller = useController({
    control,
    name: 'street_address_2',
    defaultValue: address?.street_address_2 ?? '',
  });
  const initialAddressKind = getAddressKind(address);
  const kindController = useController({
    control,
    name: 'kind',
    defaultValue: initialAddressKind?.kind ?? '',
  });
  const customKindController = useController({
    control,
    name: 'custom_address_type',
    defaultValue: initialAddressKind?.custom_address_type ?? '',
  });
  const primaryController = useController({
    control,
    name: 'primary',
    defaultValue: address?.primary ?? false,
  });
  const safePlaceController = useController({
    control,
    name: 'safe_place',
    rules: {
      required: ADDRESS_FORM_ERROR_MESSAGES.safe_place,
      validate: (value) => {
        // When flag enabled, surface error if safe place is being edited and marked as false
        // as this conflicts with  waive signature/home to sign selection.
        if (isSafePlaceInstructionRemoved && value !== 'true' && isEditingSafePlace) {
          return ADDRESS_FORM_ERROR_MESSAGES.safe_place_delivery_conflict;
        }
        return true;
      },
    },
    defaultValue: address?.safe_place?.toString() ?? 'false',
    shouldUnregister: true,
  });

  // This field will be deprecated. For now we will be hiding it for all new addresses added
  // as well as for existing addresses that have empty safe place instructions.
  const safePlaceInstructionsController = useController({
    control,
    name: 'safe_place_instructions',
    rules: {
      validate: (value) => {
        if (value.includes('\\')) {
          return `Safe place instructions must not contain a backslash ( \\ )`;
        }
        if (value && value.length > SAFE_PLACE_INSTRUCTIONS_MAX_LENGTH) {
          return `Safe place instructions must be shortened from ${value.length} characters to ${SAFE_PLACE_INSTRUCTIONS_MAX_LENGTH} characters`;
        }
        return true;
      },
    },
    defaultValue: address?.safe_place_instructions ?? '',
  });

  const deliveryInstructionsController = useController({
    control,
    name: 'delivery_instructions',
    defaultValue: address?.delivery_instructions ?? '',
    rules: {
      validate: (value) => {
        if (value.includes('\\')) {
          return `Delivery instructions must not contain a backslash ( \\ )`;
        }
        if (value && value.length > DELIVERY_INSTRUCTIONS_MAX_LENGTH) {
          return `Delivery instructions must be shortened from ${value.length} characters to ${DELIVERY_INSTRUCTIONS_MAX_LENGTH} characters`;
        }
        return true;
      },
    },
  });

  const showSafePlaceOptions =
    safePlaceInstructionsController.field.value === '' ||
    safePlaceInstructionsController.field.value === undefined ||
    SAFE_PLACES.has(safePlaceInstructionsController.field.value ?? '');

  const handleSafePlaceChange = (data: string) => {
    safePlaceController.field.onChange(data);
    if (!isSafePlaceInstructionRemoved && data === 'true' && !safePlaceInstructionsController.field.value) {
      safePlaceInstructionsController.field.onChange('Front door');
    }
    trigger('safe_place');
  };

  const showMailBoxWarning =
    hasMailbox(deliveryInstructionsController.field.value ?? '') ||
    hasMailbox(safePlaceInstructionsController.field.value ?? '');

  // Trigger safe place validation on mount
  useEffect(() => {
    trigger('safe_place');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Column flexGrow={1}>
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <InputText
          accessibilityLabel={STREET_ADDRESS_2}
          autoCapitalize="words"
          autoCorrect={false}
          keyboardType="default"
          onChangeText={streetAddress2Controller.field.onChange}
          returnKeyType="next"
          label="Apt/Suite"
          placeholder={STREET_ADDRESS_2}
          defaultValue={streetAddress2Controller.field.value}
        />
        <LgSpacing />
        {address && (
          <InputSelect
            error={kindController.fieldState.error?.message}
            label="Address Type"
            onValueChange={kindController.field.onChange}
            options={ADDRESS_KIND_OPTIONS}
            defaultValue={kindController.field.value}
            placeholder="Select an option"
            required
            leftIcon="home"
          />
        )}
        {address && kindController.field.value === 'Other' && (
          <>
            <LgSpacing />
            <InputText
              value={customKindController.field.value}
              error={customKindController.fieldState.error?.message}
              label={'Specify "Other" Address Type'}
              placeholder="e.g., clinic, school, houseboat"
              onChangeText={customKindController.field.onChange}
              returnKeyType="next"
              keyboardType="default"
              required
            />
          </>
        )}
      </LgPadding>
      <MdSpacing />
      <InputCheckboxGroup
        onValueChange={(values) => {
          primaryController.field.onChange(values.primary);
        }}
        initialState={{ primary: primaryController.field.value }}
      >
        <InputCheckbox
          name="primary"
          label="Make this my default address"
        />
      </InputCheckboxGroup>
      <XsSpacing />
      {showDeliveryInstructions ? (
        <>
          <Separator />
          <LgPadding>
            <InputGroup label="Delivery instructions">
              {/* Cast the value to a string because selects only take strings */}
              <InputSelect
                modalTitle={'Can we leave packages at this address?'}
                error={safePlaceController.fieldState.error?.message}
                label={showSafePlaceOptions ? SAFE_PLACE_LABEL : SAFE_PLACE_LEGACY_LABEL}
                onValueChange={handleSafePlaceChange}
                options={SAFE_PLACE_OPTIONS_WITH_DESCRIPTIONS}
                defaultValue={safePlaceController.field.value?.toString()}
                leftIcon={safePlaceController.field.value?.toString() === 'true' ? 'homelock' : 'handbox'}
                placeholder="Select an option"
                required
              />
              <MdSpacing />
              {/* Only show safe place instructions when flag disabled or previous value existed*/}
              {safePlaceController.field.value === 'true' &&
                (!isSafePlaceInstructionRemoved || !!address?.safe_place_instructions) && (
                  <>
                    {showSafePlaceOptions ? (
                      <InputSelect
                        modalTitle={SAFE_PLACE_INSTRUCTIONS_LABEL}
                        description="Designate a safe place where we can drop off packages."
                        error={safePlaceInstructionsController.fieldState.error?.message}
                        label={SAFE_PLACE_INSTRUCTIONS_LABEL}
                        onValueChange={safePlaceInstructionsController.field.onChange}
                        options={SAFE_PLACE_INSTRUCTIONS_OPTIONS}
                        placeholder="Select an option"
                        required
                        value={safePlaceInstructionsController.field.value}
                      />
                    ) : (
                      <InputText
                        accessibilityLabel={SAFE_PLACE_INSTRUCTIONS_LEGACY_LABEL}
                        autoCapitalize="sentences"
                        autoCorrect
                        blurOnSubmit
                        error={safePlaceInstructionsController.fieldState.error?.message}
                        keyboardType="default"
                        onChangeText={(e) => {
                          safePlaceInstructionsController.field.onChange(e);
                          trigger('safe_place_instructions');
                        }}
                        numberOfLines={1}
                        label={SAFE_PLACE_INSTRUCTIONS_LEGACY_LABEL}
                        maxLength={SAFE_PLACE_INSTRUCTIONS_MAX_LENGTH + INSTRUCTIONS_CUTOFF_OFFSET}
                        multiline
                        placeholder={SAFE_PLACE_INSTRUCTIONS_PLACEHOLDER}
                        returnKeyType="next"
                        defaultValue={safePlaceInstructionsController.field.value}
                      />
                    )}
                    <MdSpacing />
                  </>
                )}
              <InputText
                accessibilityLabel={DELIVERY_INSTRUCTIONS}
                autoCapitalize="sentences"
                autoCorrect
                blurOnSubmit
                error={deliveryInstructionsController.fieldState.error?.message}
                keyboardType="default"
                onChangeText={(e) => {
                  deliveryInstructionsController.field.onChange(e);
                  trigger('delivery_instructions');
                }}
                label={DELIVERY_INSTRUCTIONS}
                maxLength={DELIVERY_INSTRUCTIONS_MAX_LENGTH + INSTRUCTIONS_CUTOFF_OFFSET}
                multiline
                numberOfLines={3}
                placeholder={DELIVERY_INSTRUCTIONS_PLACEHOLDER}
                returnKeyType="done"
                defaultValue={deliveryInstructionsController.field.value}
              />
              {showMailBoxWarning ? (
                <>
                  <SmSpacing />
                  <InlineAlert type="warning">
                    <Description>Couriers will not be able to leave orders inside a mailbox.</Description>
                  </InlineAlert>
                </>
              ) : null}
            </InputGroup>
          </LgPadding>
        </>
      ) : null}
    </Column>
  );
};
