import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { type ModalType, closeModal } from '~shared/actions/modal';
import { snakeToProperCase } from '~shared/helpers/string';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import getIsModalShowing from '~shared/selectors/modal/getIsModalShowing';
import getModalProps from '~shared/selectors/modal/getModalProps';
import getModalType from '~shared/selectors/modal/getModalType';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import GetTheAppDrawer from './GetTheAppDrawerContainer';
import SelectPriceOptionModal from '~web/features/checkout/components/SelectPriceOptionModal';
import UpdatedCartModal from '~web/features/checkout/components/UpdatedCartModalContainer';
import CourierTipsCreditCardModal from '~web/features/courier-tips/components/CourierTipsCreditCardModalContainer';
import EditOrAddTipModal from '~web/features/courier-tips/components/EditOrAddTipModalContainer';
import CourierTipsInfoModal from '~web/features/courier-tips/components/InfoModalContainer';
import PrescriptionTrackerModal from '~web/features/homescreen/components/prescription-tracker/PrescriptionTrackerModal';
import AddingNeedlesModal from '~web/features/my-meds/components/med-list/AddingNeedlesModal';
import { MedListVideoPrepInfoModal } from '~web/features/my-meds/components/med-list/MedListVideoPrepInfoModal';
import NoNeedlesFoundModal from '~web/features/my-meds/components/med-list/NoNeedlesFoundModal';
import { AlternativeMedicationModal } from '~web/features/my-meds/components/modals/AlternativeMedicationModal';
import { ClarificationModal } from '~web/features/my-meds/components/modals/ClarificationModal';
import {
  MedicationAlternativeModal,
  type MedicationAlternativeModalProps,
} from '~web/features/my-meds/components/modals/MedicationOutOfStock/MedicationAlternativeModal';
import {
  MedicationBackorderedModal,
  type MedicationBackorderedModalProps,
} from '~web/features/my-meds/components/modals/MedicationOutOfStock/MedicationBackorderedModal';
import {
  MedicationObsoleteModal,
  type MedicationObsoleteModalProps,
} from '~web/features/my-meds/components/modals/MedicationOutOfStock/MedicationObsoleteModal';
import {
  MedicationTransferModal,
  type MedicationTransferModalProps,
} from '~web/features/my-meds/components/modals/MedicationOutOfStock/MedicationTransferModal';
import { MenopurModal, type Props as MenopurModalProps } from '~web/features/my-meds/components/modals/MenopurModal';
import { MounjaroModal, type Props as MounjaroModalProps } from '~web/features/my-meds/components/modals/MounjaroModal';
import {
  NextAvailableDateModal,
  type NextAvailableDateModalProps,
} from '~web/features/my-meds/components/modals/NextAvailableDateModal';
import { PriorAuthModal } from '~web/features/my-meds/components/modals/PriorAuthModal';
import VerificationModal from '~web/features/onboarding/components/VerificationModalContainer';
import InsuranceInputInfoModal from '~web/features/onboarding/components/redesign/InsuranceInputInfoModal';
import OnboardingPriceInfoModal from '~web/features/onboarding/components/redesign/OnboardingPriceInfoModal';
import CreditCardModal from '~web/features/payments/components/CreditCardModal';
import PostDeliveryModal from '~web/features/post-delivery/components/PostDeliveryModalContainer';
import PhiAuthModal from '~web/features/profile/components/PhiAuthModalContainer';

type Props = {
  readonly showing: boolean;
  readonly type: ModalType;
  readonly closeModal: (modalType: ModalType) => void;
  readonly params: Record<string, unknown>;
  readonly props: Record<string, unknown> | null | undefined;
};

const AppModals = ({ closeModal, params: routerParams, props, showing, type }: Props) => {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (showing && type) {
      trackEvent({
        event: EVENTS.MODAL_VIEWED,
        params: {
          origin: window.location.pathname,
          type: snakeToProperCase(type),
        },
      });
    }
  }, [trackEvent, type, showing]);

  const close = () => {
    closeModal(type);
  };

  if (!showing) {
    return null;
  }

  // TODO: Move to a Modal constants file and make object { [ModalType]: Component }
  switch (type) {
    case 'ADDING_NEEDLES_MODAL':
      return (
        <AddingNeedlesModal
          onClose={close}
          {...props}
        />
      );
    case 'NO_NEEDLES_FOUND_MODAL':
      return <NoNeedlesFoundModal />;
    case 'INSURANCE_INPUT_INFO_MODAL':
      return (
        <InsuranceInputInfoModal
          onClose={close}
          {...props}
        />
      );

    case 'PRICE_INFO_MODAL':
      return (
        <OnboardingPriceInfoModal
          onClose={close}
          {...props}
        />
      );

    case 'POST_DELIVERY_MODAL':
      return (
        <PostDeliveryModal
          onClose={close}
          {...props}
        />
      );

    case 'VIDEO_PREP_INFO_MODAL':
      return (
        <MedListVideoPrepInfoModal
          onClose={close}
          {...props}
        />
      );

    case 'PHI_AUTH_MODAL':
      return (
        <PhiAuthModal
          onClose={close}
          {...props}
        />
      );

    case 'EDIT_OR_ADD_TIP_MODAL':
      return (
        <EditOrAddTipModal
          onClose={close}
          routerParams={routerParams}
          {...props}
        />
      );

    case 'CREDIT_CARD_MODAL':
      return (
        <CreditCardModal
          onClose={close}
          {...props}
        />
      );

    case 'COURIER_TIPS_CREDIT_CARD_MODAL':
      // This modal is intended to be opened from an originModal
      // and will only show up in a nested flow
      return <CourierTipsCreditCardModal />;

    case 'COURIER_TIPS_INFO_MODAL':
      // @ts-expect-error Property 'onClose' does not exist on type 'IntrinsicAttributes & Omit<Props, "onClose">'.
      return <CourierTipsInfoModal onClose={close} />;

    case 'UPDATED_CART_MODAL':
      return (
        <UpdatedCartModal
          onClose={close}
          {...props}
        />
      );

    case 'CONTACT_VERIFICATION':
      return (
        <VerificationModal
          onClose={close}
          {...props}
        />
      );

    case 'GET_THE_APP_DRAWER':
      return (
        <GetTheAppDrawer
          onClose={close}
          {...props}
        />
      );

    case 'PRESCRIPTION_TRACKER_MODAL':
      return (
        <PrescriptionTrackerModal
          onClose={close}
          {...props}
        />
      );

    case 'SELECT_PRICE_OPTION_MODAL':
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <SelectPriceOptionModal
          onClose={close}
          {...props}
        />
      );

    case 'ALTERNATIVE_MEDICATION_MODAL':
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <AlternativeMedicationModal
          onClose={close}
          {...props}
        />
      );
    case 'CLARIFICATION_MODAL':
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ClarificationModal
          onClose={close}
          {...props}
        />
      );
    case 'PRIOR_AUTH_MODAL':
      return (
        <PriorAuthModal
          onClose={close}
          {...props}
        />
      );
    case 'NEXT_AVAILABLE_DATE_MODAL': {
      const modalProps = props as Omit<NextAvailableDateModalProps, 'onClose'>;
      return (
        <NextAvailableDateModal
          onClose={close}
          {...modalProps}
        />
      );
    }
    case 'MEDICATION_OBSOLETE_MODAL': {
      const modalProps = props as MedicationObsoleteModalProps;
      return (
        <MedicationObsoleteModal
          {...modalProps}
          onClose={close}
        />
      );
    }
    case 'MEDICATION_BACKORDERED_MODAL': {
      const modalProps = props as MedicationBackorderedModalProps;
      return (
        <MedicationBackorderedModal
          {...modalProps}
          onClose={close}
        />
      );
    }
    case 'MEDICATION_ALTERNATIVE_MODAL': {
      const modalProps = props as MedicationAlternativeModalProps;
      return <MedicationAlternativeModal {...modalProps} />;
    }
    case 'MEDICATION_TRANSFER_MODAL': {
      const modalProps = props as MedicationTransferModalProps;
      return <MedicationTransferModal {...modalProps} />;
    }
    case 'MENOPUR_MODAL': {
      const modalProps = props as MenopurModalProps;
      return (
        <MenopurModal
          onClose={close}
          origin={modalProps.origin}
        />
      );
    }
    case 'MOUNJARO_DIAGNOSIS_MODAL': {
      const modalProps = props as MounjaroModalProps;
      return (
        <MounjaroModal
          onClose={close}
          onSave={modalProps.onSave}
        />
      );
    }
    default:
      return null;
  }
}; // TODO: probably only need type, should revisit the action/reducers

const mapStateToProps = (state: ReduxStateShared) => {
  return {
    showing: getIsModalShowing(state),
    type: getModalType(state),
    props: getModalProps(state),
  };
};

const mapDispatchToProps = {
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppModals));
