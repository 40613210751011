// @owners { team: patients-team }
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { useGetPaymentMethods } from './paymentMethods';
import { type PaymentMethodWithAmount } from '~shared/features/payments/types';
import { get } from '~shared/helpers/apiHelper';
import { staleTimes } from '~shared/queries/constants';
import { type PaymentMethod, type ShipmentPaymentMethod } from '~shared/types';

export const shipmentPaymentMethods = createQueryKeys('shipmentPaymentMethods', {
  fetchAll: () => ({
    queryKey: ['fetch-all-shipment-payment-methods'],
    queryFn: async () => {
      const response = await get('/shipment_payment_methods');
      if (response.error) {
        throw new Error(response.error.message);
      }
      return response as ShipmentPaymentMethod[];
    },
  }),
});

/**
 * Fetch all shipment payment methods for a patient
 * @returns Array of shipment payment methods
 */
export const useGetShipmentPaymentMethods = ({ enabled } = { enabled: true }) => {
  const { data, isPending, isSuccess, error } = useQuery({
    ...shipmentPaymentMethods.fetchAll(),
    enabled,
    staleTime: staleTimes.fifteenMinutes,
    gcTime: staleTimes.fifteenMinutes,
  });

  return {
    error,
    isPending,
    isSuccess,
    shipmentPaymentMethods: data ?? [],
  };
};

export const useGetShipmentPaymentMethodsWithDetails = ({ shipmentID }: { shipmentID: number }) => {
  const { paymentMethods } = useGetPaymentMethods();
  const { shipmentPaymentMethods } = useGetShipmentPaymentMethods();

  const paymentMethodsByID = paymentMethods.reduce<Record<number, PaymentMethod>>((methods, method) => {
    methods[method.id] = method;
    return methods;
  }, {});

  return shipmentPaymentMethods
    .filter((spm) => spm.shipment_id === shipmentID)
    .map((method) => {
      const { payment_method_id } = method;
      const paymentMethod: PaymentMethodWithAmount = {
        ...method,
        paymentMethod: paymentMethodsByID[payment_method_id] || undefined,
      };
      return paymentMethod;
    });
};
