// @owners { team: patients-team }
import {
  InsurancesEndpoint,
  type InsurancesEndpointCreateCopayCardRequest,
} from '@alto/scriptdash/alto/billing/patients/v3/insurances_endpoint';
import { useMutation } from '@tanstack/react-query';
import { apiEndpointHandler } from '~shared/helpers/api';

const insurancesEndpoint = InsurancesEndpoint(apiEndpointHandler);

export const useAddCopayCard = () => {
  return useMutation({
    mutationFn: async (params: InsurancesEndpointCreateCopayCardRequest) => {
      const { data, errors } = await insurancesEndpoint.createCopayCard(params);
      return {
        insurance: data,
        errors,
      };
    },
  });
};
