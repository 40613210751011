// @owners { team: patients-team }
import { isEmpty } from 'lodash';
import { TypescriptUtils } from '~shared/TypescriptUtils';
import { ADDRESS_FORM_FIELDS, ADDRESS_KIND_OPTIONS } from '~shared/constants';
import { type Address } from '~shared/types';
import { type GooglePlacesValue } from './data-source/googlePlacesDataSource';

type AddressKind = {
  kind: string | undefined;
  custom_address_type?: string;
};

export type AddressFormFields = {
  street_address_1?: string;
  street_address_2?: string;
  primary?: boolean;
  safe_place?: string;
  safe_place_instructions?: string;
  kind?: string;
  delivery_instructions?: string;
  custom_address_type?: string;
  google_street_address?: GooglePlacesValue;
  city?: string;
  state?: string;
  zip?: string;
};

export type AddressFormFieldsOnSubmit = Omit<AddressFormFields, 'safe_place'> & {
  id?: number;
  safe_place?: boolean;
};

export const getAddressKind = (address: Address | undefined) => {
  // if address type/kind is not in list, select 'Other'
  const selectedAddressKind = ADDRESS_KIND_OPTIONS.find((a) => a.value === address?.kind);
  const addressKind: AddressKind = { kind: undefined };

  if (selectedAddressKind) {
    addressKind.kind = selectedAddressKind.value;
  } else {
    if (address?.kind) {
      addressKind.kind = 'Other';
    }

    addressKind.custom_address_type = address?.kind;
  }
  return addressKind;
};

export const hasMailbox = (instructions: string) => {
  return instructions.toLowerCase().replace(/\s/g, '').includes('mailbox');
};

export const cleanFormFields = (fields: Record<string, any>, id: number | undefined): AddressFormFieldsOnSubmit => {
  fields.id = id;
  // destructure google_street_address
  fields.street_address_1 = fields.google_street_address?.street_address;
  fields.city = fields.google_street_address?.city;
  fields.state = fields.google_street_address?.state;
  fields.zip = fields.google_street_address?.zip;

  // remove certain empty fields and extraneous fields
  const preparedFields = TypescriptUtils.objectKeys(ADDRESS_FORM_FIELDS).reduce<AddressFormFieldsOnSubmit>(
    (acc, key) => {
      let val;

      if (key === 'safe_place') {
        // stringified safe_place needs to be turned back into boolean
        val = fields[key] === 'true';
      } else if (key === 'kind' && fields.kind && fields.kind.toLowerCase() === 'other') {
        // is there a custom kind?
        val = fields.custom_address_type === '' || !fields.custom_address_type ? 'Other' : fields.custom_address_type;
      } else {
        val = fields[key];
      }

      let isValEmpty = false;

      switch (typeof val) {
        case 'object':
          isValEmpty = isEmpty(val);
          break;

        default:
          // street_address_2, safe place instructions, and delivery instructions
          // should remain in the payload even if it is an empty string
          if (key !== 'street_address_2' && key !== 'safe_place_instructions' && key !== 'delivery_instructions') {
            isValEmpty = val === '' || val === null || val === undefined;
          }

          break;
      }

      return isValEmpty ? acc : { ...acc, [key]: val };
    },
    {},
  );

  // Remove safe place instructions when no safe place
  if (preparedFields.safe_place === false) {
    preparedFields.safe_place_instructions = '';
  }

  return preparedFields;
};
