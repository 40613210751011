import { useEffect } from 'react';

const updateFaviconLinks = ({ faviconURL }: { faviconURL: string }) => {
  // Remove existing favicons
  const links = document.head.querySelectorAll("link[rel*='icon'], link[rel='apple-touch-icon']");
  links.forEach((link) => document.head.removeChild(link));

  // Add new favicons based on the route
  const icons = [
    { rel: 'shortcut icon', sizes: undefined, href: faviconURL },
    { rel: 'icon', sizes: '16x16', href: faviconURL },
    { rel: 'icon', sizes: '32x32', href: faviconURL },
    { rel: 'icon', sizes: '48x48', href: faviconURL },
    { rel: 'apple-touch-icon', sizes: '180x180', href: faviconURL },
    { rel: 'icon', sizes: '192x192', href: faviconURL },
    { rel: 'icon', sizes: '512x512', href: faviconURL },
  ];

  icons.forEach(({ rel, sizes, href }) => {
    const link = document.createElement('link');
    link.rel = rel;
    link.href = href;
    if (sizes) {
      link.setAttribute('sizes', sizes);
    }
    document.head.appendChild(link);
  });
};

/**
 * Sets the favicon and title for the browser tab based on the current domain
 */
export const useBrowserTab = () => {
  const currentHostName = window.location.hostname;

  useEffect(() => {
    // Skip updating the favicon and title if the current hostname does not need update
    if (!currentHostName.startsWith('patientrxauth')) return;

    switch (true) {
      case currentHostName.startsWith('patientrxauth'):
        updateFaviconLinks({ faviconURL: 'https://assets.prod.alto.com/hub_referral/info_request/help_favicon.png' });
        document.title = 'Patient support program';
        break;
      default:
        break;
    }
  }, [currentHostName]);
};
