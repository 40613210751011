import { BORDERS, COLORS, SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, Column, useScreenSize } from '@alto/design-system';
import { OrderBundlingActionSheet, PaymentSummary, useOrderBundlingOptions } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { DeprecatedModalContainer, DeprecatedModalContent, DeprecatedModalFooter } from '@alto/pocky';
import { CheckoutFlowTypeMap } from '@alto/scriptdash/alto/patient_app/checkout_flow/types/v1/checkout_flow_type';
import { type EnterCartData } from '@alto/scriptdash/alto/patient_app/checkout_flow/v1/checkout_flow_endpoint';
import { OrderBundlingOriginMap } from '@alto/scriptdash/alto/patient_app/scheduling/order_bundling/types/v1/order_bundling_origin';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
// eslint-disable-next-line import/no-deprecated
import { fetchEnterCart, setWasNeedlePromptShown, updateOrder } from '~shared/actions/cart';
import { openModal } from '~shared/actions/modal';
import { closeCart as dispatchCloseCart } from '~shared/actions/ui/cart';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { useCartUsers } from '~shared/features/cart/hooks/useCartUsers';
import { useCheckoutCourierTipping } from '~shared/features/checkout/hooks/useCheckoutCourierTipping';
import { getDefaultAddress } from '~shared/features/checkout/selectors/getDefaultAddress';
import { getCartPricing } from '~shared/features/pricing/selectors/getPricing';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useCartViewed } from '~shared/lib/analytics/src/hooks/useCartViewed';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { CartFooter } from './CartFooter';
import { CartItemBody } from './CartItemBody';
import { CartItemHeader } from './CartItemHeader';

const Container = styled(DeprecatedModalContainer)<{ isSMScreenOrBigger: boolean }>`
  left: unset;
  height: 90vh;
  margin: 0;
  top: 45px;
  ${({ isSMScreenOrBigger }) =>
    !isSMScreenOrBigger &&
    `
      position: fixed;
      margin: 0;
      background: ${COLORS.BACKGROUND_COLORS.WHITE};
      width: 100vw;
      height: 100vh;
    `}
`;

const ScrollContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding-bottom: calc(8rem + ${SPACING.STATIC.MD.px} * 2);
`;

const Content = styled(DeprecatedModalContent)<{ noHeader?: boolean; isSMScreenOrBigger: boolean }>`
  margin-top: ${SPACING.STATIC.MD.px};
  border-radius: ${BORDERS.RADIUS.LG.px};
  > *:nth-child(2) {
    padding: 0;
  }
  ${({ isSMScreenOrBigger }) =>
    !isSMScreenOrBigger &&
    `
      margin: ${SPACING.STATIC.LG.px};
      width: unset;
    `}
`;

const ModalFooter = styled(DeprecatedModalFooter)`
  flex-direction: column;
  align-items: stretch;
  padding: ${SPACING.STATIC.LG.px};
`;

const StickyFooter = styledNative(Column)`
  position: fixed;
  bottom: 0;
  min-height: calc(5rem + ${SPACING.STATIC.MD.px} * 2);
  background-color: ${COLORS.BACKGROUND_COLORS.WHITE};
  width: 100%;
  padding: ${SPACING.STATIC.MD.px} ${SPACING.STATIC.LG.px};
  border-top: 1px solid ${COLORS.BORDER_COLORS.LIGHT};
`;

const DesktopContainer = styled.div<{ isSMScreenOrBigger: boolean }>`
  display: none;
  height: 90vh;

  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `display: initial;`}
`;

const MobileContainer = styled.div<{ isSMScreenOrBigger: boolean }>`
  display: initial;
  height: 100%;
  overflow-y: scroll;

  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && `display: none;`}
`;

export const Cart = () => {
  const dispatch = useDispatchShared();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { trackEvent } = useAnalytics();
  useCartViewed();
  const cartPricing = useSelectorShared(getCartPricing);
  const priceLoading = useSelectorShared((state) => state.ui.loading.fetchOrderPricingLoading);
  const fetchEnterCartLoading = useSelectorShared((state) => state.ui.loading.fetchEnterCartLoading);
  const { isPhotoIDRequired } = useCartUsers();
  const { cartItems, cartSize } = useCartData();
  const prescriptionIDs = cartItems.map((item) => item.resource_id);
  const address = useSelectorShared(getDefaultAddress);
  const { earliestUpcomingShipment, cartShipment } = useOrderBundlingOptions({
    address_id: address?.id,
    origin: OrderBundlingOriginMap.ENTER_CHECKOUT,
  });
  const { isEligibleForTipping: isTippable } = useCheckoutCourierTipping();

  useEffect(() => {
    // remove tip if all tippable items removed from cart but still have mail order
    if (!isTippable) {
      dispatch(updateOrder({ tip_amount: undefined }));
    }
  }, [cartSize, dispatch, isTippable]);

  const closeCart = useCallback(() => {
    dispatch(dispatchCloseCart());
  }, [dispatch]);

  const handleCheckoutModal = useCallback(
    (data: EnterCartData) => {
      if (data.checkout_flow_types.includes(CheckoutFlowTypeMap.NEEDLE_PROMPT_NO_NEEDLES)) {
        dispatch(setWasNeedlePromptShown(true));
        dispatch(openModal('NO_NEEDLES_FOUND_MODAL'));
      } else if (data.checkout_flow_types.includes(CheckoutFlowTypeMap.NEEDLE_PROMPT)) {
        dispatch(setWasNeedlePromptShown(true));
        dispatch(openModal('ADDING_NEEDLES_MODAL'));
      } else if (earliestUpcomingShipment && cartShipment) {
        setActiveActionSheet(<OrderBundlingActionSheet />);
      } else {
        dispatch(push('/checkout'));
      }
    },
    [dispatch, earliestUpcomingShipment, cartShipment, setActiveActionSheet],
  );

  const handleCheckout = useCallback(async () => {
    trackEvent({ event: EVENTS.CART_CHECKOUT_TAPPED });
    if (isPhotoIDRequired) {
      dispatch(openModal('PHOTO_ID_UPLOAD_MODAL'));
    } else {
      // eslint-disable-next-line import/no-deprecated
      await dispatch(fetchEnterCart()).then((response) => {
        // eslint-disable-next-line promise/always-return
        if (response.data) handleCheckoutModal(response.data);
      });
    }
    closeCart();
  }, [trackEvent, isPhotoIDRequired, dispatch, handleCheckoutModal, closeCart]);

  const { isSMScreenOrBigger } = useScreenSize();
  const prevNumCartItems = useRef(0);

  useEffect(() => {
    if (prevNumCartItems.current > 0 && cartSize === 0) closeCart();
    prevNumCartItems.current = cartSize;
  }, [cartSize, closeCart]);

  return (
    <Container isSMScreenOrBigger={isSMScreenOrBigger}>
      <DesktopContainer isSMScreenOrBigger={isSMScreenOrBigger}>
        <Content
          scrollable
          size="narrow"
          verticallyAlignContent={false}
          isSMScreenOrBigger={isSMScreenOrBigger}
        >
          <CartItemHeader onClose={closeCart} />
          <CartItemBody
            fetchEnterCartLoading={fetchEnterCartLoading}
            cartPricing={cartPricing}
            priceLoading={priceLoading}
            includePaymentSummary
          />
          <ModalFooter>
            <CartFooter
              handleCheckout={handleCheckout}
              fetchEnterCartLoading={fetchEnterCartLoading}
              prescriptionIDs={prescriptionIDs}
            />
          </ModalFooter>
        </Content>
      </DesktopContainer>
      <MobileContainer isSMScreenOrBigger={isSMScreenOrBigger}>
        <ScrollContainer>
          <Content
            size="narrow"
            verticallyAlignContent={false}
            isSMScreenOrBigger={isSMScreenOrBigger}
          >
            <CartItemHeader onClose={closeCart} />
            <CartItemBody
              fetchEnterCartLoading={fetchEnterCartLoading}
              cartPricing={cartPricing}
              priceLoading={priceLoading}
            />
          </Content>
          <Content
            size="narrow"
            verticallyAlignContent={false}
            noHeader
            isSMScreenOrBigger={isSMScreenOrBigger}
          >
            <PaymentSummary
              pricing={cartPricing}
              origin="Cart"
            />
          </Content>
        </ScrollContainer>
        <StickyFooter>
          <CartFooter
            handleCheckout={handleCheckout}
            fetchEnterCartLoading={fetchEnterCartLoading}
            prescriptionIDs={prescriptionIDs}
          />
        </StickyFooter>
      </MobileContainer>
    </Container>
  );
};
