// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  Button,
  Card,
  Description,
  H2,
  LgPadding,
  LoadingEmptyState,
  MdSpacing,
  SecondaryPage,
  XlPadding,
} from '@alto/design-system';
import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import { goBack, push } from 'react-router-redux';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';
import { InfoRequestHeader } from './InfoRequestHeader';
import { NavBarLogo } from './NavBarLogo';
import { PatientAuthorizationActionSheet } from './PatientAuthorizationActionSheet';
import { useQueryPatientAuthPageContent } from '~web/features/info-request/hooks';
import { useStatusBarColor } from '~web/features/info-request/hooks/useStatusBarColor';

type Props = {
  params: {
    externalUUID: string;
    hubReferralID: string;
  };
};

const PatientAuthorizationPageComponent = (props: Props) => {
  const { hubReferralID: hubReferralIDParam } = props.params || {};
  const hubReferralID = Number(hubReferralIDParam || 0);
  const { trackPageView } = useAnalytics();
  const { setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);
  const dispatch = useDispatchShared();
  const { isFetching, headerLogoUrl, pageHeader, pageBody, authContentTitle, authContentBody, nextURL, redirectURL } =
    useQueryPatientAuthPageContent({
      hubReferralID,
    });

  const handleGoBack = () => {
    dispatch(goBack());
  };

  const handlePress = () => {
    setActiveActionSheet(
      <PatientAuthorizationActionSheet
        hubReferralID={hubReferralID}
        onClose={closeActionSheet}
        nextURL={nextURL}
      />,
    );
  };

  useStatusBarColor(COLORS.BACKGROUND_COLORS.GREY_LIGHTEST);

  useEffect(() => {
    trackPageView({
      event: EVENTS.INFO_REQUEST__PATIENT_AUTHORIZATION_PAGE_VIEWED,
      params: { hubReferralID },
    });
  }, [trackPageView, hubReferralID]);

  useEffect(() => {
    if (redirectURL) {
      dispatch(push(redirectURL));
    }
  }, [redirectURL, dispatch]);

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  if (!pageHeader || !authContentTitle || !authContentBody) {
    return null;
  }

  return (
    <SecondaryPage
      footerPlacementContext="webScreenWithoutPolicyFooter"
      withoutWebFramingElementHeights
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      dismissIcon="chevronleft"
      onDismiss={handleGoBack}
      NavBarCenterContent={headerLogoUrl ? <NavBarLogo illustrationSrc={headerLogoUrl} /> : undefined}
      HeaderContent={
        <InfoRequestHeader
          title={pageHeader}
          subtitle={pageBody}
        />
      }
      buttons={[
        <Button
          label="Sign form"
          key="sign form"
          onPress={handlePress}
        />,
      ]}
    >
      <Card>
        <LgPadding>
          <H2>{authContentTitle}</H2>
          <MdSpacing />
          <Description>{authContentBody}</Description>
        </LgPadding>
      </Card>
    </SecondaryPage>
  );
};

export const PatientAuthorizationPage = withRouter(PatientAuthorizationPageComponent);
