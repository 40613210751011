// @owners { team: patients-team }
import {
  ActionSheetContext,
  Button,
  Card,
  Link,
  LoadingEmptyState,
  SecondaryPage,
  Toast,
  ToastContext,
  XlPadding,
} from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { BenefitInputActionSheet, useInsuranceWarningActionSheet, useQueryInsuranceConfigs } from '@alto/features';
import { useQueryClient } from '@tanstack/react-query';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Formik } from 'formik';
import React, { useCallback, useContext, useEffect } from 'react';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { clearInsuranceError, createInsurance, updateInsurance } from '~shared/actions/insurances';
import { setEditingInsuranceID } from '~shared/actions/onboarding';
import { VERIFY_FAILURE_ERROR_MESSAGE } from '~shared/constants';
import { getInferredInsuranceFormValues } from '~shared/features/insurances/helpers/getInferredInsuranceFormValues';
import { getInitialInsuranceFormValues } from '~shared/features/insurances/helpers/getInitialInsuranceFormValues';
import { type Values } from '~shared/features/insurances/helpers/getInitialInsuranceFormValues';
import { getInsuranceByID } from '~shared/features/insurances/selectors/getInsuranceById';
import { INSURANCE_INPUT_MANUAL, MEDICAL_INFO } from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { ROUTE_TO_URL, routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import { getEditingOnboardingInsuranceID } from '~shared/features/onboarding/selectors/getOnboarding';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { getIsProgynyUser } from '~shared/features/users/selectors/getIsProgynyUser';
import { getSelectedUser } from '~shared/features/users/selectors/getSelectedUser';
import { getErrorMessage } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { previousWebURL } from '~shared/lib/analytics/src/helper';
import { queries } from '~shared/queries/query-keys';
import { getNotRestrictedPartnerError } from '~shared/selectors/ui/errors/getInsuranceError';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Insurance } from '~shared/types/clients';
import { FAQs } from './FAQs';
import { removeEmptyValues } from '~web/features/insurances/components/InsuranceFormElements';
import { InsuranceFormFields, validate } from '~web/features/onboarding/components/redesign/InsuranceFormFields';
import { OnboardingHeader } from '~web/features/onboarding/components/redesign/OnboardingHeader';
import { OnboardingNavBarLogo } from '~web/features/onboarding/components/redesign/OnboardingNavBarLogo';
import { nextUrl as getNextUrl, previousUrl as getPreviousUrl } from '~web/features/onboarding/helpers/urlHandler';

const OnboardingInsuranceInputManual = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatchShared();
  const { trackEvent, trackPageView } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const { setActiveActionSheet } = useContext(ActionSheetContext);

  const loading = useSelectorShared(getLoading);
  const previousWebUrl = useSelectorShared(previousWebURL);
  const origin = previousWebUrl ? routeFromPath(previousWebUrl) : '';
  const { value: shouldRemoveMedVerification } = Experimentation.useFeatureFlag('remove_onboarding_med_verification');
  const nextUrl = useSelectorShared((state) =>
    getNextUrl({ state, currentRoute: INSURANCE_INPUT_MANUAL, useOnboardingRoutingV2: shouldRemoveMedVerification }),
  );
  const previousUrl = useSelectorShared((state) =>
    getPreviousUrl({
      state,
      currentRoute: INSURANCE_INPUT_MANUAL,
      useOnboardingRoutingV2: shouldRemoveMedVerification,
    }),
  );

  const selectedUser = useSelectorShared(getSelectedUser);
  const currentUser = useSelectorShared(getCurrentUser);
  const user = selectedUser || currentUser;
  const isProgyny = useSelectorShared((state) => getIsProgynyUser(state, { userID: user?.id }));
  const insuranceID = useSelectorShared(getEditingOnboardingInsuranceID);
  const savedOnboardingInsurance = useSelectorShared((state) => getInsuranceByID(state, insuranceID));

  const title = isProgyny ? 'Enter your insurance' : 'Enter benefits details';
  const error = useSelectorShared(getNotRestrictedPartnerError);
  const addBenefitButtonText = isProgyny ? 'Save insurance' : 'Save benefit';
  const saving = loading.createInsuranceLoading || loading.updateInsuranceLoading;
  const { headerBackgroundColor, headerLogoUrl, isFetching } = useQueryInsuranceConfigs();

  const openInsuranceInfoActionSheet = () => {
    setActiveActionSheet(<BenefitInputActionSheet />);
  };

  const afterSave = (newInsurance: Insurance) => {
    if (!newInsurance) {
      addToast(<Toast variant="error">{getErrorMessage(error) || VERIFY_FAILURE_ERROR_MESSAGE}</Toast>);
      return;
    }
    queryClient.invalidateQueries({ queryKey: queries.insurances.fetchAll._def });
    dispatch(setEditingInsuranceID(null));
    dispatch(push(nextUrl));

    if (!savedOnboardingInsurance) {
      trackEvent({
        event: EVENTS.INSURANCE_ADDED,
        params: {
          type: 'manual',
          source: 'onboarding',
          entity_name: newInsurance.insurance_plan_name,
        },
      });
    }
  };

  const onSave = async (values: Values) => {
    if (user) {
      const newInsuranceValues = getInferredInsuranceFormValues(values, user);
      if (savedOnboardingInsurance) {
        return dispatch(
          // eslint-disable-next-line import/no-deprecated
          updateInsurance({
            id: savedOnboardingInsurance.id,
            ...removeEmptyValues(newInsuranceValues),
          }),
        ).then(afterSave);
      } else {
        return dispatch(
          // eslint-disable-next-line import/no-deprecated
          createInsurance({
            user_id: user.id,
            ...removeEmptyValues(newInsuranceValues),
          }),
        ).then(afterSave);
      }
    }
  };

  const onBack = useCallback(() => {
    if (previousUrl) {
      dispatch(push(previousUrl));
    }
  }, [previousUrl, dispatch]);

  const onCancel = useCallback(() => {
    dispatch(setEditingInsuranceID(null));
    onBack();
  }, [dispatch, onBack]);

  const insuranceWarningOnContinue = useCallback(() => {
    dispatch(push(ROUTE_TO_URL[MEDICAL_INFO]));
    dispatch(clearInsuranceError());
    dispatch(setEditingInsuranceID(null));
  }, [dispatch]);

  const insuranceWarningOnAddAnotherBenefit = useCallback(() => {
    onCancel();
    dispatch(clearInsuranceError());
  }, [dispatch, onCancel]);

  useInsuranceWarningActionSheet({
    handleContinue: insuranceWarningOnContinue,
    handleAddAnotherBenefit: insuranceWarningOnAddAnotherBenefit,
  });

  useEffect(() => {
    trackPageView({
      event: EVENTS.ONBOARDING__INSURANCE_INPUT_MANUAL_VIEWED,
      params: {
        origin,
        is_progyny_rx_view: isProgyny,
      },
    });
  }, [isProgyny, origin, trackPageView]);

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  return (
    <Formik
      initialValues={getInitialInsuranceFormValues(savedOnboardingInsurance)}
      onSubmit={onSave}
      validate={validate}
      enableReinitialize
    >
      {(formikProps) => (
        <SecondaryPage
          headerBackgroundColor={headerBackgroundColor}
          withoutWebFramingElementHeights
          dismissIcon="chevronleft"
          onDismiss={onBack}
          NavBarCenterContent={<OnboardingNavBarLogo illustrationSrc={headerLogoUrl} />}
          NavBarRightPressable={<FAQs />}
          HeaderContent={
            <OnboardingHeader
              title={title}
              link={
                <Link
                  onPress={openInsuranceInfoActionSheet}
                  center
                >
                  Where can I find this?
                </Link>
              }
            />
          }
          buttons={[
            <Button
              label="Cancel"
              onPress={onCancel}
              type="tertiary"
              key="cancel button"
              loading={saving}
            />,
            <Button
              label={addBenefitButtonText}
              onPress={formikProps.handleSubmit}
              loading={saving}
              key="submit button"
            />,
          ]}
          footerPlacementContext="webScreenWithoutPolicyFooter"
        >
          <Card>
            <InsuranceFormFields
              values={formikProps.values}
              submitCount={formikProps.submitCount}
              errors={formikProps.errors}
              handleChange={formikProps.handleChange}
            />
          </Card>
        </SecondaryPage>
      )}
    </Formik>
  );
};

export default OnboardingInsuranceInputManual;
