import { createSelector } from 'reselect';
import { flushAnalyticsEvents } from './actions';
import { EVENTS } from './constants';
import { type Event } from './types';
import { BACKEND_ENVIRONMENT } from '~shared/config';
import { type ReduxAction } from '~shared/types';

export const EVENTS_QUEUE_MAX_LENGTH = 20;
export const EVENTS_QUEUE_FLUSH_AFTER = 1000 * 30;

/**
 * A long enough time window that we can reasonably assume we don't have a
 * network request to flush events in flight.
 */
export const FLUSHING_MAY_BE_STUCK = EVENTS_QUEUE_FLUSH_AFTER * 5;

// These events will always trigger an analytics events flush. This ensures
// that for certain funnels, we have the events we care about and they aren't
// sitting on user devices waiting to be flushed.
const FLUSH_EVENTS = {
  [EVENTS.ONBOARDING__CLAIM_ACCOUNT_VIEWED]: true,
  [EVENTS.ONBOARDING__CREATE_ACCOUNT_VIEWED]: true,
  [EVENTS.ONBOARDING__WEB_LANDING_PAGE_VIEWED]: true,
  [EVENTS.ORDER_SUCCESS_VIEWED]: true,
  [EVENTS.LOGIN_VIEWED]: true,
  // adding these two temporarily as a debugging measure for this ticket:
  // https://app.asana.com/0/1192784145533692/1199529131621948
  [EVENTS.RATE_SHIPMENT__DISMISS_CLICKED]: true,
  [EVENTS.RATE_SHIPMENT__SUBMIT_CLICKED]: true,
  [EVENTS.CHECKOUT__PLACE_ORDER_SUCCEEDED]: true,
  [EVENTS.CHECKOUT__PLACE_ORDER_FAILED]: true,
  [EVENTS.LOGOUT_STARTED]: true,
  [EVENTS.TRANSFER_SUCCESS_VIEWED]: true,
  [EVENTS.FORGOT_PASSWORD_VIEWED]: true,
  // Flush main info request flow events instantly as we need to be accurate for external reporting
  [EVENTS.INFO_REQUEST__LANDING_PAGE_VIEWED]: true,
  [EVENTS.INFO_REQUEST__PATIENT_AUTHORIZATION_PAGE_VIEWED]: true,
  [EVENTS.INFO_REQUEST__SUBMIT_ELECTRONIC_SIGNATURE_CLICKED]: true,
  [EVENTS.INFO_REQUEST__SUBMIT_ELECTRONIC_SIGNATURE_FAILED]: true,
  [EVENTS.INFO_REQUEST__SUBMIT_ELECTRONIC_SIGNATURE_SUCCEED]: true,
  [EVENTS.INFO_REQUEST__INSURANCE_INPUT_PAGE_VIEWED]: true,
  [EVENTS.INFO_REQUEST__INSURANCE_INPUT_PHOTO_PAGE_VIEWED]: true,
  [EVENTS.INFO_REQUEST__UPLOAD_PHOTO_CLICKED]: true,
  [EVENTS.INFO_REQUEST__INSURANCE_INPUT_MANUAL_PAGE_VIEWED]: true,
  [EVENTS.INFO_REQUEST__ENTER_INSURANCE_MANUALLY_CLICKED]: true,
  [EVENTS.INFO_REQUEST__SAVE_INSURANCE_CLICKED]: true,
  [EVENTS.INFO_REQUEST__INSURANCE_FOUND_VIEWED]: true,
  [EVENTS.INFO_REQUEST__DECLINE_INSURANCE_CLICKED]: true,
  [EVENTS.INFO_REQUEST__DECLINE_INSURANCE_FAILED]: true,
  [EVENTS.INFO_REQUEST__DECLINE_INSURANCE_SUCCEEDED]: true,
  [EVENTS.INFO_REQUEST__COMMS_CONFIG_PAGE_VIEWED]: true,
  [EVENTS.INFO_REQUEST__SUBMIT_SMS_CONSENT_CLICKED]: true,
  [EVENTS.INFO_REQUEST__SUBMIT_SMS_CONSENT_FAILED]: true,
  [EVENTS.INFO_REQUEST__SUBMIT_SMS_CONSENT_SUCCEED]: true,
  [EVENTS.INFO_REQUEST__COMPLETE_PAGE_VIEWED]: true,
} as const;

// @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
function getEvents(state): Event[] {
  return state.analytics.events;
}

// @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
function getLastFlushedAttemptedAt(state): string {
  return state.analytics.lastFlushAttemptedAt;
}

// @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
function getAppTime(state): number {
  return state.appTime;
}

// @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
function getIsFlushing(state): boolean {
  return state.analytics.flushing;
}

// @ts-expect-error TS(2769): No overload matches this call.
const getMSSinceLastFlush = createSelector(
  [getAppTime, getLastFlushedAttemptedAt],
  (appTime: number, lastFlushAttemptedAt: number): number => {
    return appTime - lastFlushAttemptedAt;
  },
);

const getHasExceededTimeout = createSelector([getMSSinceLastFlush], (msSinceLastFlush: number): boolean => {
  return msSinceLastFlush > EVENTS_QUEUE_FLUSH_AFTER;
});

/*
 * Prevent users from being stuck in a state where `flushing` is true forever
 * if they kill the app before a flush request resolves.
 */
const getIsFlushingStuck = createSelector([getMSSinceLastFlush], (msSinceLastFlush: number): boolean => {
  return msSinceLastFlush > FLUSHING_MAY_BE_STUCK;
});

const getHasFlushEvent = createSelector([getEvents], (events: Event[]): boolean => {
  return events.some((e) => FLUSH_EVENTS[e.key as keyof typeof FLUSH_EVENTS]);
});

const isSandboxEnvironment = BACKEND_ENVIRONMENT === 'sandbox';

const getShouldFlush = createSelector(
  [getEvents, getIsFlushing, getIsFlushingStuck, getHasFlushEvent, getHasExceededTimeout],
  (
    events: Event[],
    isFlushing: boolean,
    isFlushingStuck: boolean,
    hasFlushEvent: boolean,
    hasExceededTimeout: boolean,
  ): boolean => {
    if (isFlushing && !isFlushingStuck) {
      return false;
    }

    const fullQueue = events.length >= EVENTS_QUEUE_MAX_LENGTH;

    if (fullQueue) {
      return true;
    }

    if (hasFlushEvent) {
      return true;
    }

    const hasEvents = events.length > 0;

    if (hasEvents && hasExceededTimeout) {
      return true;
    }

    // when in sandbox environment, flush events immediately (instead of batching)
    // so they can be viewed in close-to-real-time in Segment UI (Wunderbar Patients Dev)
    // (for same reason, events also sent without delay from Wunderbar to Segment when in sandbox)
    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
    if (hasEvents && isSandboxEnvironment) {
      return true;
    }

    return false;
  },
);

export default createSelector(
  [getEvents, getShouldFlush],
  (events: Event[], shouldFlush: boolean): ReduxAction | null | undefined => {
    return shouldFlush ? flushAnalyticsEvents(events) : undefined;
  },
);
