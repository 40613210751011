// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { createSelector } from 'reselect';
import { getEditShipmentID } from './getCart';
import { getInsurances } from '~shared/features/insurances/selectors/getInsurances';
import { getShipmentsWithItemsByID } from '~shared/features/shipments/selectors/getShipmentsWithItems';
import { type ShipmentWithItems } from '~shared/features/shipments/types';
import { isCourier } from '~shared/helpers/order';
import { type ReduxStateShared } from '~shared/types';

type GetIsTippableForShipmentIDParams = {
  shipmentID?: number | null;
  deliveryMethod: DeliveryMethod | null | undefined;
};

const getShipmentID = (_: ReduxStateShared, params: GetIsTippableForShipmentIDParams) => params.shipmentID;
const getDeliveryMethod = (_: ReduxStateShared, params: GetIsTippableForShipmentIDParams) => params.deliveryMethod;

/**
 * Determines whether or not a specific shipment is tippable or not.
 * Takes a provided shipmentID, if one does not exist it will try to get the items
 * for the editShipmentID (only works during checkout flow).
 * A shipment is tippable if the address is in courier zone, delivery method is courier, and no item
 * is for a patient that has government insurance.
 */
export const getIsTippableForShipmentID = createSelector(
  [getShipmentID, getDeliveryMethod, getEditShipmentID, getShipmentsWithItemsByID, getInsurances],
  (shipmentID, deliveryMethod, editShipmentID, shipmentsWithItemsByID, insurances) => {
    let shipment: ShipmentWithItems | null = null;

    if (shipmentID) {
      shipment = shipmentsWithItemsByID[shipmentID];
    } else if (editShipmentID) {
      shipment = shipmentsWithItemsByID[Number(editShipmentID)];
    }

    const userIDs = new Set(shipment?.items.map((item) => item.prescription.user_id));
    const userInsurances = insurances.filter((insurance) => userIDs.has(insurance.user_id));
    const hasGovernmentInsurance = userInsurances.some((insurance) => insurance.is_government_insurance);

    return !hasGovernmentInsurance && !!shipment?.courier_tips_available && isCourier(deliveryMethod?.mode);
  },
);
