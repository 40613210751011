// @owners { team: patients-team }
import {
  Body,
  Button,
  Column,
  Description,
  InlineAlert,
  LoadingEmptyState,
  MdSpacing,
  SecondaryPage,
  XlPadding,
  XlSpacing,
} from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { InsuranceCard, useQueryInsuranceConfigs } from '@alto/features';
import { useNavigation } from '@alto/navigation';
import React, { Fragment } from 'react';
import { push } from 'react-router-redux';
import { setEditingInsuranceID } from '~shared/actions/onboarding';
import {
  INSURANCE_FOUND,
  INSURANCE_INPUT,
  INSURANCE_INPUT_MANUAL,
  INSURANCE_INPUT_PHOTO,
} from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { useGetInsurances } from '~shared/queries/query-keys/insurances';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Insurance as InsuranceType } from '~shared/types/clients';
import { FAQs } from '~web/features/onboarding/components/redesign/FAQs';
import { OnboardingHeader } from '~web/features/onboarding/components/redesign/OnboardingHeader';
import { OnboardingNavBarLogo } from '~web/features/onboarding/components/redesign/OnboardingNavBarLogo';
import {
  nextUrl as getNextUrl,
  previousUrl as getOnboardingPreviousUrl,
} from '~web/features/onboarding/helpers/urlHandler';

export const OnboardingInsuranceFoundPage = () => {
  const dispatch = useDispatchShared();
  const { getCurrentRouteName } = useNavigation();
  const showProgyny = useSelectorShared(getShowProgynySelfServiceForUser);
  const { insurances, isPending: fetchInsurancesLoading, error: fetchInsurancesError } = useGetInsurances();
  const { value: shouldRemoveMedVerification } = Experimentation.useFeatureFlag('remove_onboarding_med_verification');
  const photoUrl = useSelectorShared((state) =>
    getNextUrl({
      state,
      currentRoute: INSURANCE_FOUND,
      useOnboardingRoutingV2: shouldRemoveMedVerification,
      desiredRoute: INSURANCE_INPUT_PHOTO,
    }),
  );
  const manualUrl = useSelectorShared((state) =>
    getNextUrl({
      state,
      currentRoute: INSURANCE_FOUND,
      useOnboardingRoutingV2: shouldRemoveMedVerification,
      desiredRoute: INSURANCE_INPUT_MANUAL,
    }),
  );
  const addInsuranceUrl = useSelectorShared((state) =>
    getNextUrl({
      state,
      currentRoute: INSURANCE_FOUND,
      useOnboardingRoutingV2: shouldRemoveMedVerification,
      desiredRoute: INSURANCE_INPUT,
    }),
  );
  const nextUrl = useSelectorShared((state) =>
    getNextUrl({ state, currentRoute: INSURANCE_FOUND, useOnboardingRoutingV2: shouldRemoveMedVerification }),
  );
  const routeName = getCurrentRouteName() || '';
  const currentRoute = routeFromPath(routeName);
  const onboardingPreviousUrl = useSelectorShared((state) =>
    getOnboardingPreviousUrl({ state, currentRoute, useOnboardingRoutingV2: shouldRemoveMedVerification }),
  );
  const titleText = showProgyny ? 'Along with your Progyny benefits, we found your additional insurance!' : 'Benefits';
  const subtitleText = showProgyny
    ? 'This is separate from your Progyny benefits, and helps us find you a great price on any medications not covered by Progyny.'
    : "Review the info below to make sure it's correct. Have additional insurance or prescription savings cards? Add them all so we can get you the best pricing.";

  const { headerBackgroundColor, headerLogoUrl, isFetching } = useQueryInsuranceConfigs();

  const handleEditInsurance = (insurance: InsuranceType) => {
    const url = insurance.image_url ? photoUrl : manualUrl;
    dispatch(setEditingInsuranceID(insurance.id));

    dispatch(push(url));
  };

  const handleAddInsuranceClick = () => {
    dispatch(push(addInsuranceUrl));
  };

  const handleConfirm = () => {
    dispatch(push(nextUrl));
  };

  const handleBack = () => {
    if (onboardingPreviousUrl) {
      dispatch(push(onboardingPreviousUrl));
    }
  };

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  return (
    <SecondaryPage
      withoutWebFramingElementHeights
      buttons={
        !fetchInsurancesError && !fetchInsurancesLoading && !isFetching
          ? [
              <Button
                type="tertiary"
                key="confirm"
                label="Next"
                onPress={handleConfirm}
              />,
              <Button
                type="primary"
                key="confirm"
                label="Add another benefit"
                onPress={handleAddInsuranceClick}
              />,
            ]
          : []
      }
      footerPlacementContext="webScreenWithoutPolicyFooter"
      onDismiss={handleBack}
      dismissIcon="chevronleft"
      headerBackgroundColor={headerBackgroundColor}
      HeaderContent={
        <OnboardingHeader
          title={titleText}
          subtitle={<Body center>{subtitleText}</Body>}
        />
      }
      NavBarCenterContent={<OnboardingNavBarLogo illustrationSrc={headerLogoUrl} />}
      NavBarRightPressable={<FAQs />}
    >
      {fetchInsurancesLoading ? <LoadingEmptyState /> : null}
      {fetchInsurancesError && !fetchInsurancesLoading ? (
        <>
          <InlineAlert type="error">
            <Description>Looks like we're having some technical difficulty. Please refresh this page.</Description>
          </InlineAlert>
          <XlSpacing />
        </>
      ) : null}
      {!fetchInsurancesLoading && !fetchInsurancesError && (
        <Column>
          {insurances.map((insurance, index) => (
            <Fragment key={`insurance-card-${insurance.id}`}>
              <InsuranceCard
                insurance={insurance}
                onEditInsurance={handleEditInsurance}
              />
              {index !== insurances.length - 1 && <MdSpacing />}
            </Fragment>
          ))}
        </Column>
      )}
    </SecondaryPage>
  );
};
