// @owners { team: patients-team }
import {
  Body,
  Button,
  Card,
  InputPassword,
  InputText,
  LgPadding,
  LoadingEmptyState,
  SecondaryPage,
  Toast,
  ToastContext,
  XlPadding,
  XlSpacing,
} from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { useQueryCreateAccountConfigs, useQuerySuccessConfigs } from '@alto/features';
import { useNavigation } from '@alto/navigation';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Form, Formik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { setSuccessMessageType, updateOnboardingUser } from '~shared/actions/onboarding';
import { VERIFY_FAILURE_ERROR_MESSAGE } from '~shared/constants';
import { CREATE_ACCOUNT, ONBOARD_STATE_DONE } from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import { getOnboardingSuccessMessageType } from '~shared/features/onboarding/selectors/getOnboardingSuccessMessageType';
import getError from '~shared/features/ui/selectors/getErrors';
import getLoading from '~shared/features/ui/selectors/getLoading';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { getErrorMessage } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { previousWebURL } from '~shared/lib/analytics/src/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { createValidator, validateEmail, validatePassword, validatePresentWithMessage } from '~shared/validations';
import { FAQs } from '~web/features/onboarding/components/redesign/FAQs';
import { OnboardingHeader } from '~web/features/onboarding/components/redesign/OnboardingHeader';
import { OnboardingNavBarLogo } from '~web/features/onboarding/components/redesign/OnboardingNavBarLogo';
import { previousUrl as getOnboardingPreviousUrl, nextUrl } from '~web/features/onboarding/helpers/urlHandler';

const initialValues = {
  email: '',
  password: '',
};

export type Values = {
  email: string;
  password: string;
};

export const OnboardingCreateAccountPage = () => {
  const { addToast } = useContext(ToastContext);
  const { updateOnboardingUserLoading } = useSelectorShared(getLoading);
  const showProgyny = useSelectorShared(getShowProgynySelfServiceForUser);
  const { trackPageView } = useAnalytics();
  const dispatch = useDispatchShared();
  const { getCurrentRouteName } = useNavigation();
  const previousWebUrl = useSelectorShared(previousWebURL);
  const origin = previousWebUrl ? routeFromPath(previousWebUrl) : '';
  const routeName = getCurrentRouteName() || '';
  const currentRoute = routeFromPath(routeName);
  const { value: shouldRemoveMedVerification } = Experimentation.useFeatureFlag('remove_onboarding_med_verification');
  const nextRoute = useSelectorShared((state) =>
    nextUrl({ state, currentRoute: CREATE_ACCOUNT, useOnboardingRoutingV2: shouldRemoveMedVerification }),
  );
  const onboardingPreviousUrl = useSelectorShared((state) =>
    getOnboardingPreviousUrl({ state, currentRoute, useOnboardingRoutingV2: shouldRemoveMedVerification }),
  );
  const successMessageType = useSelectorShared(getOnboardingSuccessMessageType);
  const { updateOnboardingUserError } = useSelectorShared(getError);
  const { headerBackgroundColor, headerLogoUrl, isFetching } = useQueryCreateAccountConfigs();

  // Prefetch onboarding success config before account creation.
  useQuerySuccessConfigs();

  useEffect(() => {
    trackPageView({
      event: EVENTS.ONBOARDING__CREATE_LOGIN_VIEWED,
      params: { origin, is_progyny_rx_view: showProgyny },
    });
  }, [trackPageView, showProgyny, origin]);

  const validate = createValidator({
    email: (email) => validatePresentWithMessage('Email')(email) || validateEmail(email),
    password: (password) => validatePresentWithMessage('Password')(password) || validatePassword(password),
  });

  const handleBack = () => {
    if (onboardingPreviousUrl) {
      dispatch(push(onboardingPreviousUrl));
    }
  };

  const handleSave = async (data: Values) => {
    // eslint-disable-next-line import/no-deprecated
    const success = await dispatch(updateOnboardingUser({ ...data, onboard_state: ONBOARD_STATE_DONE }));
    if (success) {
      dispatch(setSuccessMessageType(successMessageType));
      dispatch(push(nextRoute));
    } else {
      addToast(
        <Toast variant="error">{getErrorMessage(updateOnboardingUserError) || VERIFY_FAILURE_ERROR_MESSAGE}</Toast>,
      );
    }
  };

  const titleText = showProgyny ? 'Create your Alto login' : 'Lastly, let’s create your account';

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSave}
      validate={validate}
      enableReinitialize
    >
      {(formikProps) => (
        <Form
          {...formikProps}
          noValidate
        >
          <SecondaryPage
            withoutWebFramingElementHeights
            buttons={[
              <Button
                type="primary"
                key="finish"
                label="Finish"
                onPress={formikProps.handleSubmit}
                loading={(updateOnboardingUserLoading && formikProps.submitCount > 0) ?? false}
              />,
            ]}
            footerPlacementContext="webScreenWithoutPolicyFooter"
            dismissIcon="chevronleft"
            onDismiss={handleBack}
            headerBackgroundColor={headerBackgroundColor}
            HeaderContent={
              <OnboardingHeader
                title={titleText}
                subtitle={
                  <Body center>
                    We’ve got everything ready to go. Add your email address and password which you'll use to log in.
                  </Body>
                }
              />
            }
            NavBarCenterContent={<OnboardingNavBarLogo illustrationSrc={headerLogoUrl} />}
            NavBarRightPressable={<FAQs />}
          >
            {updateOnboardingUserLoading ? (
              <LoadingEmptyState />
            ) : (
              <Card>
                <LgPadding>
                  <InputText
                    id="email"
                    label="Email address"
                    value={formikProps.values.email}
                    placeholder="username@email.com"
                    autoCapitalize="none"
                    error={formikProps.submitCount > 0 ? formikProps.errors.email : undefined}
                    onChangeText={formikProps.handleChange('email')}
                    required
                  />
                  <XlSpacing />
                  <InputPassword
                    accessibilityLabel="password"
                    label="Create password"
                    value={formikProps.values.password}
                    error={formikProps.submitCount > 0 ? formikProps.errors.password : undefined}
                    onChangeText={formikProps.handleChange('password')}
                    placeholder="Enter password"
                    caption="Must be at least 8 characters"
                  />
                </LgPadding>
              </Card>
            )}
          </SecondaryPage>
        </Form>
      )}
    </Formik>
  );
};
