import {
  ActionSheetContext,
  type AddOption,
  InputSelect,
  Link,
  ListButton,
  type Option,
  Tag,
} from '@alto/design-system';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
// eslint-disable-next-line import/no-deprecated
import { fetchAddresses, selectAddress } from '~shared/actions/addresses';
import { updateOrder } from '~shared/actions/cart';
// eslint-disable-next-line import/no-deprecated
import { fetchNextAvailableDates } from '~shared/actions/nextAvailableDates';
import { getAddresses } from '~shared/features/addresses/selectors/getAddresses';
import { getDefaultAddress } from '~shared/features/checkout/selectors/getDefaultAddress';
import { getOrder, getOrderAddress, getSaveOrderLoading } from '~shared/features/checkout/selectors/getOrder';
import { formatStreetAddress } from '~shared/helpers/address';
import { type OriginName } from '~shared/lib/analytics/src/getOrigin';
import { exitOrderBundling } from '~shared/reducers/ui/checkoutFlow';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Address } from '~shared/types';
import { AddressFormActionSheet } from './AddressFormActionSheet';
import { addressListDescriptions } from './AddressListItem';

type Props = {
  readonly disabled?: boolean;
  readonly origin?: OriginName;
};

export const AddressSelector = ({ disabled, origin }: Props) => {
  const dispatch = useDispatchShared();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const addresses = useSelectorShared(getAddresses);
  const defaultAddress = useSelectorShared(getDefaultAddress);
  const orderAddress = useSelectorShared(getOrderAddress);
  const order = useSelectorShared(getOrder);
  const savingOrder = useSelectorShared(getSaveOrderLoading);

  useEffect(() => {
    if (!addresses.length) {
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchAddresses());
    }
  }, [dispatch, addresses.length]);

  // clear the address if it isn't found
  const orderAddressID = orderAddress?.id;
  const isValidOrderAddress = addresses.some((address) => address.id === orderAddressID);
  useEffect(() => {
    if (!orderAddressID) return;

    if (!isValidOrderAddress) {
      dispatch(updateOrder({ address_id: undefined }));
    }
  }, [dispatch, isValidOrderAddress, orderAddressID]);

  // auto-select the primary address or most recently used address for non-pick up orders
  const defaultAddressID = defaultAddress?.id;
  useEffect(() => {
    if (!addresses.length) return;

    if (!orderAddressID && order.delivery_method !== 'pickup' && defaultAddressID) {
      dispatch(updateOrder({ address_id: defaultAddressID }));
    }
  }, [addresses.length, defaultAddressID, dispatch, order.delivery_method, orderAddressID]);

  const handleAddAddress = useCallback(() => {
    setActiveActionSheet(<AddressFormActionSheet origin={origin} />);
  }, [origin, setActiveActionSheet]);

  const options = useMemo(() => {
    const handleEditAddress = (address: Address) => () => {
      dispatch(selectAddress(address));
      setActiveActionSheet(<AddressFormActionSheet origin={origin} />);
    };

    const options =
      addresses.map((address): Option => {
        return {
          label: formatStreetAddress(address),
          value: address.id.toString(),
          radioPlacement: 'left',
          RightContent: <Link onPress={handleEditAddress(address)}>Edit</Link>,
          tag: address.primary ? <Tag label="Default Address" /> : undefined,
          tagPlacement: 'bottom',
          descriptions: addressListDescriptions(address),
        };
      }) || [];

    const addAddressOption: AddOption = {
      label: 'Add new address',
      value: 'ADD_OPTION_VALUE',
      onPress: handleAddAddress,
    };

    options.push(addAddressOption);

    return options;
  }, [addresses, dispatch, handleAddAddress, origin, setActiveActionSheet]);

  const handleSelectAddress = (value: string) => {
    const addressID = Number(value);
    dispatch(updateOrder({ address_id: addressID }));
    // TODO: remove once NAD hook ready
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchNextAvailableDates());
    dispatch(exitOrderBundling());

    // reset home to sign confirmation when order address changes
    if (orderAddress && addressID !== orderAddress.id) {
      dispatch(updateOrder({ home_to_sign_confirmation: undefined }));
    }
  };

  if (!addresses.length) {
    return (
      <ListButton
        onPress={handleAddAddress}
        label="Add delivery address"
        rightIcon="plus-small"
      />
    );
  }

  return (
    <InputSelect
      leftIcon="mapmarker"
      value={orderAddress?.id.toString()}
      options={options}
      onValueChange={handleSelectAddress}
      placeholder="Add delivery address"
      modalTitle="Select address"
      disabled={disabled || savingOrder}
      required
      showSeparators
    />
  );
};
