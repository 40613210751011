import { type ErrorObject } from '@alto/core/types/v1/error_object';
import { toNumber } from 'lodash';
import pluralize from 'pluralize';
import platform from './getPlatform';
import {
  ANDROID_APP_STORE_LINK,
  EXPENSIVE_THRESHOLD_3000,
  EXPENSIVE_THRESHOLD_500,
  IOS_APP_STORE_LINK,
  QUANTITY_UNIT_MAP,
} from '~shared/constants';
import { type Insurance, type LightDelivery, type Prescription, type User } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

export function getDisplayName(user: User | null | undefined): string {
  return (user && (user.preferred_first_name || user.first_name)) || '';
}

export function getDisplayFullName(user: User | null | undefined): string {
  const firstName = getDisplayName(user);
  const lastName = user?.last_name || '';
  if (firstName === '' && lastName === '') return '';
  return `${firstName} ${lastName}`;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function formatPhone(phone: string): string {
  if (!phone) {
    return phone;
  }

  phone = phone.replace(/\D/g, '');

  if (phone.startsWith('(')) {
    if (phone.charAt(4) === ')') {
      if (phone.charAt(5) === ' ') {
        if (phone.charAt(9) === ' ') {
          if (phone.charAt(10) === '-') {
            if (phone.charAt(11) === ' ') {
              phone = phone.slice(0, 11) + phone.slice(12);
            }

            phone = phone.slice(0, 10) + phone.slice(11);
          }

          phone = phone.slice(0, 9) + phone.slice(10);
        }

        phone = phone.slice(0, 5) + phone.slice(6);
      }

      phone = phone.slice(0, 4) + phone.slice(5);
    }

    phone = phone.slice(1);
  }

  const part1 = phone.slice(0, 3);
  const part2 = phone.slice(3, 6);
  const part3 = phone.slice(6, 10);

  if (part1.length > 0) {
    phone = `(${part1}`;
  }

  if (part2.length > 0) {
    phone = `${phone}) ${part2}`;
  }

  if (part3.length > 0) {
    phone = `${phone} - ${part3}`;
  }

  return phone;
} // TODO: Remove once migrated all uses to use Formik

type Field = {
  value: any;
};
export function checkMatch(field1: Field, field2: Field) {
  if (!field1.value || !field2.value) return {};

  const match = field1.value === field2.value;
  const lengthMatch = field1.value.length === field2.value.length;

  if (match) {
    return {
      matchClass: 'success',
      matchString: 'Matches',
    };
  }

  if (lengthMatch && !match) {
    return {
      matchClass: 'danger',
      matchString: "Doesn't match",
    };
  }

  return {
    matchClass: 'warning',
    matchString: "Doesn't match",
  };
}

export function getURLParams() {
  const params: any = {};
  const { search } = window.location;
  const paramString = search.slice(1, search.length);
  paramString.split('&').forEach((param) => {
    const p = param.split('=');
    params[p[0]] = p[1];
  });

  return params;
}

export function replaceState(url: string) {
  window.history.replaceState(null, '', url);
}

export function formatQuantity(prescription: Prescription, delivery?: LightDelivery | null): string {
  const dispensedQuantity = delivery?.quantity || prescription.quantity;
  const product_quantity = delivery?.product_quantity || prescription.product_quantity;

  if (!dispensedQuantity) {
    return '';
  }

  // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
  const unit = QUANTITY_UNIT_MAP[prescription.quantity_unit] || '';

  if (product_quantity && Number.isNaN(toNumber(product_quantity))) {
    if (unit !== '') {
      return `${product_quantity.toLowerCase()} (${dispensedQuantity}${unit})`;
    }
    return product_quantity.toLowerCase();
  }

  return `${dispensedQuantity}${unit}`;
}

// Returns ratio of dispensed quantity (i.e. 7 ML) to product quantity (i.e. 14 Pens)
export function getQuantityToFriendlyDosageConversion(prescription: Prescription): number | null | undefined {
  const { quantity, product_quantity } = prescription;

  if (!quantity || !product_quantity || !Number.isNaN(toNumber(product_quantity))) {
    return null;
  }

  let [productCount] = product_quantity.split(' ');
  // @ts-expect-error TS(2322): Type 'number' is not assignable to type 'string'.
  productCount = toNumber(productCount);

  if (typeof productCount === 'number' && productCount > 0) {
    return quantity / productCount;
  }

  return null;
}

// Returns a human-readable conversion from dispensed quantity to product quantity
// i.e. 1 pen = 0.5 mL
export function formatQuantityToFriendlyDosageConversion(prescription: Prescription): string {
  const { product_friendly_dosage_form, quantity_unit } = prescription;
  const quantityToFriendlyDosageConversion = getQuantityToFriendlyDosageConversion(prescription);

  if (quantityToFriendlyDosageConversion && product_friendly_dosage_form) {
    // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
    return `1 ${product_friendly_dosage_form} = ${quantityToFriendlyDosageConversion} ${QUANTITY_UNIT_MAP[quantity_unit]}`;
  }

  return '';
}

// For prescriptions without a friendly dosage form, returns written quantity.
// Otherwise, returns written quantity converted to friendly dosage form.
export function formatWrittenQuantity(prescription: Prescription): string {
  const { written_quantity, quantity_unit, product_friendly_dosage_form } = prescription;

  if (!written_quantity) {
    return '';
  }

  // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
  const writtenQuantity = `${written_quantity}${QUANTITY_UNIT_MAP[quantity_unit] || ''}`;
  const friendlyDosageForm = product_friendly_dosage_form || '';
  const quantityToFriendlyDosageConversion = getQuantityToFriendlyDosageConversion(prescription);

  if (quantityToFriendlyDosageConversion && friendlyDosageForm) {
    const writtenProductCount = written_quantity / quantityToFriendlyDosageConversion;
    const pluralizedFriendlyDosageForm = pluralize(friendlyDosageForm, writtenProductCount);
    return `${writtenProductCount} ${pluralizedFriendlyDosageForm} (${writtenQuantity} total)`;
  }

  return writtenQuantity;
}

export function formatInsuranceName(insurance: Insurance): string {
  const { bin, company } = insurance;
  let insuranceName = '';

  if (!company && !bin) {
    insuranceName = 'Pending verification';
  } else if (company && bin) {
    insuranceName = `${company} - ${bin}`;
  } else if (bin) {
    insuranceName = bin;
  }

  return insuranceName;
}

const excludedFieldKeys = [
  'initialValue',
  'autofill',
  'onUpdate',
  'valid',
  'invalid',
  'dirty',
  'pristine',
  'active',
  'touched',
  'visited',
  'autofilled',
];
export function getInputPropsFromField(field: Record<string, any>): Record<string, any> {
  return Object.keys(field).reduce((out, key) => {
    if (!excludedFieldKeys.includes(key)) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
      out[key] = field[key];
    }

    return out;
  }, {});
}

const regexp = /^support.*@alto\.com$/;
export function isSupportEmail(email: string | null | undefined): boolean {
  return !!email && !!regexp.exec(email);
}

export const isExpensive = (prescriptions: Prescription[], use3000threshold = false): boolean => {
  const price = prescriptions.reduce((sum, prescription) => {
    return sum + (prescription.product_net_standard_cost || prescription.product_net_price || 0);
  }, 0);

  if (use3000threshold) {
    return price > EXPENSIVE_THRESHOLD_3000;
  }

  return price > EXPENSIVE_THRESHOLD_500;
};

export const isControl = (prescription: Prescription): boolean => prescription.dea_schedule > 1;

export function isIOSMobileWeb(): boolean {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function isAndroidMobileWeb(): boolean {
  return /Android/i.test(navigator.userAgent);
}

export function isMobileWeb(): boolean {
  return isIOSMobileWeb() || isAndroidMobileWeb();
}

export function directAppStoreLink(): string {
  if (platform === 'patient-rn-android') {
    return ANDROID_APP_STORE_LINK;
  }

  if (platform === 'patient-rn-ios') {
    return IOS_APP_STORE_LINK;
  }

  if (platform === 'patient-mobile-web') {
    if (isIOSMobileWeb()) {
      return IOS_APP_STORE_LINK;
    }

    if (isAndroidMobileWeb()) {
      return ANDROID_APP_STORE_LINK;
    }
  }

  return 'https://app.alto.com';
}

export function getErrorMessage(error: APIError | null | undefined): string | undefined {
  if (!error) {
    return undefined;
  }

  if (error?.details?.message) {
    return error.details.message;
  }

  if (error.message) {
    return error.message;
  }

  return undefined;
}

export const getErrorMessageFromResponse = ({
  error,
  errors,
}: {
  error?: APIError;
  errors?: ErrorObject[];
}): string => {
  const errorMessage = getErrorMessage(error);
  if (errorMessage) {
    return errorMessage;
  }

  if (errors?.length && errors[0].detail) {
    return errors[0].detail;
  }

  return 'Something went wrong. Please try again or message support if the problem persists.';
};
