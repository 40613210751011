// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, Body, Button, LgPadding, LgSpacing, ListDescription, ListItem } from '@alto/design-system';
import React from 'react';
import alertIssue from '~shared/assets/images/alert_issue.png';
import { useSelectorShared } from '~shared/store';

type Props = {
  readonly onClose: () => void;
  readonly onSubmit: () => void;
  readonly medicationNames: string[];
};

export const MedSyncDeliveryRescheduleActionSheet = ({ onClose, onSubmit, medicationNames }: Props) => {
  const isSavingOrder = useSelectorShared((state) => state.ui.loading.saveOrderLoading);

  let actionSheetTitle;
  if (medicationNames.length > 1) {
    actionSheetTitle = 'This delivery contains auto refill medications';
  } else {
    actionSheetTitle = 'This delivery contains an auto refill medication';
  }

  return (
    <ActionSheetV2
      title={actionSheetTitle}
      buttons={[
        <Button
          key="primary-button"
          label="Agree and Update Order"
          onPress={onSubmit}
          loading={isSavingOrder}
          accessibilityLabel="Press to agree and update the order"
        />,
        <Button
          key="tertiary-button"
          type="tertiary"
          label="Go back"
          onPress={onClose}
          accessibilityLabel="Press to go back and continue editing the order"
        />,
      ]}
      illustrationSrc={alertIssue}
      handleBack={onClose}
      handleClose={onClose}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        {medicationNames.map((medicationName) => {
          return (
            <ListItem
              key={`list_item-${medicationName}`}
              fullBleed
              descriptions={[
                <ListDescription
                  key={`list_desc-${medicationName}`}
                  iconName="pills-small"
                >
                  {medicationName}
                </ListDescription>,
              ]}
            />
          );
        })}
        <LgSpacing />
        <Body>Changing the date of this upcoming delivery may affect future auto refill dates.</Body>
      </LgPadding>
    </ActionSheetV2>
  );
};
