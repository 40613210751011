import { SIZES, SPACING } from '@alto/design-library-tokens';
// eslint-disable-next-line import/no-deprecated
import { ActionSheetProvider, ToastProvider, getScreenSize } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { ExperimentationListener } from '@alto/features';
import { Auth0Provider } from '@auth0/auth0-react';
import React, { useCallback, useEffect, useRef } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Router, applyRouterMiddleware, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { fetchAddresses } from '~shared/actions/addresses';
import { clearRegistrationNotifications, setPostLoginRoute } from '~shared/actions/auth';
import { changeBanner, openBanner } from '~shared/actions/banners';
// eslint-disable-next-line import/no-deprecated
import { clearDeliveryNotifications, fetchDeliveries } from '~shared/actions/deliveries';
// eslint-disable-next-line import/no-deprecated
import { fetchNextAvailableDates } from '~shared/actions/nextAvailableDates';
// eslint-disable-next-line import/no-deprecated
import { fetchPrescriptions, fetchTransfers } from '~shared/actions/prescriptions';
// eslint-disable-next-line import/no-deprecated
import { fetchOrderPricing, fetchPrescriptionPricing } from '~shared/actions/pricing';
// eslint-disable-next-line import/no-deprecated
import { fetchShipments } from '~shared/actions/shipments';
// eslint-disable-next-line import/no-deprecated
import { fetchUsers } from '~shared/actions/users';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '~shared/config';
import { getDeliveries } from '~shared/features/delivery/selectors/getDeliveries';
import { PRESCRIPTION_PRICING_LABELS } from '~shared/features/pricing/constants';
import { getPrescriptionPricing } from '~shared/features/pricing/selectors/getPricing';
import { getItemPricingTotal } from '~shared/features/pricing/selectors/getPricingInfoForPrescription';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { staleTimes } from '~shared/queries/constants';
import { useGetInsurances } from '~shared/queries/query-keys/insurances';
import { useGetPaymentMethods } from '~shared/queries/query-keys/paymentMethods';
import { QueryClient, QueryClientProvider } from '~shared/react-query';
import { ReactQueryDevtools } from '~shared/react-query/devtools';
import getIsAuthorized from '~shared/selectors/auth/getIsAuthorized';
import { Sentry } from '~shared/sentry';
import { ReduxProvider, useDispatchShared, useSelectorShared } from '~shared/store';
import { type PriorAuthState } from '~shared/types';
import { useBrowserTab } from '~web/hooks';
import routes from '~web/routes';

// avoid 'auth0-spa-js must run on a secure origin' error
// when running app locally pointed at server running locally
// we can remove this code when url for that scenario is localhost or https
if (process.env.BACKEND_ENVIRONMENT === 'local' && !window.crypto?.subtle) {
  Object.defineProperty(window, 'crypto', {
    writable: true,
    value: {
      subtle: { digest: () => 'foo' },
      getRandomValues: () => new Uint8Array(16),
    },
  });
}

type Props = {
  readonly store: any;
  readonly skipInitialDataFetch?: boolean;
};

const DataLoader = () => {
  const authorized = useSelectorShared((state) => state.auth.authorized);
  const dispatch = useDispatchShared();

  useGetInsurances({ enabled: authorized });
  useGetPaymentMethods({ enabled: authorized });

  useEffect(() => {
    if (!authorized) return;
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchNextAvailableDates());
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchTransfers());
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchOrderPricing());
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchShipments());
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchPrescriptions(true));
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchUsers());
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchAddresses());
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchDeliveries());
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchPrescriptionPricing());
  }, [authorized, dispatch]);

  return null;
};

const PatientDashboardApp = ({ store, skipInitialDataFetch = false }: Props) => {
  const historyRef = useRef(syncHistoryWithStore(browserHistory, store));
  useBrowserTab();

  const getInitialData = useCallback(async () => {
    if (skipInitialDataFetch) return;

    const initialState = store.getState();
    const isAuthorized = getIsAuthorized(initialState);
    if (!isAuthorized) return;

    const [isDeliveriesSuccess, isPrescriptionPricingSuccess] = await Promise.all([
      // eslint-disable-next-line import/no-deprecated
      store.dispatch(fetchDeliveries()),
      // eslint-disable-next-line import/no-deprecated
      store.dispatch(fetchPrescriptionPricing()),
    ]);
    const state = store.getState();
    let shouldOpenBanner = false;

    if (isDeliveriesSuccess) {
      const deliveries = getDeliveries(state);
      const priorAuthDeliveries = deliveries.filter((d) => d.prior_authorization_status && d.status === 'unscheduled');
      const priorAuthPendingDelivery = priorAuthDeliveries.find((d) => d.prior_authorization_status === 'pending');
      const priorAuthApprovedDelivery = priorAuthDeliveries.find((d) => d.prior_authorization_status === 'approved');
      const priorAuthDeniedDelivery = priorAuthDeliveries.find((d) => d.prior_authorization_status === 'denied');
      const priorAuthNoResponseDelivery = priorAuthDeliveries.find(({ prior_authorization_status }) => {
        const priorAuthStatus = prior_authorization_status as PriorAuthState | 'no_response';
        return priorAuthStatus === 'no_response';
      });

      if (priorAuthPendingDelivery) {
        store.dispatch(
          changeBanner({
            bannerType: 'PRIOR_AUTH_PENDING',
            metadata: { medication_name: priorAuthPendingDelivery.medication_name },
          }),
        );
        shouldOpenBanner = true;
      } else if (priorAuthApprovedDelivery) {
        store.dispatch(
          changeBanner({
            bannerType: 'PRIOR_AUTH_APPROVED',
            metadata: { medication_name: priorAuthApprovedDelivery.medication_name },
          }),
        );
        shouldOpenBanner = true;
      } else if (priorAuthDeniedDelivery) {
        store.dispatch(
          changeBanner({
            bannerType: 'PRIOR_AUTH_DENIED',
            metadata: { medication_name: priorAuthDeniedDelivery.medication_name },
          }),
        );
        shouldOpenBanner = true;
      } else if (priorAuthNoResponseDelivery) {
        store.dispatch(
          changeBanner({
            bannerType: 'PRIOR_AUTH_NO_RESPONSE',
            metadata: { medication_name: priorAuthNoResponseDelivery.medication_name },
          }),
        );
        shouldOpenBanner = true;
      }
    }

    if (isPrescriptionPricingSuccess) {
      const pricing = getPrescriptionPricing(state);
      const needsAppUpsell = Object.values(pricing).some((value) => {
        const { reason } = getItemPricingTotal(value);
        return reason === PRESCRIPTION_PRICING_LABELS.PROGYNY;
      });

      if (needsAppUpsell) {
        store.dispatch(changeBanner({ bannerType: 'PROGYNY_APP_UPSELL' }));
        shouldOpenBanner = true;
      }
    }

    if (shouldOpenBanner) {
      setTimeout(() => {
        store.dispatch(openBanner());
      }, 2000);
    }
  }, [skipInitialDataFetch, store]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const createAnalyticsEvent = useCallback(
    (actionSheetName: string) => {
      store.dispatch(sendAnalyticsEvent(createEvent(EVENTS.ACTION_SHEET_VIEWED, { type: actionSheetName })));
    },
    [store],
  );

  const removeNotifications = () => {
    store.dispatch(clearDeliveryNotifications());
    store.dispatch(clearRegistrationNotifications());
  };

  // sync on each render for hot reload https://github.com/mattkrick/meatier/blob/961b5a79b2692c6a4fe381ed2cd2d00058a89f37/src/client/Root.js#L15
  if (!historyRef.current) {
    historyRef.current = syncHistoryWithStore(browserHistory, store);
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: staleTimes.twentySeconds,
      },
    },
  });

  // eslint-disable-next-line import/no-deprecated
  const { isSMScreenOrBigger } = getScreenSize();

  return (
    <div data-testid="dashboard">
      <ReduxProvider store={store}>
        <Experimentation.Provider>
          <Auth0Provider
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENT_ID}
            authorizationParams={{
              redirect_uri: window.location.origin,
              prompt: 'login', // always prompt user for email and password
            }}
            onRedirectCallback={(appState, user) => {
              Sentry.captureMessage('Auth0 Redirect', (scope) => {
                scope.setLevel('info');
                scope.setTag('auth0_id', user?.sub);
                return scope;
              });
              if (appState?.next) {
                store.dispatch(setPostLoginRoute(appState?.next));
              }
            }}
          >
            <QueryClientProvider client={queryClient}>
              <SafeAreaProvider>
                <ToastProvider
                  offset={
                    (isSMScreenOrBigger
                      ? parseInt(SIZES.PAGE.WEB_NAVBAR_HEIGHT.LG.replace('px', ''))
                      : parseInt(SIZES.PAGE.WEB_NAVBAR_HEIGHT.SM.replace('px', ''))) + SPACING.STATIC.MD.value
                  }
                >
                  <ActionSheetProvider createAnalyticsEvent={createAnalyticsEvent}>
                    <Router
                      history={historyRef.current}
                      render={applyRouterMiddleware()}
                      onUpdate={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      {
                        // FIXME: we want the routes in a separate file for HMR, but then
                        // they need access to the store to trigger these methods. This is
                        // a solution, but it means we have to re-create the routes on
                        // every render :\
                        routes({
                          removeNotifications,
                          getInitialData,
                        })
                      }
                    </Router>
                    <ReactQueryDevtools />
                  </ActionSheetProvider>
                </ToastProvider>
              </SafeAreaProvider>
              <DataLoader />
            </QueryClientProvider>
          </Auth0Provider>
        </Experimentation.Provider>
        <ExperimentationListener />
      </ReduxProvider>
    </div>
  );
};

export default Sentry.withProfiler(PatientDashboardApp);
