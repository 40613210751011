// eslint-disable-next-line @alto/prefer-alto-navigation-library
import { type RouterState, routerReducer as routing } from 'react-router-redux';
import { combineReducers } from 'redux';
import shipmentTips, { type ShipmentTipsState } from '~shared/features/courier-tips/reducers/shipmentTips';
import analytics, { type StateAnalytics } from '~shared/lib/analytics/src/reducer';
import addresses, { type StateAddresses } from '~shared/reducers/addresses';
import altoAssistant, { type StateAltoAssistant } from '~shared/reducers/altoAssistant';
import appTime from '~shared/reducers/appTime';
import auth, { type AuthState } from '~shared/reducers/auth';
import banners, { type BannersState } from '~shared/reducers/banners';
import biometrics, { type StateBiometrics } from '~shared/reducers/biometrics';
import cart, { type StateCart } from '~shared/reducers/cart';
import csat, { type CSATState } from '~shared/reducers/csat';
import deliveries, { type DeliveryState } from '~shared/reducers/deliveries';
import essentials, { type EssentialsState } from '~shared/reducers/essentials';
import facilities, { type FacilitiesState } from '~shared/reducers/facilities';
import instructionalVideos, { type InstructionalVideosState } from '~shared/reducers/instructionalVideos';
import insurances, { type InsurancesState } from '~shared/reducers/insurances';
import messages, { type StateMessages } from '~shared/reducers/messages';
import modal, { type ModalState } from '~shared/reducers/modal';
import nextAvailableDates, { type NextAvailableDateState } from '~shared/reducers/nextAvailableDates';
import notices, { type StateNotices } from '~shared/reducers/notices';
import onboarding, { type OnboardingState } from '~shared/reducers/onboarding';
import paymentMethods, { type PaymentMethodState } from '~shared/reducers/paymentMethods';
import prescriptions, { type PrescriptionState } from '~shared/reducers/prescriptions';
import presence, { type StatePresence } from '~shared/reducers/presence';
import pricing, { type StatePricing } from '~shared/reducers/pricing';
import quantityOptions, { type StateQuantityOptions } from '~shared/reducers/quantityOptions';
import rateShipment, { type RateShipmentState } from '~shared/reducers/rateShipment';
import shipments, { type ShipmentsState } from '~shared/reducers/shipments';
import subscriptions, { type SubscriptionsState } from '~shared/reducers/subscriptions';
import transfers, { type TransfersState } from '~shared/reducers/transfers';
import uiActionSheet, { type StateActionSheet } from '~shared/reducers/ui/actionSheet';
import uiAddFamilyMember, { type StateAddFamilyMember } from '~shared/reducers/ui/addFamilyMember';
import uiAnnouncements, { type StateUiAnnouncements } from '~shared/reducers/ui/announcements';
import uiCart, { type StateUiCart } from '~shared/reducers/ui/cart';
import uiCheckoutFlow, { type StateUiCheckoutFlow } from '~shared/reducers/ui/checkoutFlow';
import uiCoachmarks, { type StateUiCoachmarks } from '~shared/reducers/ui/coachmarks';
import uiErrors, { type StateUiErrors } from '~shared/reducers/ui/errors';
import uiLoading, { type StateUiLoading } from '~shared/reducers/ui/loading';
import uiMedList, { type StateMedList } from '~shared/reducers/ui/medList';
import uiMedications, { type StateUiMedications } from '~shared/reducers/ui/medications';
import uiModal, { type StateUiModal } from '~shared/reducers/ui/modal';
import uiPriceOptions, { type StateUiPriceOptions } from '~shared/reducers/ui/priceOptions';
import uiProgynyWizard, { type StateUiProgynyWizard } from '~shared/reducers/ui/progynyWizard';
import uiPromos, { type StateUiPromos } from '~shared/reducers/ui/promos';
import uiShipmentTipInput, { type ShipmentTipInputState } from '~shared/reducers/ui/shipmentTipInput';
import uiUsers, { type StateUiUsers } from '~shared/reducers/ui/users';
import uiWelcome, { type StateUiWelcome } from '~shared/reducers/ui/welcome';
import uiOnboarding, { type StateUiOnboarding } from '~shared/reducers/uiOnboarding';
import users, { type UserState } from '~shared/reducers/users';

export type ReduxStateShared = {
  auth: AuthState;
  addresses: StateAddresses;
  altoAssistant: StateAltoAssistant;
  analytics: StateAnalytics;
  appTime: number;
  banners: BannersState;
  biometrics: StateBiometrics;
  cart: StateCart;
  csat: CSATState;
  deliveries: DeliveryState;
  essentials: EssentialsState;
  facilities: FacilitiesState;
  instructionalVideos: InstructionalVideosState;
  insurances: InsurancesState;
  messages: StateMessages;
  modal: ModalState;
  nextAvailableDates: NextAvailableDateState;
  notices: StateNotices;
  onboarding: OnboardingState;
  paymentMethods: PaymentMethodState;
  presence: StatePresence;
  prescriptions: PrescriptionState;
  pricing: StatePricing;
  quantityOptions: StateQuantityOptions;
  rateShipment: RateShipmentState;
  routing: RouterState;
  shipments: ShipmentsState;
  shipmentTips: ShipmentTipsState;
  subscriptions: SubscriptionsState;
  transfers: TransfersState;
  users: UserState;
  ui: {
    actionSheet: StateActionSheet;
    addFamilyMember: StateAddFamilyMember;
    cart: StateUiCart;
    checkoutFlow: StateUiCheckoutFlow;
    errors: StateUiErrors;
    loading: StateUiLoading;
    medications: StateUiMedications;
    modal: StateUiModal;
    priceOptions: StateUiPriceOptions;
    progynyWizard: StateUiProgynyWizard;
    shipmentTipInput: ShipmentTipInputState;
    users: StateUiUsers;
    welcome: StateUiWelcome;
  };
  persistedUI: {
    medList: StateMedList;
    coachmarks: StateUiCoachmarks;
    announcements: StateUiAnnouncements;
    promos: StateUiPromos;
  };
  uiOnboarding: StateUiOnboarding;
};

export const getSharedReducers = () =>
  combineReducers({
    auth,
    addresses,
    altoAssistant,
    analytics,
    appTime,
    banners,
    biometrics,
    cart,
    csat,
    deliveries,
    essentials,
    presence,
    facilities,
    instructionalVideos,
    insurances,
    messages,
    modal,
    nextAvailableDates,
    notices,
    onboarding,
    paymentMethods,
    prescriptions,
    pricing,
    quantityOptions,
    rateShipment,
    routing,
    shipments,
    shipmentTips,
    subscriptions,
    transfers,
    users,
    ui: combineReducers({
      addFamilyMember: uiAddFamilyMember,
      actionSheet: uiActionSheet,
      cart: uiCart,
      checkoutFlow: uiCheckoutFlow,
      errors: uiErrors,
      loading: uiLoading,
      medications: uiMedications,
      modal: uiModal,
      priceOptions: uiPriceOptions,
      progynyWizard: uiProgynyWizard,
      shipmentTipInput: uiShipmentTipInput,
      users: uiUsers,
      welcome: uiWelcome,
    }),
    persistedUI: combineReducers({
      medList: uiMedList,
      coachmarks: uiCoachmarks,
      announcements: uiAnnouncements,
      promos: uiPromos,
    }),
    uiOnboarding,
  });
