import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, Card, LgPadding, ListBase, useScreenSize } from '@alto/design-system';
import { PrescriptionStatusTracker, getDeliveryEstimatedPrice, getPriceCopy } from '@alto/features';
import { type Prescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/prescription';
import { type SourcePrescription } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/source_prescription';
import { PrescriptionStatusMap } from '@alto/scriptdash/alto/patient_app/types/v1/prescription_status';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { closeModal, openModal } from '~shared/actions/modal';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { getNextDeliveriesMappedToPrescriptionID } from '~shared/features/delivery/selectors/getNextDeliveryForPrescriptionID';
import { SECTION_LABELS } from '~shared/features/homescreen/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Mutable, PrescriptionInfo, type Props as PrescriptionInfoProps } from './PrescriptionInfo';
import { MedicationCTA } from '~web/features/homescreen/sections/MedicationCTA';
import pillBottle from '~web/images/pillBottle@3x.png';

const CustomCard = styled(Card)<{ isSMScreenOrBigger?: boolean }>`
  margin: 0 0 ${SPACING.STATIC.XL.px};
  ${({ isSMScreenOrBigger }) => isSMScreenOrBigger && ` margin: 0 0 ${SPACING.STATIC.LG.value * 2}px;`}
  z-index: 0;
`;

type Props = {
  readonly prescriptions: (SourcePrescription | Prescription)[];
};

export default function NewPrescriptions({ prescriptions }: Props) {
  const dispatch = useDispatchShared();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const deliveriesByPrescriptionID = useSelectorShared(getNextDeliveriesMappedToPrescriptionID);
  const { isSMScreenOrBigger } = useScreenSize();

  const closeNewRxModal = () => {
    dispatch(closeModal('PRESCRIPTION_TRACKER_MODAL'));
  };

  const Rows = prescriptions.map((prescription) => {
    const { name, status } = prescription;

    const prescriptionInfoProps = {
      title: name,
      origin: ORIGIN_NAMES.HOMESCREEN,
    } as Mutable<PrescriptionInfoProps>;

    let prescriptionID: number | undefined = undefined;
    // check if the rx is not a source prescription
    if ('id' in prescription) {
      prescriptionID = prescription.id;
      const { id, image_url, family_member_name, manual_coupon_eligible, price } = prescription;
      const delivery = deliveriesByPrescriptionID[id];
      const estimatedPrice = getDeliveryEstimatedPrice(delivery);

      prescriptionInfoProps.manualCouponEligible = manual_coupon_eligible ?? false;
      prescriptionInfoProps.byline = family_member_name || undefined;
      prescriptionInfoProps.prescriptionID = id;
      prescriptionInfoProps.imageURL = image_url || undefined;

      const formattedPrice = getPriceCopy({
        estimatedPrice,
        isPrescriptionReady: status === 'ready',
        shouldShowPrice: !manual_coupon_eligible,
        price,
      });
      prescriptionInfoProps.description = formattedPrice;
    }

    prescriptionInfoProps.CTA = (
      <div
        role="button"
        tabIndex={0}
        onClick={(event) => {
          // Prevent clicks on CTA actions from propagating to parent
          event.stopPropagation();
        }}
        onKeyUp={(event) => {
          // Prevent clicks on CTA actions from propagating to parent
          event.stopPropagation();
        }}
      >
        <MedicationCTA
          prescription={prescription}
          openPrescriptionTrackerModal={() => {
            // PROGYNY_EARLY_ORDER_ELIGIBLE is a special case where we need to use the old prescription tracker modal
            // since there's a button that leads to a native only progyny flow
            const useNewerPrescriptionTrackerModal =
              prescription.status !== PrescriptionStatusMap.PROGYNY_EARLY_ORDER_ELIGIBLE;
            if (useNewerPrescriptionTrackerModal) {
              setActiveActionSheet(
                <PrescriptionStatusTracker
                  prescriptionId={prescriptionID}
                  origin="NewPrescriptions"
                  prescriptionStatus={prescription.status}
                  prescriptionName={prescription.name}
                />,
              );
            } else {
              dispatch(openModal('PRESCRIPTION_TRACKER_MODAL', { prescription, onClose: closeNewRxModal }));
            }
          }}
        />
      </div>
    );

    prescriptionInfoProps.isCTAButton =
      PrescriptionStatusMap.MULTIPLE_PRICES === status ||
      PrescriptionStatusMap.READY === status ||
      PrescriptionStatusMap.PROGYNY_EARLY_ORDER_ELIGIBLE === status;

    return {
      key: prescription.name,
      component: <PrescriptionInfo {...prescriptionInfoProps} />,
    };
  });

  return (
    <CustomCard
      title={SECTION_LABELS.NEW_PRESCRIPTIONS}
      illustrationSrc={pillBottle}
      illustrationAccessibilityLabel={IMG_ALT_TEXTS.bikeCourier}
      isSMScreenOrBigger={isSMScreenOrBigger}
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <section>
          <ListBase items={Rows} />
        </section>
      </LgPadding>
    </CustomCard>
  );
}
