// @owners { team: patients-team }
import {
  CouponsEndpoint,
  type CouponsEndpointFetchSignUpConfigurationResponse,
} from '@alto/scriptdash/alto/patient_app/billing/v1/coupons_endpoint';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { apiEndpointHandler } from '~shared/helpers/api';

const couponsEndpoint = CouponsEndpoint(apiEndpointHandler);

const coupons = createQueryKeys('coupons', {
  signUpConfig: (couponID: number) => ({
    queryKey: ['sign-up-config', couponID],
    queryFn: async (): Promise<CouponsEndpointFetchSignUpConfigurationResponse> => {
      return await couponsEndpoint.fetchSignUpConfiguration({ coupon_id: couponID });
    },
  }),
});

export const useGetCouponSignUpConfig = (couponID: number) => {
  const { data, error, isPending } = useQuery({
    ...coupons.signUpConfig(couponID),
    enabled: !!couponID,
  });

  return {
    isPending,
    couponConfig: data?.data,
    error,
  };
};
