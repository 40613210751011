// @owners { team: patients-team }
import { Toast, ToastContext } from '@alto/design-system';
import { type AllergenParams } from '@alto/scriptdash/alto/wunderbar/patients/types/v1/allergen_params';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Formik, type FormikProps } from 'formik';
import React, { useContext, useEffect } from 'react';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { updateOnboardingUser } from '~shared/actions/onboarding';
import {
  DOES_NOT_HAVE_OTHER_SCRIPTS,
  HAS_OTHER_SCRIPTS,
  NO_KNOWN_ALLERGIES,
  NO_KNOWN_MEDICAL_CONDITIONS,
  VERIFY_FAILURE_ERROR_MESSAGE,
} from '~shared/constants';
import {
  ONBOARD_STATE_MEDICAL_INFO,
  PHI_AUTHORIZATION,
} from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { ROUTE_TO_URL, routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import {
  type AsyncValue,
  formatAsyncSelectInitialValue,
  formatAsyncSelectSubmitValue,
} from '~shared/helpers/formatAsyncSelectValues';
import { getErrorMessage } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks';
import { BUCKET, markExperimentExposure } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { ORIGIN_NAMES } from '~shared/lib/analytics/src/getOrigin';
import { previousWebURL } from '~shared/lib/analytics/src/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { createValidator, validatePresentIf, validatePresentWithMessage } from '~shared/validations';
import { OnboardingMedicalInfoPage } from '~web/features/onboarding/components/redesign/OnboardingMedicalInfoPage';

const ALLERGIES = 'Allergies';
const MEDICAL_CONDITIONS = 'Medical conditions';
const OTHER_MEDICATIONS = 'Other medications';
const YES_VALUE = HAS_OTHER_SCRIPTS;

type Values = {
  allergies: string;
  allergens: AllergenParams[];
  medical_conditions: string;
  onboarding_other_scripts: string;
  other_scripts_list: (AsyncValue | null | undefined) | (AsyncValue[] | null | undefined);
};

const validate = createValidator({
  allergies: validatePresentIf((_value, values) => {
    return !Array.isArray(values.allergens);
  }, ALLERGIES),
  allergens: validatePresentIf((value) => {
    return Array.isArray(value);
  }, ALLERGIES),
  medical_conditions: validatePresentWithMessage(MEDICAL_CONDITIONS),
  onboarding_other_scripts: validatePresentWithMessage(OTHER_MEDICATIONS),
  other_scripts_list: validatePresentIf(
    (value, values) => !value && values.onboarding_other_scripts === YES_VALUE,
    OTHER_MEDICATIONS,
  ),
});

const OnboardingMedicalInfo = () => {
  const dispatch = useDispatchShared();
  const { trackEvent, trackPageView } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const previousUrl = useSelectorShared(previousWebURL);
  const origin = previousUrl ? routeFromPath(previousUrl) : '';
  const updateOnboardingUserError = useSelectorShared((state) => state.ui.errors.updateOnboardingUserError);
  const showProgyny = useSelectorShared(getShowProgynySelfServiceForUser);
  const {
    allergies: userAllergies,
    medical_conditions: userMedicalConditions,
    onboarding_other_scripts: userOnboardingOtherScripts,
    other_scripts_list: userOtherScriptsList,
  } = useSelectorShared(getCurrentUser) || {};
  const allergies = userAllergies || NO_KNOWN_ALLERGIES;
  const medicalConditions = userMedicalConditions || NO_KNOWN_MEDICAL_CONDITIONS;
  const onboardingOtherScripts = userOnboardingOtherScripts || DOES_NOT_HAVE_OTHER_SCRIPTS;
  const otherScriptsList = userOtherScriptsList;

  const onSave = async (userUpdate: {
    allergies?: string;
    allergens: AllergenParams[] | null;
    medical_conditions?: string;
    onboarding_other_scripts?: string;
    other_scripts_list: (AsyncValue | null | undefined) | (AsyncValue[] | null | undefined);
  }) => {
    const { onboarding_other_scripts, other_scripts_list } = userUpdate;

    let getOtherScriptLength: number | null = 0;

    // This is kinda gross, but for other scripts -- first checking to make sure type of other scripts list is an array,
    // then making it sure it isn't empty in the case patient doesn't enter a med.  If empty, return null instead of length
    if (onboarding_other_scripts === HAS_OTHER_SCRIPTS) {
      getOtherScriptLength =
        Array.isArray(other_scripts_list) && other_scripts_list.length ? other_scripts_list.length : null;
    }

    trackEvent({
      event: EVENTS.MED_INFO_SUBMITTED,
      params: {
        'has other scripts during onboarding': onboarding_other_scripts,
        'other scripts names': formatAsyncSelectSubmitValue(other_scripts_list),
        'total other scripts': getOtherScriptLength,
        origin: ORIGIN_NAMES.ONBOARDING,
        is_progyny_rx_view: showProgyny,
      },
    });

    const success = await dispatch(
      // eslint-disable-next-line import/no-deprecated
      updateOnboardingUser({
        ...userUpdate,
        other_scripts_list:
          onboarding_other_scripts !== HAS_OTHER_SCRIPTS
            ? ''
            : (formatAsyncSelectSubmitValue(other_scripts_list) ?? ''),
      }),
    );
    if (success) {
      // not using the nextRoute helper because we want to go to the next route based on the user's onboarding state
      // since the update is async, we wont get the latest nextRoute from the selector here until the next render
      dispatch(push(ROUTE_TO_URL[PHI_AUTHORIZATION]));
    } else {
      addToast(
        <Toast variant="error">{getErrorMessage(updateOnboardingUserError) || VERIFY_FAILURE_ERROR_MESSAGE}</Toast>,
      );
    }
  };

  useEffect(() => {
    dispatch(
      // eslint-disable-next-line import/no-deprecated
      updateOnboardingUser({
        onboard_state: ONBOARD_STATE_MEDICAL_INFO,
      }),
    );

    dispatch(
      markExperimentExposure({
        experimentName: 'patients-other-scripts',
        origin: ORIGIN_NAMES.ONBOARDING,
        bucket: BUCKET.TREATMENT,
      }),
    );
    trackPageView({
      event: EVENTS.ONBOARDING__MEDICAL_INFO_VIEWED,
      params: {
        origin,
        is_progyny_rx_view: showProgyny,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={{
        allergies,
        allergens: null,
        medical_conditions: medicalConditions,
        onboarding_other_scripts: onboardingOtherScripts,
        other_scripts_list: formatAsyncSelectInitialValue(otherScriptsList),
      }}
      validate={validate} // This prevents validation error form being shown after clicking textarea for first time
      validateOnBlur={false}
      onSubmit={onSave}
    >
      {/* @ts-expect-error formik is having issues with newest react */}
      {(formikProps: FormikProps<Values>) => <OnboardingMedicalInfoPage formikProps={formikProps} />}
    </Formik>
  );
};

export default OnboardingMedicalInfo;
