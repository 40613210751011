// @owners { team: patients-team }
import {
  ActionSheetContext,
  Button,
  Column,
  Description,
  Illustration,
  InlineAlert,
  MdSpacing,
  Row,
  SmSpacing,
  XsSpacing,
} from '@alto/design-system';
import { type Coupon } from '@alto/scriptdash/alto/billing/types/v1/coupon';
import React, { useContext, useEffect } from 'react';
import magnifyGlass from '~shared/assets/images/magnify_glass.png';
import { getNextDeliveryForPrescriptionID } from '~shared/features/delivery/selectors/getNextDeliveryForPrescriptionID';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';
import { ApplyCouponForm } from './ApplyCouponForm';

type Props = {
  readonly prescriptionID: number;
  readonly prescriptionUserID: number;
  readonly coupon: Coupon;
};

export const ManualCouponInfoBox = ({ prescriptionID, prescriptionUserID, coupon }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { trackPageView, trackEvent } = useAnalytics();
  const delivery = useSelectorShared((state) => getNextDeliveryForPrescriptionID(state, { prescriptionID }));

  useEffect(() => {
    trackPageView({
      event: EVENTS.MANUAL_COUPONS_COUPON_SEEN_BY_PATIENT,
      params: { coupon_id: coupon.id },
      additionalFields: { prescriptionId: prescriptionID, deliveryId: delivery?.id },
      enabled: !!delivery?.id,
    });
  }, [coupon.id, prescriptionID, delivery?.id, trackPageView]);

  const handleClickApplyCouponButton = () => {
    trackEvent({
      event: EVENTS.MANUAL_COUPONS_APPLY_COUPON_CLICKED,
      params: { coupon_id: coupon.id },
      additionalFields: { deliveryId: delivery?.id, prescriptionId: prescriptionID },
    });
    setActiveActionSheet(
      <ApplyCouponForm
        prescriptionID={prescriptionID}
        prescriptionUserID={prescriptionUserID}
      />,
    );
  };

  return (
    <>
      <SmSpacing />
      <InlineAlert>
        <Row>
          <Column flexShrink={1}>
            <Description fontFamily="bold">Save More With a Coupon</Description>
            <Description>The manufacturer requires you to sign up for it directly.</Description>
            <MdSpacing />
            <Row left>
              <Button
                type="secondary"
                small
                onPress={handleClickApplyCouponButton}
                label="Apply coupon"
                accessibilityLabel="Apply coupon"
              />
            </Row>
          </Column>
          <XsSpacing />
          <Column>
            <Illustration
              size="lg"
              source={magnifyGlass}
            />
          </Column>
        </Row>
      </InlineAlert>
    </>
  );
};
