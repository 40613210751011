// @owners { team: patients-team }
import { ActionSheetContext, LgSpacing, Row, SmSpacing, XlSpacing, XsSpacing } from '@alto/design-system';
import { useAddOnsContext } from '@alto/features';
import { type AddOnOtc } from '@alto/scriptdash/alto/patient_app/add_ons/types/v1/add_on_otc';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getItemQuantityForAddOnOtc } from '~shared/features/checkout/helpers';
import { type AnalyticsProps } from '~shared/features/essentials/AnalyticsProps';
import { useEssentialPrescriptionByProductID } from '~shared/features/essentials/hooks';
import { formatDollars } from '~shared/helpers/currency';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useAddOnsAddToCart } from '~shared/queries/useAddOnsAddToCart';
import { useAddOnsAddToShipment } from '~shared/queries/useAddOnsAddToShipment';
import { ADD_ONS_DELETE_MUTATION_KEY } from '~shared/queries/useAddOnsDeletePrescription';
import { ADD_ONS_UPDATE_MUTATION_KEY } from '~shared/queries/useAddOnsUpdatePrescription';
import { useIsMutating } from '~shared/react-query';
import { SelectPaymentMethodActionSheet } from './SelectPaymentMethodActionSheet';
import { ProductQuantityToggle } from '~web/features/essentials/components/ProductQuantityToggle';
import { ProductUpdateButton } from '~web/features/essentials/components/ProductUpdateButton';
import { Button, ButtonSideText, Text, View } from '~web/features/essentials/primitives';
import { useDeprecatedMediaQuery } from '~web/hooks';

type Props = {
  readonly product: AddOnOtc;
  readonly analyticsProps: AnalyticsProps;
  readonly showPrice: boolean;
  readonly isGridItem?: boolean;
  readonly onClose?: () => void;
};

export const ProductFooter = ({ product, analyticsProps, showPrice, isGridItem, onClose }: Props) => {
  const MAX_QUANTITY = 3;
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { onAdd, shipmentID, setActiveProduct, activeProduct } = useAddOnsContext();
  const { mutateAsync: addToCart, isPending: addToCartLoading } = useAddOnsAddToCart();
  const { mutateAsync: addToShipment, isPending: addToShipmentLoading } = useAddOnsAddToShipment();
  const { trackEvent } = useAnalytics();
  const active = !activeProduct || activeProduct === product.product_id;
  const { essential: prescription } = useEssentialPrescriptionByProductID({
    productID: product.product_id,
    shipmentID,
  });
  const defaultQuantity = useCallback(() => {
    if (prescription) return getItemQuantityForAddOnOtc(prescription, product?.quantity);
    return 1;
  }, [prescription, product?.quantity]);
  const [quantity, setQuantity] = useState(defaultQuantity);
  const updatePrescriptionLoading = !!useIsMutating({ mutationKey: ADD_ONS_UPDATE_MUTATION_KEY });
  const deletePrescriptionLoading = !!useIsMutating({ mutationKey: ADD_ONS_DELETE_MUTATION_KEY });
  const addingItem = addToShipmentLoading || addToCartLoading;
  const disabled = addingItem || updatePrescriptionLoading || deletePrescriptionLoading;
  const isMobile = !useDeprecatedMediaQuery('SM');
  const MIN_QUANTITY = useMemo(() => (prescription ? 0 : 1), [prescription]);

  // Reset quantity back to 1 if product was removed from order
  useEffect(() => {
    if (!prescription) {
      setQuantity(defaultQuantity());
    }
  }, [defaultQuantity, prescription]);

  const handleQuantityClick = (event: React.SyntheticEvent<HTMLSelectElement>) => {
    event.stopPropagation();
  };

  const handleQuantityPress = (event: React.SyntheticEvent<HTMLSelectElement>) => {
    setQuantity(Number(event.currentTarget.value));
  };

  const handleQuantityToggle = (increase?: boolean) => () => {
    setQuantity((number: number) => {
      if (increase) {
        return number >= MAX_QUANTITY ? MAX_QUANTITY : number + 1;
      }
      return number === MIN_QUANTITY ? MIN_QUANTITY : number - 1;
    });
  };

  const handleSelectPaymentMethod = useCallback(
    (createAndAdd: (paymentMethodID?: number) => void) => {
      trackEvent({ event: EVENTS.ADD_NEW_METHOD_TAPPED });
      setActiveActionSheet(<SelectPaymentMethodActionSheet onSubmit={createAndAdd} />);
    },
    [setActiveActionSheet, trackEvent],
  );

  const handleAdd = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (setActiveProduct) {
        setActiveProduct(product.product_id);
      }
      await onAdd(
        product,
        shipmentID ? addToShipment : addToCart,
        onClose ? onClose : () => undefined,
        quantity,
        analyticsProps,
        undefined,
        handleSelectPaymentMethod,
      );

      if (setActiveProduct) {
        setActiveProduct(null);
      }
    },
    [
      onAdd,
      addToShipment,
      addToCart,
      analyticsProps,
      onClose,
      product,
      quantity,
      setActiveProduct,
      shipmentID,
      handleSelectPaymentMethod,
    ],
  );

  const targetObject = shipmentID ? 'order' : 'cart';
  let button = (
    <Button
      title={`Add to ${targetObject}`}
      onClick={handleAdd}
      disabled={disabled}
      loading={(active && addingItem) ?? false}
      Right={showPrice ? <ButtonSideText text={formatDollars(quantity * product.price)} /> : null}
    />
  );
  if (prescription) {
    button = (
      <ProductUpdateButton
        itemQuantity={quantity}
        essential={product}
        analyticsProps={analyticsProps}
        showPrice={showPrice}
      />
    );
  }

  let spacing = <XlSpacing />;
  if (isGridItem) {
    spacing = <SmSpacing />;
  } else if (isMobile) {
    spacing = <LgSpacing />;
  }
  return (
    <>
      <Row
        spaceBetween
        center
        wrap={false}
      >
        <View
          flexDirection="column"
          justifyContent="center"
        >
          <Text
            title={formatDollars(product.price)}
            color="PRIMARY"
            variant={isGridItem ? 'body' : 'h3'}
          />
        </View>
        <XsSpacing />
        <ProductQuantityToggle
          itemQuantity={quantity}
          onIncrease={handleQuantityToggle(true)}
          onDecrease={handleQuantityToggle(false)}
          onQuantityClick={handleQuantityClick}
          onQuantityPress={handleQuantityPress}
          prescription={prescription}
        />
      </Row>
      {spacing}
      {button}
    </>
  );
};
