// @owners { team: patients-team }
import { ActionSheetContext, LoadingEmptyState, XlPadding } from '@alto/design-system';
import { useQueryLandingPageConfigs } from '@alto/features';
import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { fetchOnboardState, setTempPassword } from '~shared/actions/onboarding';
import { ONBOARD_STATE_DONE } from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { type Event } from '~shared/lib/analytics/src/types';
import { connect } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import ProgynyLandingPage from './ProgynyLandingPage';
import { VerifyActionSheet } from '~web/features/onboarding/components/redesign/VerifyActionSheet';
import { PartnershipLandingPage } from '~web/features/web-landing-page-tracker/components/redesign/PartnerLandingPage';

type StateProps = {
  readonly loading: boolean;
  readonly tempPassword: string;
};

type DispatchProps = {
  readonly dispatchFetchOnboardState: (arg0: string) => void;
  readonly dispatchSendAnalyticsEvent: (arg0: Event) => void;
  readonly dispatchSetTempPassword: (arg0: string) => void;
  readonly openLink: (arg0: string) => void;
};

type Props = StateProps & DispatchProps;

const ProgynyLandingPageContainer = ({
  dispatchFetchOnboardState,
  dispatchSendAnalyticsEvent,
  dispatchSetTempPassword,
  loading,
  openLink,
  tempPassword,
}: Props) => {
  const { trackPageView } = useAnalytics();
  const { setActiveActionSheet, closeActionSheet } = useContext(ActionSheetContext);
  const { partnershipName, isFetching } = useQueryLandingPageConfigs({ tempPassword });

  useEffect(() => {
    trackPageView({
      event: EVENTS.ONBOARDING__WEB_LANDING_PAGE_VIEWED,
      params: {
        is_progyny_rx_view: true,
      },
      additionalFields: {
        userIdentifier: tempPassword,
        userIdentifierType: 'temp_password',
      },
    });
  }, [trackPageView, tempPassword]);

  useEffect(() => {
    dispatchSetTempPassword(tempPassword);
  }, [tempPassword, dispatchSetTempPassword]);

  const handleOnboard = () => {
    dispatchSendAnalyticsEvent(
      createEvent(
        EVENTS.WEB_LANDING_PAGE__GET_STARTED_TAPPED,
        {
          is_progyny_rx_view: true,
        },
        {
          userIdentifier: tempPassword,
          userIdentifierType: 'temp_password',
        },
      ),
    );

    // @ts-expect-error TS(7006): Parameter 'result' implicitly has an 'any' type.
    // eslint-disable-next-line promise/catch-or-return, @typescript-eslint/no-confusing-void-expression
    dispatchFetchOnboardState(tempPassword).then((result) => {
      const onboardState = result.onboard_state;

      // If the user has already completed onboarding, redirect to login. Otherwise, show verify modal/drawer
      // eslint-disable-next-line promise/always-return
      if (onboardState === ONBOARD_STATE_DONE) {
        openLink('/');
      } else {
        setActiveActionSheet(
          <VerifyActionSheet
            tempPassword={tempPassword}
            onboardState={onboardState}
            onVerifySuccess={closeActionSheet}
          />,
        );
      }
    });
  };

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  if (partnershipName === 'progyny') {
    return (
      <PartnershipLandingPage
        handleGetStarted={handleOnboard}
        getStartedLoading={loading}
        tempPassword={tempPassword}
      />
    );
  }

  // Track this, ideally we'd be able to clean up ProgynyLandingPage but not sure if there's ever a time
  // we hit this and there's not a label on the patient.
  createEvent(EVENTS.PROGYNY_LANDING_PAGE_WRONG_PARTNERSHIP);
  return (
    <ProgynyLandingPage
      handleOnboard={handleOnboard}
      loading={loading}
    />
  );
};

type OwnProps = {
  params: {
    tempPassword: string;
  };
};

function mapStateToProps(state: ReduxStateShared, ownProps: OwnProps): StateProps {
  const { params } = ownProps;
  const { tempPassword } = params;
  const { fetchOnboardStateLoading } = getLoading(state);

  return {
    tempPassword,
    loading: fetchOnboardStateLoading,
  };
}

const mapDispatchToProps: DispatchProps = {
  dispatchSendAnalyticsEvent: sendAnalyticsEvent,
  dispatchSetTempPassword: setTempPassword,
  // eslint-disable-next-line import/no-deprecated
  dispatchFetchOnboardState: fetchOnboardState,
  openLink: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProgynyLandingPageContainer));
