// @owners { team: patients-team }
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { apiEndpointHandler } from '~shared/helpers/api';
import {
  WundercomMessagesEndpoint,
  type WundercomMessagesEndpointFetchAllResponse,
} from '~shared/types/wundercomMessagesEndpoint';
const wundercomMessagesEndpoint = WundercomMessagesEndpoint(apiEndpointHandler);

export const wundercomMessages = createQueryKeys('wundercomMessages', {
  fetchWundercomMessages: ({ supportCaseID }: { supportCaseID: number | null }) => ({
    queryKey: [{ supportCaseID }],
    queryFn: (): Promise<WundercomMessagesEndpointFetchAllResponse> =>
      wundercomMessagesEndpoint.fetchAll({
        support_case_id: Number(supportCaseID),
        pagination: null,
      }),
  }),
});
