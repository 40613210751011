// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Card, LgPadding } from '@alto/design-system';
import React from 'react';
import getDeliveriesForShipmentID from '~shared/features/delivery-info/selectors/getDeliveriesForShipmentID';
import { CHARGE_STATUS } from '~shared/features/payment-methods/constants';
import { isShipmentEditable } from '~shared/features/shipments/helpers/isShipmentEditable';
import { type ShipmentWithItems } from '~shared/features/shipments/types';
import { getAccessibilityLabel, getIllustration, getOrderStatusTitle } from '~shared/helpers/order';
import { useGetShipmentPaymentMethodsWithDetails } from '~shared/queries/query-keys/shipmentPaymentMethods';
import { useSelectorShared } from '~shared/store';
import { useGetDeliveryMethodsByShipmentIDs } from '../../delivery-methods';
import { OrderDetailsLogistics } from './OrderDetailsLogistics';
import { PartialFillAlert } from './PartialFillAlert';
import { ActionButtonsGroup } from './buttons/ActionButtonsGroup';

type OrderDetailsCardProps = {
  readonly handleCancelDelivery: () => void;
  readonly handleDownloadReceipt: () => void;
  readonly handleEditDelivery: () => void;
  readonly handleSnoozeDelivery?: () => void;
  readonly shipment: ShipmentWithItems;
  readonly children?: React.ReactNode;
};

export const OrderDetailsCard = ({
  handleCancelDelivery,
  handleDownloadReceipt,
  handleEditDelivery,
  handleSnoozeDelivery,
  shipment,
  children,
}: OrderDetailsCardProps) => {
  const { deliveryMethodsByShipmentID } = useGetDeliveryMethodsByShipmentIDs([shipment.id]);
  const deliveryMethod = deliveryMethodsByShipmentID[shipment.id];
  const shipmentPaymentMethods = useGetShipmentPaymentMethodsWithDetails({ shipmentID: shipment.id });
  const failedPaymentMethods = shipmentPaymentMethods
    ?.filter((spm) => spm.charge_status === CHARGE_STATUS.FAILED)
    .map((spm) => {
      const paymentMethodDetails = spm?.paymentMethod?.details;
      return `${paymentMethodDetails?.card_type} - ${paymentMethodDetails?.card_last_4}`;
    })
    .join(', ');
  const orderStatusTitle = getOrderStatusTitle({
    method: deliveryMethod,
    status: shipment.status,
    failedPaymentMethods,
  });
  const illustrationArgs = { method: deliveryMethod, status: shipment.status };
  const deliveries = useSelectorShared((state) =>
    getDeliveriesForShipmentID(state, { shipmentID: shipment.shipmentID }),
  );
  const partialFillDeliveryIDs = deliveries.filter((d) => d.med_sync_status === 'short_fill').map((d) => d.id);
  const hasPartialFill = partialFillDeliveryIDs.length > 0;

  return (
    <Card
      illustrationAccessibilityLabel={getAccessibilityLabel(illustrationArgs)}
      illustrationSrc={getIllustration(illustrationArgs)}
      title={orderStatusTitle}
    >
      {hasPartialFill ? (
        <LgPadding
          bottomPadding={SPACING.STATIC.MD}
          topPadding={SPACING.STATIC.NONE}
        >
          <PartialFillAlert
            partialFillDeliveryIDs={partialFillDeliveryIDs}
            shipmentID={shipment.id}
          />
        </LgPadding>
      ) : null}
      <OrderDetailsLogistics
        failedPaymentMethods={failedPaymentMethods}
        shipment={shipment}
        showTracking
        status={shipment.status}
      />
      <ActionButtonsGroup
        charged={shipment.charged}
        handleEditShipment={handleEditDelivery}
        handleDownloadReceipt={handleDownloadReceipt}
        handleOpenSnooze={handleSnoozeDelivery}
        method={deliveryMethod}
        openCancelDeliveryModal={handleCancelDelivery}
        shipmentFees={shipment.shipment_fees}
        shipmentID={shipment.id}
        shipmentIsEditable={shipment ? isShipmentEditable(shipment) : undefined}
        status={shipment ? shipment.status : undefined}
        userID={shipment.user_id}
      />
      {children}
    </Card>
  );
};
