// @owners { team: patients-team }
import { useMemo } from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { getUsers } from '~shared/features/users/selectors/getUsers';
import { isControl as isControlledMed } from '~shared/helpers/helper';
import { useSelectorShared } from '~shared/store';
import { type Prescription, type User } from '~shared/types';

const getUsersWhoNeedPhotoID = (prescriptions: Prescription[], users: User[] | []) => {
  return prescriptions.reduce<User[]>((usersWhoNeedPhotoID, prescription) => {
    const user = users.find((u) => u.id === prescription.user_id);
    const isControl = isControlledMed(prescription);
    const hasNoPhotoID = user && !user.photo_id_url;
    const userHasNotBeenAdded = user && !usersWhoNeedPhotoID.find((u) => u.id === user.id);

    if (user && isControl && hasNoPhotoID && userHasNotBeenAdded) {
      usersWhoNeedPhotoID.push(user);
    }

    return usersWhoNeedPhotoID;
  }, []);
};

const getUsersInCart = (prescriptions: Prescription[], users: User[] | []) => {
  const userIDsInCart = prescriptions.reduce<number[]>((ids, item) => {
    if (item?.user_id) {
      ids.push(item.user_id);
    }

    return ids;
  }, []);
  return users.filter((user) => userIDsInCart.includes(user.id));
};
export const useCartUsers = () => {
  const { prescriptions } = usePrescriptionsInCart();
  const users = useSelectorShared(getUsers);

  const usersInCart = useMemo(() => getUsersInCart(prescriptions, users), [prescriptions, users]);
  const usersWhoNeedPhotoID = useMemo(() => getUsersWhoNeedPhotoID(prescriptions, users), [prescriptions, users]);

  return {
    users: usersInCart,
    usersWhoNeedPhotoID,
    isPhotoIDRequired: usersWhoNeedPhotoID.length > 0,
  };
};
