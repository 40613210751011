// @owners { team: patients-team }
import { Experimentation } from '@alto/experimentation';
import React, { useEffect, useState } from 'react';
import { push } from 'react-router-redux';
import { completeOnboarding } from '~shared/actions/auth';
import { openModal } from '~shared/actions/modal';
import { clearOnboardingState } from '~shared/actions/onboarding';
// eslint-disable-next-line import/no-deprecated
import { fetchAppDownloadLink } from '~shared/actions/users';
import { ONBOARD_SUCCESS } from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import { getTempPassword } from '~shared/features/onboarding/selectors/getOnboarding';
import { getOnboardingSuccessMessageType } from '~shared/features/onboarding/selectors/getOnboardingSuccessMessageType';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { getCurrentUserFirstName } from '~shared/features/users/selectors/getCurrentUser';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { isMobileWeb } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { previousWebURL } from '~shared/lib/analytics/src/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import OnboardingProviderSuccess from './OnboardingProviderSuccess';
import { nextUrl } from '~web/features/onboarding/helpers/urlHandler';

const OnboardingProviderSuccessContainer = () => {
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const tempPassword = useSelectorShared(getTempPassword);
  const showProgyny = useSelectorShared(getShowProgynySelfServiceForUser);
  const messageType = useSelectorShared(getOnboardingSuccessMessageType);
  const { value: shouldRemoveMedVerification } = Experimentation.useFeatureFlag('remove_onboarding_med_verification');
  const nextRoute = useSelectorShared((state) =>
    nextUrl({ state, currentRoute: ONBOARD_SUCCESS, useOnboardingRoutingV2: shouldRemoveMedVerification }),
  );
  const { fetchAppDownloadLinkLoading } = useSelectorShared(getLoading);
  const firstName = useSelectorShared(getCurrentUserFirstName);
  const previousWebUrl = useSelectorShared(previousWebURL);
  const origin = routeFromPath(previousWebUrl ?? '');
  const [appDownloadUrl, setAppDownloadUrl] = useState('');
  const [appDownloadClicked, setAppDownloadClicked] = useState(false);

  useEffect(() => {
    if (showProgyny) {
      // eslint-disable-next-line promise/catch-or-return, import/no-deprecated
      dispatch(fetchAppDownloadLink(tempPassword ?? '')).then((url: string) => {
        setAppDownloadUrl(url);

        // Open the link immediately if the user wanted to open the link but it wasn't available yet
        // eslint-disable-next-line promise/always-return
        if (appDownloadClicked) {
          window.location.assign(url);
        }
      });
    }
  }, [showProgyny, tempPassword, appDownloadClicked, dispatch]);

  const onContinue = () => {
    dispatch(clearOnboardingState());
    dispatch(completeOnboarding());

    if (showProgyny) {
      trackEvent({
        event: EVENTS.PROGYNY_UPSELL__DOWNLOAD_APP_TAPPED,
      });
      if (appDownloadUrl) {
        window.location.assign(appDownloadUrl);
      } else {
        setAppDownloadClicked(true);
      }
    } else {
      dispatch(push(nextRoute));

      if (isMobileWeb()) {
        setTimeout(() => {
          dispatch(
            openModal('GET_THE_APP_DRAWER', {
              tempPassword,
            }),
          );
        }, 300);
      }
    }
  };

  return (
    <OnboardingProviderSuccess
      onContinue={onContinue}
      messageType={messageType}
      showProgyny={showProgyny}
      fetchAppDownloadLinkLoading={fetchAppDownloadLinkLoading}
      firstName={firstName}
      origin={origin}
    />
  );
};

export default OnboardingProviderSuccessContainer;
