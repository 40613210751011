// @owners { team: patients-team }
import {
  Body,
  Button,
  Card,
  LgPadding,
  LoadingEmptyState,
  SecondaryPage,
  Toast,
  ToastContext,
  XlPadding,
} from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { useQueryInsuranceConfigs } from '@alto/features';
import { useNavigation } from '@alto/navigation';
import { useQueryClient } from '@tanstack/react-query';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Formik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { createPhotoInsurance } from '~shared/actions/insurances';
// eslint-disable-next-line import/no-deprecated
import { setEditingInsuranceID, updateOnboardingUser } from '~shared/actions/onboarding';
import { VERIFY_FAILURE_ERROR_MESSAGE } from '~shared/constants';
import getIsPhotoUpload from '~shared/features/insurances/helpers/getIsPhotoUpload';
import { getInsuranceByID } from '~shared/features/insurances/selectors/getInsuranceById';
import {
  INSURANCE_INPUT_PHOTO,
  ONBOARD_STATE_INSURANCE_INPUT,
} from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import { getEditingOnboardingInsuranceID } from '~shared/features/onboarding/selectors/getOnboarding';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { getErrorMessage } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { previousWebURL } from '~shared/lib/analytics/src/helper';
import { queries } from '~shared/queries/query-keys';
import getInsuranceError from '~shared/selectors/ui/errors/getInsuranceError';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type Insurance } from '~shared/types';
import { validatePresent } from '~shared/validations';
import { FAQs } from './FAQs';
import { OnboardingNavBarLogo } from './OnboardingNavBarLogo';
import { InsurancePhotoUploadButtons } from '~web/features/insurances/components/InsurancePhotoUploadButtons';
import { OnboardingHeader } from '~web/features/onboarding/components/redesign/OnboardingHeader';
import { nextUrl, previousUrl } from '~web/features/onboarding/helpers/urlHandler';

const OnboardingInsuranceInputPhoto = () => {
  const queryClient = useQueryClient();
  const { trackEvent, trackPageView } = useAnalytics();
  const dispatch = useDispatchShared();
  const { addToast } = useContext(ToastContext);
  const { getCurrentRouteName } = useNavigation();
  const routeName = getCurrentRouteName() || '';
  const currentRoute = routeFromPath(routeName);
  const { value: shouldRemoveMedVerification } = Experimentation.useFeatureFlag('remove_onboarding_med_verification');
  const onboardingPreviousUrl = useSelectorShared((state) =>
    previousUrl({ state, currentRoute, useOnboardingRoutingV2: shouldRemoveMedVerification }),
  );
  const loading = useSelectorShared((state) => state.ui.loading.createPhotoInsuranceLoading);
  const previousWebUrl = useSelectorShared(previousWebURL);
  const origin = previousWebUrl ? routeFromPath(previousWebUrl) : '';
  const insuranceID = useSelectorShared(getEditingOnboardingInsuranceID);
  const insurance = useSelectorShared((state) => getInsuranceByID(state, insuranceID));
  const viewOnly = getIsPhotoUpload(insurance);
  const showProgyny = useSelectorShared(getShowProgynySelfServiceForUser);
  const nextRoute = useSelectorShared((state) =>
    nextUrl({ state, currentRoute: INSURANCE_INPUT_PHOTO, useOnboardingRoutingV2: shouldRemoveMedVerification }),
  );
  const previousRoute = useSelectorShared((state) =>
    previousUrl({ state, currentRoute: INSURANCE_INPUT_PHOTO, useOnboardingRoutingV2: shouldRemoveMedVerification }),
  );
  const error = useSelectorShared(getInsuranceError);
  const { headerBackgroundColor, headerLogoUrl, isFetching } = useQueryInsuranceConfigs();

  const title = showProgyny ? 'Take a photo of your insurance' : 'Take a photo of your benefits card';
  const subTitle = showProgyny
    ? 'Make sure to include both sides of your insurance card and take the photos on a neutral background.'
    : 'Include both sides of your insurance card, and take the photos on a neutral background.';

  const onBack = () => {
    if (onboardingPreviousUrl) {
      dispatch(push(onboardingPreviousUrl));
    }
  };

  const onSave = async (newInsurance: Pick<Insurance, 'image_url' | 'image_url_2'>) => {
    const frontImageError = validatePresent(newInsurance.image_url);
    const backImageError = validatePresent(newInsurance.image_url_2);
    if (frontImageError || backImageError) {
      addToast(<Toast variant="error">Please include both sides of your card.</Toast>);
      return;
    }
    const imageURLs = [
      newInsurance.image_url?.length && newInsurance.image_url[0],
      newInsurance.image_url_2?.length && newInsurance.image_url_2[0],
    ];

    const filteredImageURLs = imageURLs.filter(Boolean) as string[];
    // @ts-expect-error I'm pretty convinced we're actually using createPhotoInsurance wrong here but it's out-of-scope
    // of this PR to fix it.
    // eslint-disable-next-line import/no-deprecated
    const savedInsurance: Insurance = await dispatch(createPhotoInsurance(filteredImageURLs));
    if (!savedInsurance) {
      addToast(<Toast variant="error">{getErrorMessage(error) || VERIFY_FAILURE_ERROR_MESSAGE}</Toast>);
      return;
    }
    if (!insurance) {
      trackEvent({
        event: EVENTS.INSURANCE_ADDED,
        params: {
          source: 'onboarding',
          type: 'photo',
          entity_name: savedInsurance.insurance_plan_name ?? '',
        },
      });
    }
    queryClient.invalidateQueries({ queryKey: queries.insurances.fetchAll._def });
    dispatch(setEditingInsuranceID(null));
    dispatch(push(nextRoute));
  };

  const onCancel = () => {
    dispatch(setEditingInsuranceID(null));
    if (previousRoute) {
      dispatch(push(previousRoute));
    }
  };

  useEffect(() => {
    dispatch(
      // eslint-disable-next-line import/no-deprecated
      updateOnboardingUser({
        onboard_state: ONBOARD_STATE_INSURANCE_INPUT,
      }),
    );

    // Unset editing insurance ID if user navigates away using the back button, instead of save or cancel button
    return () => {
      dispatch(setEditingInsuranceID(null));
    };
  }, [dispatch]);

  useEffect(() => {
    trackPageView({
      event: EVENTS.ONBOARDING__INSURANCE_INPUT_PHOTO_VIEWED,
      params: {
        origin,
        is_progyny_rx_view: showProgyny,
      },
    });
  }, [origin, showProgyny, trackPageView]);

  const addBenefitButtonText = showProgyny ? 'Save insurance' : 'Save benefit';

  const initialValues = {
    image_url: insurance?.image_url || '',
    image_url_2: insurance?.image_url_2 || '',
  };

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSave}
      enableReinitialize
    >
      {(formikProps) => (
        <SecondaryPage
          headerBackgroundColor={headerBackgroundColor}
          withoutWebFramingElementHeights
          dismissIcon="chevronleft"
          onDismiss={onBack}
          NavBarCenterContent={<OnboardingNavBarLogo illustrationSrc={headerLogoUrl} />}
          NavBarRightPressable={<FAQs />}
          HeaderContent={
            <OnboardingHeader
              title={title}
              subtitle={<Body center>{subTitle}</Body>}
            />
          }
          buttons={[
            <Button
              label="Cancel"
              onPress={onCancel}
              type="tertiary"
              key="cancel button"
              loading={loading}
            />,
            <Button
              label={addBenefitButtonText}
              onPress={formikProps.handleSubmit}
              loading={loading}
              key="submit button"
              disabled={viewOnly}
            />,
          ]}
          footerPlacementContext="webScreenWithoutPolicyFooter"
        >
          <Card>
            <LgPadding>
              <InsurancePhotoUploadButtons
                loading={loading}
                onFormChange={formikProps.setFieldValue}
                initialFrontImageUrl={initialValues.image_url}
                initialBackImageUrl={initialValues.image_url_2}
                disabled={viewOnly}
              />
            </LgPadding>
          </Card>
        </SecondaryPage>
      )}
    </Formik>
  );
};

export default OnboardingInsuranceInputPhoto;
