// @owners { team: patients-team }
import { BORDERS, SIZES } from '@alto/design-library-tokens';
import { AccessibilityInfo, Card, Description, MdPadding, Row, SizedImage, Tag, XsSpacing } from '@alto/design-system';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import styledNative, { css } from 'styled-components/native';
import getIsPhotoUpload from '~shared/features/insurances/helpers/getIsPhotoUpload';
import { PLAN_NAME_TO_LOGO } from '~shared/features/onboarding/helpers/insuranceLogos';
import { capitalizeString } from '~shared/helpers/string';
import { type Insurance as InsuranceType } from '~shared/types/clients';

const popIn = css`
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

/* eslint-disable @typescript-eslint/restrict-template-expressions */
const StyledCard = styledNative(Card)<{ isReduceMotionEnabled: boolean }>`
  border-radius: ${BORDERS.RADIUS.LG.px};
  ${({ isReduceMotionEnabled }) =>
    isReduceMotionEnabled &&
    `
    animation-duration: 600ms;
    animation-name: ${popIn};
    animation-fill-mode: forwards;
  `}
`;
/* eslint-enable @typescript-eslint/restrict-template-expressions */

const Img = styled.img<{ sidebar: boolean }>`
  width: 100px;
  height: 40px;
`;

const LabeledInfo = ({ label, info }: { readonly label: string; readonly info?: string }) => (
  <Row>
    <Description fontFamily="semibold">{label}</Description>
    <XsSpacing />
    <Description>{info}</Description>
  </Row>
);

const InsuranceCard = ({ insurance }: { readonly insurance: InsuranceType }) => {
  const { first_name, last_name, insurance_plan_name, is_active, bin, pcn } = insurance;
  const insuranceLogo = insurance_plan_name && PLAN_NAME_TO_LOGO[insurance_plan_name];
  const [isReduceMotionEnabled, setIsReduceMotionEnabled] = useState(true);

  useEffect(() => {
    const handleSetReduceMotion = async () => {
      const enabled = await AccessibilityInfo.isReduceMotionEnabled();
      setIsReduceMotionEnabled(enabled);
    };
    handleSetReduceMotion();
  }, []);

  const insuranceStillProcessing = getIsPhotoUpload(insurance);

  return (
    <StyledCard isReduceMotionEnabled={isReduceMotionEnabled}>
      {insuranceStillProcessing ? (
        <MdPadding>
          <Row
            testID="card-images"
            spaceEvenly
          >
            {insurance.image_url ? (
              <SizedImage
                borderRadius={BORDERS.RADIUS.MD.value}
                pixelSize={SIZES.ILLUSTRATION.XXL.value}
                maxHeight={SIZES.ILLUSTRATION.XL.value}
                resizeMode="cover"
                source={{ uri: insurance.image_url }}
              />
            ) : null}
            {insurance.image_url_2 ? (
              <SizedImage
                borderRadius={BORDERS.RADIUS.MD.value}
                pixelSize={SIZES.ILLUSTRATION.XXL.value}
                maxHeight={SIZES.ILLUSTRATION.XL.value}
                resizeMode="cover"
                source={{ uri: insurance.image_url_2 }}
              />
            ) : null}
          </Row>
        </MdPadding>
      ) : (
        <MdPadding>
          <Row
            spaceBetween
            centerVertically
          >
            {insuranceLogo ? (
              <Img
                aria-hidden
                sidebar
                src={insuranceLogo}
              />
            ) : null}
            {!!insurance_plan_name && (
              <>
                <Description>{capitalizeString(insurance_plan_name)}</Description>
                {!is_active && (
                  <Tag
                    label="inactive"
                    type="warning"
                  />
                )}
              </>
            )}
          </Row>
          {first_name || last_name ? (
            <>
              <XsSpacing />
              <Row spaceBetween>
                <Description>
                  {first_name} {last_name}
                </Description>
                {!insurance_plan_name && !is_active && (
                  <Tag
                    label="inactive"
                    type="warning"
                  />
                )}
              </Row>
              <XsSpacing />
            </>
          ) : null}
          <Row spaceBetween>
            <LabeledInfo
              label="BIN"
              info={bin ?? ''}
            />
            {!insurance_plan_name && !first_name && !last_name && !is_active && (
              <Tag
                label="inactive"
                type="warning"
              />
            )}
          </Row>
          <LabeledInfo
            label="PCN"
            info={pcn ?? ''}
          />
        </MdPadding>
      )}
    </StyledCard>
  );
};

export default InsuranceCard;
