// @owners { team: patients-team }
export const ADDRESS_DELIVERY_METHOD_DESCRIPTIONS = {
  mail_only: 'This address is in our mail delivery zone.',
  courier: 'This address is in our courier zone.',
};

export const ADDRESS_FORM_ERROR_MESSAGES = {
  google_street_address:
    'Select an address from the dropdown after typing. If your address cannot be selected, please contact us.',
  kind: 'Address type must be specified.',
  safe_place: 'Please indicate whether a safe place exists.',
  safe_place_instructions: 'Safe place description must be added.',
  safe_place_delivery_conflict:
    'If you will not be present, you must indicate that packages may be left at this address.',
  out_of_zone_address:
    'Sorry, this address is currently outside Alto’s service area. Please try a different address, or contact us for further assistance.',
};
