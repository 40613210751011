// @owners { team: patients-team }
import { AltoIcon, Body, Button, Card, ListItem, MdSpacing, SecondaryPage } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { useQueryInsuranceConfigs } from '@alto/features';
import { useNavigation } from '@alto/navigation';
import React, { useEffect } from 'react';
import { push } from 'react-router-redux';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useGetInsurances } from '~shared/queries/query-keys/insurances';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { FAQs } from './FAQs';
import { OnboardingNavBarLogo } from './OnboardingNavBarLogo';
import { OnboardingHeader } from '~web/features/onboarding/components/redesign/OnboardingHeader';
import { previousUrl as getPreviousUrl } from '~web/features/onboarding/helpers/urlHandler';
import {
  getOnboardingInsuranceInputSubtitle,
  getOnboardingInsuranceInputTitle,
} from '~web/features/onboarding/helpers/utils';

export type Props = {
  readonly onUploadPhoto: () => void;
  readonly onEnterManually: () => void;
  readonly onDeclineInsurance: () => Promise<void>;
  readonly onSkip: () => void;
  readonly showProgyny: boolean;
  readonly origin: string;
};

export const OnboardingInsuranceInput = ({
  onDeclineInsurance,
  onEnterManually,
  onSkip,
  onUploadPhoto,
  origin,
  showProgyny,
}: Props) => {
  const dispatch = useDispatchShared();
  const { trackPageView } = useAnalytics();
  const { getCurrentRouteName } = useNavigation();
  const routeName = getCurrentRouteName() || '';
  const currentRoute = routeFromPath(routeName);
  const { value: shouldRemoveMedVerification } = Experimentation.useFeatureFlag('remove_onboarding_med_verification');
  const previousUrl = useSelectorShared((state) =>
    getPreviousUrl({ state, currentRoute, useOnboardingRoutingV2: shouldRemoveMedVerification }),
  );
  const declineInsuranceLoading = useSelectorShared((state) => state.ui.loading.declineInsuranceLoading);
  const { headerBackgroundColor, headerLogoUrl } = useQueryInsuranceConfigs();
  const { hasInsurance } = useGetInsurances();

  const titleText = getOnboardingInsuranceInputTitle({
    hasInsurance,
    showProgyny,
  });
  const subtitleText = getOnboardingInsuranceInputSubtitle({
    showProgyny,
  });

  const onBack = () => {
    if (previousUrl) {
      dispatch(push(previousUrl));
    }
  };

  useEffect(() => {
    trackPageView({
      event: EVENTS.ONBOARDING__INSURANCE_INPUT_VIEWED,
      params: { origin, is_progyny_rx_view: showProgyny },
    });
  }, [origin, showProgyny, trackPageView]);

  const handleSelectInsuranceInputMethod = (insuranceType: 'PHOTO' | 'MANUAL' | 'NONE') => {
    switch (insuranceType) {
      case 'PHOTO':
        return onUploadPhoto;

      case 'MANUAL':
        return onEnterManually;

      case 'NONE':
        return onDeclineInsurance;

      default:
        return;
    }
  };

  return (
    <SecondaryPage
      headerBackgroundColor={headerBackgroundColor}
      withoutWebFramingElementHeights
      dismissIcon="chevronleft"
      onDismiss={onBack}
      NavBarCenterContent={<OnboardingNavBarLogo illustrationSrc={headerLogoUrl} />}
      NavBarRightPressable={<FAQs />}
      HeaderContent={
        <OnboardingHeader
          title={titleText}
          subtitle={<Body center>{subtitleText}</Body>}
        />
      }
      buttons={[
        <Button
          key="skip"
          label="Remind me later"
          onPress={onSkip}
          type="tertiary"
        />,
      ]}
      footerPlacementContext="webScreenWithoutPolicyFooter"
    >
      <Card>
        <ListItem
          title="Take a photo"
          LeftContent={<AltoIcon name="camera-duo" />}
          RightContent={
            <AltoIcon
              name="chevronright-small"
              type="grey"
            />
          }
          onPress={handleSelectInsuranceInputMethod('PHOTO')}
        />
      </Card>
      <MdSpacing />
      <Card>
        <ListItem
          title="Enter it manually"
          LeftContent={<AltoIcon name="signpaper-duo" />}
          RightContent={
            <AltoIcon
              name="chevronright-small"
              type="grey"
            />
          }
          onPress={handleSelectInsuranceInputMethod('MANUAL')}
        />
      </Card>
      {hasInsurance ? null : (
        <>
          <MdSpacing />
          <Card>
            <ListItem
              title="I don't have any benefits"
              LeftContent={<AltoIcon name="shieldslash-duo" />}
              RightContent={
                <AltoIcon
                  name="chevronright-small"
                  type="grey"
                />
              }
              onPress={handleSelectInsuranceInputMethod('NONE')}
              loading={declineInsuranceLoading}
            />
          </Card>
        </>
      )}
    </SecondaryPage>
  );
};
