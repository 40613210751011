// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { ActionSheetContext, ActionSheetV2, Button, InputCheckbox, Switch } from '@alto/design-system';
import { type FilterType } from '@alto/scriptdash/alto/medications/patients/v2/list_endpoint';
import React, { useCallback, useContext, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { TypescriptUtils } from '~shared/TypescriptUtils';
import { setMedListFilters } from '~shared/actions/ui/medList';
import { type CheckboxStateByFilterType } from '~shared/reducers/ui/medList';
import { getMedListFilters } from '~shared/selectors/ui/medList';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { getNextToggleState } from './toggleCheckbox';

// exported for tests
export const filterActionSheetContent: Record<FilterType, string> = {
  ALL: 'All medications',
  ACTIVE: 'Active prescriptions',
  INACTIVE: 'No active prescriptions',
};

const filterKeys = TypescriptUtils.objectKeys(filterActionSheetContent);

export const FilterActionSheet = () => {
  const { closeActionSheet } = useContext(ActionSheetContext);
  const dispatch = useDispatchShared();
  const filters = useSelectorShared(getMedListFilters);
  const [checkedByInputName, setCheckedByInputName] = useState(filters);
  const handleToggleCheckbox = useCallback(
    (name: FilterType) => () => {
      setCheckedByInputName((prevState) => getNextToggleState(prevState, name) as CheckboxStateByFilterType);
    },
    [],
  );

  const applyFilters = useCallback(() => {
    dispatch(setMedListFilters(checkedByInputName));
    closeActionSheet();
  }, [checkedByInputName, closeActionSheet, dispatch]);

  return (
    <ActionSheetV2
      title="Filter medications"
      analyticsName="filter by filter type"
      buttons={[
        <Button
          key="apply-filter"
          label="Apply"
          onPress={applyFilters}
          disabled={!Object.values(checkedByInputName).some(Boolean)}
        />,
      ]}
    >
      <>
        {filterKeys.map((filterType) => {
          const label = filterActionSheetContent[filterType];

          if (filterType === 'ALL') {
            return (
              <View
                key={`filter-${filterType}`}
                style={styles.switchContainer}
              >
                <Switch
                  label={label}
                  onValueChange={handleToggleCheckbox(filterType)}
                  value={checkedByInputName[filterType]}
                  labelColor={COLORS.PALETTE.PRIMARY.DEFAULT}
                />
              </View>
            );
          }

          return (
            <InputCheckbox
              key={`filter-${filterType}`}
              label={label}
              name={filterType}
              noHighlight
              onPress={handleToggleCheckbox(filterType)}
              value={checkedByInputName[filterType]}
            />
          );
        })}
      </>
    </ActionSheetV2>
  );
};

const styles = StyleSheet.create({
  switchContainer: {
    marginLeft: 24,
    marginRight: 21,
  },
});
