// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Body, Column, Fade, Illustration, LgSpacing, MdSpacing } from '@alto/design-system';
import { useQuerySuccessConfigs } from '@alto/features';
import React, { useEffect } from 'react';
import { IMG_ALT_TEXTS } from '~shared/constants';
import {
  type OnboardingSuccessMessageType,
  PROCESSING_INFORMATION,
  SCHEDULE_DELIVERY,
} from '~shared/features/onboarding/selectors/getOnboardingSuccessMessageType';
import { useOnboardingConfirmationViewed } from '~shared/lib/analytics/src/hooks/useOnboardingConfirmationViewed';
import { OnboardingButtonDesktop, OnboardingButtonMobile } from './OnboardingButton';
import OnboardingPageBackground from './OnboardingPageBackgroundContainer';
import { Header } from '~web/components/HeaderImage';
import { OnboardingContentWrapper } from '~web/components/Page';
import courier from '~web/images/courier.gif';

const messageOptions = {
  [SCHEDULE_DELIVERY]: 'Your medications are ready for delivery! Add an item to your cart and check out.',
  [PROCESSING_INFORMATION]: 'Your medications will be ready for delivery soon.',
};

type Props = {
  readonly onContinue: () => void;
  readonly messageType: OnboardingSuccessMessageType;
  readonly showProgyny: boolean;
  readonly fetchAppDownloadLinkLoading: boolean;
  readonly firstName: string | null | undefined;
  readonly origin?: string;
};

const OnboardingProviderSuccess = ({
  onContinue,
  messageType,
  showProgyny,
  fetchAppDownloadLinkLoading,
  firstName,
  origin,
}: Props) => {
  useOnboardingConfirmationViewed(showProgyny, origin);
  const { closingImageUrl, closingText } = useQuerySuccessConfigs();
  // If this is not Progyny onboarding, redirect after 4s to med list
  useEffect(() => {
    if (!showProgyny) {
      setTimeout(() => {
        onContinue();
      }, 4000);
    }
  }, [showProgyny, onContinue]);

  let title = 'Way to go!';

  if (firstName) {
    title = `Way to go, ${firstName}!`;
  }

  let subtitle = closingText || messageOptions[messageType];
  let buttonCopy = '';

  if (showProgyny) {
    title = 'Final step!\nDownload the Alto app';
    subtitle = 'The Alto app is required to schedule your fertility medications for delivery';
    buttonCopy = 'Download App';
  }

  const successComponent = (
    <OnboardingPageBackground>
      <OnboardingContentWrapper>
        <Column center>
          <Header title={title} />
          <MdSpacing />
          <Body
            color={COLORS.TEXT_COLORS.GREY}
            center
          >
            {subtitle}
          </Body>
          <LgSpacing />
          <Illustration
            source={closingImageUrl || courier}
            accessibilityLabel={IMG_ALT_TEXTS.bikeCourier}
            size="xxl"
          />
        </Column>
        {showProgyny ? (
          <OnboardingButtonDesktop
            onClick={onContinue}
            loading={fetchAppDownloadLinkLoading}
            label={buttonCopy}
          />
        ) : null}
      </OnboardingContentWrapper>
      {showProgyny ? (
        <OnboardingButtonMobile
          onClick={onContinue}
          loading={fetchAppDownloadLinkLoading}
          label={buttonCopy}
        />
      ) : null}
    </OnboardingPageBackground>
  );

  if (showProgyny) {
    return successComponent;
  }

  return (
    <Fade
      direction="out"
      delay={3300}
      duration={700}
    >
      {successComponent}
    </Fade>
  );
};

export default OnboardingProviderSuccess;
