// @owners { team: patients-team }
import React from 'react';
import { Platform } from 'react-native';
import type { WebViewProps } from 'react-native-webview';

const WebView: React.FC<WebViewProps> = (props) => {
  if (Platform.OS === 'web') {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    const WebWebView = require('react-native-web-webview').default;
    return (
      <WebWebView
        testID="web-view"
        {...props}
      />
    );
  } else {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    const NativeWebView = require('react-native-webview').WebView;
    return (
      <NativeWebView
        testID="web-view"
        {...props}
      />
    );
  }
};

export { WebView };
