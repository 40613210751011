import { H3, Link, ListDescription, ListItem, Tag } from '@alto/design-system';
import React from 'react';
import { formatCityState, formatStreetAddress } from '~shared/helpers/address';
import { type Address } from '~shared/types';

type Props = {
  readonly address: Address;
  // Editing an address is a modal on web and a screen on native.
  // This is here temporarily until we migrate the screen on native to
  // an ActionSheet.
  readonly onEditAddress: (address: Address) => void;
};

export const addressListDescriptions = (address: Address) => {
  const descriptions = [
    <ListDescription key={`address-city-state-${address.id}`}>
      <H3>{formatCityState(address)}</H3>
    </ListDescription>,
    <ListDescription
      key={`address-delivery-zone-${address.id}`}
      iconName={address.in_courier_zone ? 'car-small' : 'box-small'}
    >
      {address.in_courier_zone ? 'Courier delivery zone' : 'Mail delivery zone'}
    </ListDescription>,
  ];
  if (address.in_courier_zone) {
    descriptions.push(
      <ListDescription
        key={`address-safe-place-instructions-${address.id}`}
        iconName={address.safe_place ? 'homelock-small' : 'handbox-small'}
      >
        {address.safe_place ? 'Packages may be left' : 'Hand packages to recipient'}
      </ListDescription>,
    );
  }

  return descriptions;
};

export const AddressListItem = ({ address, onEditAddress }: Props) => {
  const handleEditAddress = (address: Address) => () => {
    onEditAddress(address);
  };

  return (
    <ListItem
      title={formatStreetAddress(address)}
      descriptions={addressListDescriptions(address)}
      RightContent={<Link onPress={handleEditAddress(address)}>Edit</Link>}
      tagPlacement="bottom"
      tags={address.primary ? <Tag label="Default address" /> : undefined}
    />
  );
};
