import { PHONE } from '~shared/constants';

export const DATE_LOADING = 'date_loading';
export const DATE_REFRIGERATED = 'date_refrigerated';
export const DATE_TOO_EARLY = 'date_too_early';
export const DATE_TOO_LATE = 'date_too_late';
export const DATE_INVALID_MAIL = 'date_invalid_mail';
export const EXPENSIVE = 'expensive';
export const HAS_CONTROLS = 'has_controls';
export const MAIL_ONLY_ZONE = 'mail_only_zone';
export const NO_ADDRESS_SELECTED = 'no_address_selected';
export const NO_DATE_SELECTED = 'no_date_selected';
export const NO_ORDER_ITEMS = 'no_order_items';
export const NO_PAYMENT_METHOD_SELECTED = 'no_payment_method_selected';
export const NO_SAFE_PLACE = 'no_safe_place';
export const NO_SAFE_PLACE_WITH_WAIVE_SIG_SELECTED = 'no_safe_place_with_waive_sig_selected';
export const NO_SHARPS_CONTAINER_SELECTED = 'no_sharps_container_selected';
export const NO_TIP_PAYMENT_METHOD_SELECTED = 'no_tip_payment_method_specified';
export const NO_WAIVE_SIG_SELECTED = 'no_waive_sig_selected';
export const NO_WINDOW_SELECTED = 'no_window_selected';
export const OUT_OF_ZONE = 'out_of_zone';
export const PRESENCE_NOT_REQUIRED = 'presence_not_required';
export const SPLIT_AMOUNTS_NOT_MATCHED = 'split_amounts_not_matched';
export const UNEDITABLE_SPLIT_PAYMENTS = 'uneditable_split_payments';
export const PICKUP_NO_PAY_AT_PICKUP = 'pickup_no_pay_at_pickup';

export const CONFIRM = 'CONFIRM';
export const UNCONFIRMED = 'UNCONFIRMED';
export const UNNECESSARY = 'UNNECESSARY';
export const WAIVE = 'WAIVE';

// Delivery Methods
export const DELIVERY_METHOD_COURIER = 'courier';
export const DELIVERY_METHOD_PICKUP = 'pickup';
export const DELIVERY_METHOD_FEDEX_PRIORITY_OVERNIGHT = 'fedex_priority_overnight';
export const DELIVERY_METHOD_FEDEX_STANDARD_OVERNIGHT = 'fedex_standard_overnight';
export const DELIVERY_METHOD_USPS_PRIORITY = 'usps_priority';
export const DELIVERY_METHOD_USPS_FIRST = 'usps_first';
export const DELIVERY_METHOD_USPS_GROUND_ADVANTAGE = 'usps_ground_advantage';

// convert the confirmation into what we expect on the FE to properly populate
// the selected value
export const HOME_TO_SIGN_CONFIRMATION_MAP = {
  unconfirmed: UNCONFIRMED,
  unnecessary: UNNECESSARY,
  confirmed: CONFIRM,
  waived: WAIVE,
} as const;

export const EXCLUDED_DATE_REASON_MESSAGES = {
  mail: 'Your mail delivery cannot be scheduled for this day.',
  refrigerated: 'Mailed medications that require refrigeration cannot be delivered on Mondays.',
};

export const ADDRESS_MESSAGES = {
  no_safe_place: 'You have not added a safe place to leave packages.',
  a_signature_required: 'You must be present to sign for this package since it contains a controlled substance.',
  a_signature_and_18_plus_photo_id_required:
    'Someone 18+ must be present to show a photo ID and sign for this order, since it contains a controlled substance.',
  be_present_or_add_safe_place: 'Someone must be present to receive this package. Change your mind?',
  be_present_to_sign_or_add_safe_place: 'Someone must be present to sign for this package. Change your mind?',
  expensive: 'This order contains an expensive medication.',
  no_need_to_be_home: 'No need to be present—package will be dropped off per your delivery instructions.',
};

export const PAYMENT_METHOD_ERROR_MESSAGES = {
  minimum_amount: 'Amount must be at least $0.50.',
  not_matched_full_amount: 'The split amounts must equal to the total payment amount.',
  different_payment_methods: 'Payment methods must be different.',
  payment_method_does_not_exist: 'A valid payment method must be selected.',
  uneditable_split_payments: `You are using multiple payment methods on this delivery. Please call ${PHONE} to make any changes.`,
};

export const MIN_PAYMENT_AMOUNT = 0.5;

export const REFILL_CROSS_SELL_COLORS = ['#EEF4F9', '#FFEFD0', '#F6FAE7', '#FEF8F1', '#EFFAF2'];

export const MAX_NUM_ITEMS_TO_SHOW_ON_CROSS_SELL = 5;

export const PAYMENT_METHOD_VALIDATION_ERRORS = [NO_PAYMENT_METHOD_SELECTED, NO_TIP_PAYMENT_METHOD_SELECTED];

export const SELECT_PRICE_OPTION = {
  title: 'Select Price Option',
  subtitle:
    "We automatically selected the lowest price. Sometimes the cost of the drug is lower than your plan's standard copay amount.",
  lowest_price_tag: 'LOWEST PRICE',
  without_insurance_subtitle: ' • Includes the use of applicable savings cards, coupons, etc',
  with_insurance_subtitle: ' • Counts towards deductible',
};
