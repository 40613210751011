// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  Button,
  Card,
  Description,
  H3,
  MdPadding,
  MdSpacing,
  SmSpacing,
} from '@alto/design-system';
import React, { useContext, useEffect } from 'react';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type Prescription } from '~shared/types';
import { CouponAttestationActionSheet } from './CouponAttestationActionSheet';

type Props = {
  couponID: number;
  prescription: Prescription;
};

export const CouponCard = ({ couponID, prescription }: Props) => {
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent({
      event: EVENTS.COPAY_COUPON__CARD_VIEWED,
      params: {
        couponID,
      },
    });
  }, [couponID, trackEvent]);

  const handleAddCoupon = () => {
    trackEvent({
      event: EVENTS.COPAY_COUPON__CARD_APPLY_COUPON_PRESSED,
      params: {
        couponID,
      },
    });

    setActiveActionSheet(
      <CouponAttestationActionSheet
        couponID={couponID}
        prescription={prescription}
      />,
    );
  };

  return (
    <Card style={{ backgroundColor: COLORS.BACKGROUND_COLORS.SECONDARY_LIGHTEST }}>
      <MdPadding>
        <H3>{`Save on ${prescription.medication_name || 'your medication'}`}</H3>
        <SmSpacing />
        <Description>{`Eligible participants can receive savings on ${prescription.medication_name} by adding a copay card.`}</Description>
        <MdSpacing />
        <Button
          label="Apply instant discount"
          type="secondary"
          small
          onPress={handleAddCoupon}
        />
      </MdPadding>
    </Card>
  );
};
