// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Body, Column, H1, LgPadding, XsSpacing } from '@alto/design-system';
import React from 'react';

type Props = {
  readonly title: string;
  readonly link?: React.ReactNode;
  readonly subtitle?: React.ReactNode;
};

export const InfoRequestHeader = ({ title, link, subtitle }: Props) => {
  return (
    <Column centerHorizontally>
      <LgPadding bottomPadding={SPACING.STATIC.XL}>
        <H1 center>{title}</H1>
        {subtitle ? (
          <>
            <XsSpacing />
            <Body center>{subtitle}</Body>
          </>
        ) : null}
        {link ? (
          <>
            <XsSpacing />
            {link}
          </>
        ) : null}
      </LgPadding>
    </Column>
  );
};
