// @owners { team: patients-team }

import { SCROLLED_TO_BOTTOM } from '../components/CouponAttestationActionSheet';

/**
 * Applies styles and JavaScript behavior so patient can confirm coupon eligibility
 *
 * IMPORTANT! This method provides Javascript behavior to pass messages to the client so that
 * patients can accept coupon terms and conditions. Altering or removing the script tag
 * will break that behavior.
 * @param htmlContent
 * @returns html string
 */
export const wrapHtmlContent = (htmlContent: string) => {
  return `<!DOCTYPE html>
  <html>
    <head>
      <title>Terms and Conditions</title>
      <meta http-equiv="content-type" content="text/html; charset=utf-8">
      <meta name="viewport" content="width=device-width, user-scalable=no">
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap" rel="stylesheet">
      <style type="text/css">
        body {
          margin: 0;
          padding: 0;
          font-family: Inter, sans-serif;
          font-size: 14px;
          color: #404A52;
        }
      </style>
    </head>
    <body>
      ${htmlContent}
    </body>
    <script>
      let hasReachedBottom = false;
      document.addEventListener('scroll', () => {
          if (hasReachedBottom) {
            return;
          }
          const isNative = typeof window.ReactNativeWebView !== 'undefined';
          const scrollTop = isNative ? document.documentElement.scrollTop : window.scrollY;
          const scrollHeight = document.documentElement.scrollHeight;
          const clientHeight = document.documentElement.clientHeight;

          const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 20;
          if (isAtBottom) {
            hasReachedBottom = true;
            if (isNative) {
              window.ReactNativeWebView.postMessage('${SCROLLED_TO_BOTTOM}');
            } else {
              window.parent.postMessage('${SCROLLED_TO_BOTTOM}');
            }
          }
        });
    </script>
  </html>
`;
};
