import { platform } from './getPlatform';

type BackendEnviroment = 'local' | 'integration' | 'production' | 'sandbox' | 'staging';

const getLocalUrl = () => {
  if (platform === 'android') {
    return {
      API_URL: 'http://10.0.2.2:3000',
      API_WEBSOCKET_URL: 'wss://10.0.2.2:7000/socket/stream?method=POST',
    } as const;
  } else if (platform === 'ios') {
    return {
      API_URL: 'http://patients.alto.local.alto.com:3000',
      API_WEBSOCKET_URL: 'wss://api.alto.local.alto.com:7000/socket/stream?method=POST',
    } as const;
  }

  return {
    API_URL: 'https://patients.alto.local.alto.com:3443',
    API_WEBSOCKET_URL: 'wss://api.alto.local.alto.com:7000/socket/stream?method=POST',
  } as const;
};

const getProdUrl = () =>
  ({
    API_URL: 'https://patients.prod.alto.com',
    API_WEBSOCKET_URL: `wss://api.prod.alto.com/pubsub/socket/stream?method=POST`,
  }) as const;

const getSandboxUrl = ({ sandboxName }: { sandboxName?: string }) => {
  if (platform === 'web' && (!sandboxName || sandboxName === 'default')) {
    // web has to be dynamic since we don't know the name of the sandbox it will be
    // pointing to during the build process
    const hostName = window.location.hostname.replace(/[^-]+(-?.*.(dev|stg|prod)).alto.com/gm, '$1');

    if (hostName === 'stg.patientrxauth.com') {
      return {
        API_URL: 'https://stg.patientrxauth.com',
        API_WEBSOCKET_URL: 'wss://api2-stg-patientrxauth.alto.com/pubsub/socket/stream?method=POST',
      };
    }

    return {
      API_URL: `https://patients2${hostName}.alto.com`,
      API_WEBSOCKET_URL: `wss://api2${hostName}.alto.com/pubsub/socket/stream?method=POST`,
    } as const;
  }

  return {
    API_URL: `https://patients2-${sandboxName}.dev.alto.com`,
    API_WEBSOCKET_URL: `wss://api2-${sandboxName}.dev.alto.com/pubsub/socket/stream?method=POST`,
  } as const;
};

const getStagingUrl = () =>
  ({
    API_URL: 'https://patients2.stg.alto.com',
    API_WEBSOCKET_URL: `wss://api2.stg.alto.com/pubsub/socket/stream?method=POST`,
  }) as const;

export const getBaseURL = ({ backendEnv, sandboxName }: { backendEnv: BackendEnviroment; sandboxName?: string }) => {
  switch (backendEnv) {
    case 'local':
    case 'integration':
      return getLocalUrl();
    case 'production':
      return getProdUrl();
    case 'sandbox':
      return getSandboxUrl({ sandboxName });
    case 'staging':
      return getStagingUrl();
    default: {
      // return prod by default for safety
      return getProdUrl();
    }
  }
};
