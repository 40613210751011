import { type inferQueryKeyStore, mergeQueryKeys } from '@lukemorales/query-key-factory';
import { addToShipment } from './add-to-shipment';
import { cart } from './cart';
import { enterCheckout } from './enter-checkout';
import { facilityAlerts } from './facility-alert';
import { insurances } from './insurances';
import { orderBundling } from './order-bundling';
import { paymentMethods } from './paymentMethods';
import { prescriptions } from './prescriptions';
import { pricing } from './pricing';
import { products } from './products';
import { shipmentFees } from './shipment-fees';
import { shipmentPaymentMethods } from './shipmentPaymentMethods';
import { wundercomMessages } from './wundercom-messages';
import { announcements } from '~shared/queries/query-keys/announcements';
import { hubReferralInfoRequestConfigs } from '~shared/queries/query-keys/hub-referral-info-request-configs';
import { onboardingConfigs } from '~shared/queries/query-keys/onboarding-configs';

export const queries = mergeQueryKeys(
  addToShipment,
  announcements,
  cart,
  enterCheckout,
  facilityAlerts,
  hubReferralInfoRequestConfigs,
  insurances,
  onboardingConfigs,
  orderBundling,
  paymentMethods,
  prescriptions,
  pricing,
  products,
  shipmentFees,
  shipmentPaymentMethods,
  wundercomMessages,
);
export type QueryKeys = inferQueryKeyStore<typeof queries>;
