// @owners { team: patients-team }
import { getDeliveryItemsForShipment } from '~shared/features/delivery-info/selectors/getDeliveryItems';
import getShipments from '~shared/features/shipments/selectors/getShipments';
import { useSelectorShared } from '~shared/store';
import { useShipmentID } from '../../../index';

export const useShipment = () => {
  const { shipmentID } = useShipmentID();
  const shipments = useSelectorShared(getShipments);
  const shipment = shipments.find(({ id }) => id === shipmentID);
  const shipmentItems = useSelectorShared((state) => getDeliveryItemsForShipment(state, { shipmentID }));

  return {
    shipment,
    shipmentID,
    shipmentItems,
  };
};
