// @owners { team: patients-team }
import { PRESCRIPTION_PRICING_LABELS } from '~shared/features/pricing/constants';
import { formatDollars } from '~shared/helpers/currency';

export const getPriceCopy = ({
  estimatedPrice,
  isPrescriptionReady,
  price,
  shouldShowPrice,
}: {
  estimatedPrice: number | null | undefined;
  isPrescriptionReady: boolean;
  price: number | null | undefined;
  shouldShowPrice: boolean;
}) => {
  if (isPrescriptionReady) {
    return shouldShowPrice && typeof price === 'number'
      ? formatDollars(price)
      : PRESCRIPTION_PRICING_LABELS.VIEW_PRICE_IN_CART;
  } else if (typeof estimatedPrice === 'number') {
    return `Estimated price ${formatDollars(estimatedPrice)}`;
  }

  return undefined;
};
