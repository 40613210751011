// @owners { team: patients-team }
import { Experimentation } from '@alto/experimentation';
import React, { useEffect } from 'react';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { clearInsuranceError, declineInsurance } from '~shared/actions/insurances';
// eslint-disable-next-line import/no-deprecated
import { updateOnboardingUser } from '~shared/actions/onboarding';
import {
  INSURANCE_INPUT,
  INSURANCE_INPUT_MANUAL,
  INSURANCE_INPUT_PHOTO,
  ONBOARD_STATE_INSURANCE_INPUT,
} from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { routeFromPath } from '~shared/features/onboarding/helpers/routeToPath';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { previousWebURL } from '~shared/lib/analytics/src/helper';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { OnboardingInsuranceInput } from './OnboardingInsuranceInput';
import { nextUrl } from '~web/features/onboarding/helpers/urlHandler';

export const OnboardingInsuranceInputContainer = () => {
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const showProgyny = useSelectorShared(getShowProgynySelfServiceForUser);
  const previousUrl = useSelectorShared(previousWebURL);
  const origin = previousUrl ? routeFromPath(previousUrl) : '';
  const { value: shouldRemoveMedVerification } = Experimentation.useFeatureFlag('remove_onboarding_med_verification');
  const photoURL = useSelectorShared((state) =>
    nextUrl({
      state,
      currentRoute: INSURANCE_INPUT,
      useOnboardingRoutingV2: shouldRemoveMedVerification,
      desiredRoute: INSURANCE_INPUT_PHOTO,
    }),
  );
  const manualURL = useSelectorShared((state) =>
    nextUrl({
      state,
      currentRoute: INSURANCE_INPUT,
      useOnboardingRoutingV2: shouldRemoveMedVerification,
      desiredRoute: INSURANCE_INPUT_MANUAL,
    }),
  );
  const skipURL = useSelectorShared((state) =>
    nextUrl({ state, currentRoute: INSURANCE_INPUT, useOnboardingRoutingV2: shouldRemoveMedVerification }),
  );

  useEffect(() => {
    dispatch(
      // eslint-disable-next-line import/no-deprecated
      updateOnboardingUser({
        onboard_state: ONBOARD_STATE_INSURANCE_INPUT,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearInsuranceError());
    };
  }, [dispatch]);

  const onUploadPhoto = () => {
    dispatch(push(photoURL));
  };

  const onEnterManually = () => {
    dispatch(push(manualURL));
  };

  const onDeclineInsurance = async () => {
    trackEvent({
      event: EVENTS.ONBOARDING__INSURANCE_NONE_CLICKED,
      params: {
        is_progyny_rx_view: showProgyny,
      },
    });

    // eslint-disable-next-line import/no-deprecated
    const success = await dispatch(declineInsurance());
    if (success) {
      dispatch(push(skipURL));
    }
  };

  const onSkip = () => {
    trackEvent({
      event: EVENTS.ONBOARDING__INSURANCE_REMINDER_CLICKED,
      params: {
        is_progyny_rx_view: showProgyny,
      },
    });

    dispatch(push(skipURL));
  };

  return (
    <OnboardingInsuranceInput
      origin={origin}
      showProgyny={showProgyny}
      onUploadPhoto={onUploadPhoto}
      onEnterManually={onEnterManually}
      onDeclineInsurance={onDeclineInsurance}
      onSkip={onSkip}
    />
  );
};
