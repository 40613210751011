// @owners { team: patients-team }
import { useMemo } from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { getOrder } from '~shared/features/checkout/selectors/getOrder';
import { getNextAvailableDateID } from '~shared/features/next-available-date/helpers/getNextAvailableDateID';
import { getNextAvailableDateAddressID } from '~shared/features/next-available-date/selectors/getNextAvailableDateAddressId';
import { getNextAvailableDateFacilityID } from '~shared/features/next-available-date/selectors/getNextAvailableDateFacilityId';
import { getNextAvailableDateFreshnessByIDMap } from '~shared/features/next-available-date/selectors/getNextAvailableDateFreshnessByIDMap';
import {
  getLastRequestAttemptedAt,
  getNextAvailableDates,
} from '~shared/features/next-available-date/selectors/getNextAvailableDates';
import { type NextAvailableDateMapping } from '~shared/reducers/nextAvailableDates';
import { useSelectorShared } from '~shared/store';
import { type Prescription } from '~shared/types';

type GetNextAvailableDateIDProps = {
  prescriptionIDs: number[];
  addressID: number | null | undefined;
  facilityID: number | null | undefined;
};

const getNextAvailableDateId = ({
  prescriptionIDs,
  addressID,
  facilityID,
}: GetNextAvailableDateIDProps): string | undefined => {
  if (prescriptionIDs.length === 0 || !(addressID || facilityID)) {
    return undefined;
  }
  return getNextAvailableDateID({ addressID, facilityID, prescriptionIDs });
};

type GetEarliestAvailableDatesProps = {
  nextAvailableDates: NextAvailableDateMapping;
  prescriptionIDs: number[];
  addressID: number | null | undefined;
  facilityID: number | null | undefined;
};

const getEarliestAvailableDates = ({
  nextAvailableDates,
  prescriptionIDs,
  addressID,
  facilityID,
}: GetEarliestAvailableDatesProps) => {
  if (prescriptionIDs.length === 0 || !(addressID || facilityID)) {
    return {};
  }

  return prescriptionIDs.reduce<Record<number, string>>((dates, prescriptionID) => {
    const nextAvailableDateID = getNextAvailableDateID({
      addressID,
      facilityID,
      prescriptionIDs: [prescriptionID],
    });

    const nextAvailableDateObject = nextAvailableDates[nextAvailableDateID];

    if (nextAvailableDateObject) {
      dates[prescriptionID] = nextAvailableDateObject.earliest.date;
    }

    return dates;
  }, {});
};

type GetPrescriptionsMissingAvailableDateProps = {
  availableNextAvailableDateFreshnessByIDs: Record<string, boolean>;
  prescriptions: Prescription[];
  prescriptionIDs: number[];
  addressID: number | null | undefined;
  facilityID: number | null | undefined;
};

/**
 * Returns a list of prescriptions that are missing a next available date based
 * on the current address (or facility) state.
 *
 * These are 'bundled' prescriptions- they are matched with the next available
 * dates as if the prescriptions are all in a single shipment,
 *
 */
const getPrescriptionsMissingAvailableDate = ({
  availableNextAvailableDateFreshnessByIDs,
  prescriptionIDs,
  prescriptions,
  addressID,
  facilityID,
}: GetPrescriptionsMissingAvailableDateProps) => {
  if (!(addressID || facilityID) || prescriptions.length === 0) {
    return [];
  }

  const nextAvailableDateID = getNextAvailableDateID({ addressID, facilityID, prescriptionIDs });

  if (!availableNextAvailableDateFreshnessByIDs[nextAvailableDateID]) {
    return prescriptions;
  }

  return [];
};

export const useCartNextAvailableDate = () => {
  const order = useSelectorShared(getOrder);
  const { prescriptions, prescriptionIDs } = usePrescriptionsInCart();
  const nextAvailableDateAddressID = useSelectorShared(getNextAvailableDateAddressID);
  const nextAvailableDateFacilityID = useSelectorShared(getNextAvailableDateFacilityID);
  const nextAvailableDates = useSelectorShared(getNextAvailableDates);
  const nextAvailableDateFreshnessByIDMap = useSelectorShared(getNextAvailableDateFreshnessByIDMap);
  const lastRequestNextAvailableDateAttemptedAt = useSelectorShared(getLastRequestAttemptedAt);

  const nextAvailableDateID = getNextAvailableDateId({
    prescriptionIDs,
    addressID: nextAvailableDateAddressID,
    facilityID: nextAvailableDateFacilityID,
  });
  const nextAvailableDateObject = nextAvailableDateID ? nextAvailableDates[nextAvailableDateID] : undefined;
  // override the earliest available date if the order is pickup to today
  const earliestAvailableDate =
    order.delivery_method === 'pickup' ? order.date : nextAvailableDateObject?.earliest?.date;
  const earliestAvailableDates = useMemo(
    () =>
      getEarliestAvailableDates({
        nextAvailableDates,
        prescriptionIDs,
        addressID: nextAvailableDateAddressID,
        facilityID: nextAvailableDateFacilityID,
      }),
    [nextAvailableDates, prescriptionIDs, nextAvailableDateAddressID, nextAvailableDateFacilityID],
  );
  const prescriptionsMissingAvailableDate = useMemo(
    () =>
      getPrescriptionsMissingAvailableDate({
        availableNextAvailableDateFreshnessByIDs: nextAvailableDateFreshnessByIDMap,
        prescriptions,
        prescriptionIDs,
        addressID: nextAvailableDateAddressID,
        facilityID: nextAvailableDateFacilityID,
      }),
    [
      nextAvailableDateFreshnessByIDMap,
      prescriptions,
      prescriptionIDs,
      nextAvailableDateAddressID,
      nextAvailableDateFacilityID,
    ],
  );

  return {
    nextAvailableDateID,
    nextAvailableDateObject,
    earliestAvailableDate: earliestAvailableDate || '',
    earliestAvailableDateReason: nextAvailableDateObject?.earliest?.reason || '',
    earliestAvailableDateInternalReason: nextAvailableDateObject?.earliest?.internal_reason || '',
    lastAvailableDate: nextAvailableDateObject?.lastAvailableDate?.date || '',
    lastAvailableDateReason: nextAvailableDateObject?.lastAvailableDate?.reason || '',
    lastAvailableDateInternalReason: nextAvailableDateObject?.lastAvailableDate?.internal_reason || '',
    earliestAvailableDates,
    deliveryMethod: nextAvailableDateObject?.deliveryMethod || '',
    excludedDates: nextAvailableDateObject?.excluded,
    prescriptionsMissingAvailableDate,
    lastRequestNextAvailableDateAttemptedAt,
  };
};
