// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  Button,
  Card,
  Link,
  LoadingEmptyState,
  SecondaryPage,
  Toast,
  ToastContext,
  XlPadding,
} from '@alto/design-system';
import { BenefitInputActionSheet } from '@alto/features';
import { useQueryClient } from '@tanstack/react-query';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Formik } from 'formik';
import React, { useCallback, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import { goBack, push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { createInsurance, updateInsurance } from '~shared/actions/insurances';
import { setEditingInsuranceID } from '~shared/actions/onboarding';
import { VERIFY_FAILURE_ERROR_MESSAGE } from '~shared/constants';
import { getInferredInsuranceFormValues } from '~shared/features/insurances/helpers/getInferredInsuranceFormValues';
import {
  type Values,
  getInitialInsuranceFormValues,
} from '~shared/features/insurances/helpers/getInitialInsuranceFormValues';
import { getInsuranceByID } from '~shared/features/insurances/selectors/getInsuranceById';
import { getEditingOnboardingInsuranceID } from '~shared/features/onboarding/selectors/getOnboarding';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { getSelectedUser } from '~shared/features/users/selectors/getSelectedUser';
import { getErrorMessage } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { queries } from '~shared/queries/query-keys';
import { getNotRestrictedPartnerError } from '~shared/selectors/ui/errors/getInsuranceError';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import type { Insurance } from '~shared/types';
import { InfoRequestHeader } from './InfoRequestHeader';
import { NavBarLogo } from './NavBarLogo';
import { useQueryInsurancePageContent } from '~web/features/info-request/hooks';
import { useStatusBarColor } from '~web/features/info-request/hooks/useStatusBarColor';
import { removeEmptyValues } from '~web/features/insurances/components/InsuranceFormElements';
import { InsuranceFormFields, validate } from '~web/features/onboarding/components/redesign/InsuranceFormFields';

type Props = {
  params: {
    externalUUID: string;
    hubReferralID: string;
  };
};

const InsuranceInputManualPageComponent = (props: Props) => {
  const { hubReferralID: hubReferralIDParam } = props.params || {};
  const hubReferralID = Number(hubReferralIDParam || 0);
  const dispatch = useDispatchShared();
  const loading = useSelectorShared(getLoading);
  const insuranceID = useSelectorShared(getEditingOnboardingInsuranceID);
  const { isFetching, headerLogoUrl, redirectURL } = useQueryInsurancePageContent({
    hubReferralID,
  });
  const queryClient = useQueryClient();
  const { trackEvent, trackPageView } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const { setActiveActionSheet } = useContext(ActionSheetContext);

  const selectedUser = useSelectorShared(getSelectedUser);
  const currentUser = useSelectorShared(getCurrentUser);
  const user = selectedUser || currentUser;
  const savedOnboardingInsurance = useSelectorShared((state) => getInsuranceByID(state, insuranceID));
  const currentPath = window.location.pathname;
  const nextURL = currentPath.replace('/insurance-input-manual/', '/insurance/');

  const error = useSelectorShared(getNotRestrictedPartnerError);
  const saving = loading.createInsuranceLoading || loading.updateInsuranceLoading;

  const openInsuranceInfoActionSheet = () => {
    setActiveActionSheet(<BenefitInputActionSheet />);
  };

  const handleGoBack = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  const afterSave = (newInsurance: Insurance) => {
    if (!newInsurance) {
      addToast(<Toast variant="error">{getErrorMessage(error) || VERIFY_FAILURE_ERROR_MESSAGE}</Toast>);
      trackEvent({
        event: EVENTS.INSURANCE_ADD_FAILED,
        params: {
          type: 'manual',
          source: 'hub-referral-info-request',
          next_url: nextURL,
        },
      });
      return;
    }
    queryClient.invalidateQueries({ queryKey: queries.insurances.fetchAll._def });
    dispatch(setEditingInsuranceID(null));

    if (!savedOnboardingInsurance) {
      trackEvent({
        event: EVENTS.INSURANCE_ADDED,
        params: {
          type: 'manual',
          source: 'hub-referral-info-request',
          entity_name: newInsurance.insurance_plan_name,
          next_url: nextURL,
        },
      });
    }

    dispatch(push(nextURL));
  };

  const onSave = async (values: Values) => {
    trackEvent({
      event: EVENTS.INFO_REQUEST__SAVE_INSURANCE_CLICKED,
      params: {
        hub_referral_id: hubReferralID,
        insurance_input_type: 'manual',
        patient_id: user?.id,
        next_url: nextURL,
      },
    });
    if (user) {
      const newInsuranceValues = getInferredInsuranceFormValues(values, user);
      if (savedOnboardingInsurance) {
        return dispatch(
          // eslint-disable-next-line import/no-deprecated
          updateInsurance({
            id: savedOnboardingInsurance.id,
            ...removeEmptyValues(newInsuranceValues),
          }),
        ).then(afterSave);
      } else {
        return dispatch(
          // eslint-disable-next-line import/no-deprecated
          createInsurance({
            user_id: user.id,
            hub_referral_id: hubReferralID,
            ...removeEmptyValues(newInsuranceValues),
          }),
        ).then(afterSave);
      }
    }
  };

  const onCancel = useCallback(() => {
    dispatch(setEditingInsuranceID(null));
    handleGoBack();
  }, [dispatch, handleGoBack]);

  useStatusBarColor(COLORS.BACKGROUND_COLORS.GREY_LIGHTEST);

  useEffect(() => {
    trackPageView({
      event: EVENTS.INFO_REQUEST__INSURANCE_INPUT_MANUAL_PAGE_VIEWED,
      params: { hubReferralID },
    });
  }, [trackPageView, hubReferralID]);

  useEffect(() => {
    if (redirectURL) {
      dispatch(push(redirectURL));
    }
  }, [redirectURL, dispatch]);

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  return (
    <Formik
      initialValues={getInitialInsuranceFormValues(savedOnboardingInsurance)}
      onSubmit={onSave}
      validate={validate}
      enableReinitialize
    >
      {(formikProps) => (
        <SecondaryPage
          headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
          withoutWebFramingElementHeights
          dismissIcon="chevronleft"
          onDismiss={handleGoBack}
          NavBarCenterContent={headerLogoUrl ? <NavBarLogo illustrationSrc={headerLogoUrl} /> : undefined}
          HeaderContent={
            <InfoRequestHeader
              title="Add insurance details"
              link={
                <Link
                  onPress={openInsuranceInfoActionSheet}
                  center
                >
                  Where can I find this?
                </Link>
              }
            />
          }
          buttons={[
            <Button
              label="Cancel"
              onPress={onCancel}
              type="tertiary"
              key="cancel button"
              loading={saving}
            />,
            <Button
              label="Save insurance"
              onPress={formikProps.handleSubmit}
              loading={saving}
              key="submit button"
            />,
          ]}
          footerPlacementContext="webScreenWithoutPolicyFooter"
        >
          <Card>
            <InsuranceFormFields
              values={formikProps.values}
              submitCount={formikProps.submitCount}
              errors={formikProps.errors}
              handleChange={formikProps.handleChange}
            />
          </Card>
        </SecondaryPage>
      )}
    </Formik>
  );
};

export const InsuranceInputManualPage = withRouter(InsuranceInputManualPageComponent);
