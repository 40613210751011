// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { SecondaryPage, Toast, ToastContext } from '@alto/design-system';
import { PageErrorFallback } from '@alto/features';
import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { fetchAddresses } from '~shared/actions/addresses';
import { initializeExistingOrder, setOrderAtIndex, updateOrder } from '~shared/actions/cart';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from '~shared/actions/deliveries';
import { openModal } from '~shared/actions/modal';
// eslint-disable-next-line import/no-deprecated
import { fetchPrescriptions } from '~shared/actions/prescriptions';
// eslint-disable-next-line import/no-deprecated
import { fetchShipmentPricing } from '~shared/actions/pricing';
// eslint-disable-next-line import/no-deprecated
import { fetchShipmentPaymentMethods } from '~shared/actions/shipmentPaymentMethods';
// eslint-disable-next-line import/no-deprecated
import { fetchShipments } from '~shared/actions/shipments';
import { closeCart } from '~shared/actions/ui/cart';
import { getExistingOrder } from '~shared/features/checkout/selectors/getOrder';
// eslint-disable-next-line import/no-deprecated
import { fetchShipmentTipsForUser } from '~shared/features/courier-tips/actions';
import { getShipmentTipForShipmentID } from '~shared/features/courier-tips/selectors';
import { getShipmentPricing } from '~shared/features/pricing/selectors/getPricing';
import { getShipmentWithItemsByID } from '~shared/features/shipments/selectors/getShipmentsWithItems';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import { useGetPaymentMethods } from '~shared/queries/query-keys/paymentMethods';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { OrderDetails } from './OrderDetails';

type Props = {
  params: {
    orderID: number;
  };
};

const OrderDetailsContainer = ({ params }: Props) => {
  const { orderID: shipmentID } = params;
  const dispatch = useDispatchShared();
  const { addToast } = useContext(ToastContext);
  const user = useSelectorShared(getCurrentUser);
  const userID = user?.id;
  const shipment = useSelectorShared((state) =>
    getShipmentWithItemsByID(state, {
      id: shipmentID,
    }),
  );
  const existingOrder = useSelectorShared((state) => getExistingOrder(state, { shipmentID }));
  const shipmentPricing = useSelectorShared(getShipmentPricing);
  const shipmentTip = useSelectorShared((state) =>
    getShipmentTipForShipmentID(state, {
      shipmentID,
    }),
  );
  const fetchShipmentTipsError = useSelectorShared((state) => state.ui.errors.fetchShipmentTipsError);

  useGetPaymentMethods();

  useEffect(() => {
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchAddresses());
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchDeliveries());
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchPrescriptions());
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchShipments());
    // eslint-disable-next-line import/no-deprecated
    dispatch(fetchShipmentPaymentMethods());
  }, [dispatch, shipmentID, userID]);

  useEffect(() => {
    if (userID) {
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchShipmentTipsForUser(userID));
    }
  }, [dispatch, userID]);

  useEffect(() => {
    if (shipmentID) {
      // eslint-disable-next-line import/no-deprecated
      dispatch(fetchShipmentPricing(shipmentID));
    }
  }, [dispatch, shipmentID]);

  useEffect(() => {
    if (fetchShipmentTipsError) {
      addToast(<Toast variant="error">Unable to fetch shipment tips. {fetchShipmentTipsError.details.message}</Toast>);
    }
  }, [addToast, fetchShipmentTipsError]);

  const goBack = () => {
    dispatch(push('/orders'));
  };

  if (!shipment) {
    return (
      <SecondaryPage
        headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
        dismissIcon="chevronleft"
        onDismiss={goBack}
      >
        <PageErrorFallback />
      </SecondaryPage>
    );
  }

  const editShipment = () => {
    dispatch(setOrderAtIndex({ order: existingOrder, orderIndex: 0 }));
    dispatch(
      initializeExistingOrder({
        order: existingOrder,
        shipmentID,
      }),
    );
    dispatch(
      updateOrder({
        origin: 'Existing Order',
      }),
    );
    dispatch(closeCart());
    dispatch(push('/checkout'));
  };

  const openCancelDeliveryPage = () => {
    dispatch(push(`/orders/cancel?shipmentID=${shipment.id}`));
  };

  const openEditOrAddTipModal = () => {
    dispatch(openModal('EDIT_OR_ADD_TIP_MODAL'));
  };

  return (
    <OrderDetails
      shipment={shipment}
      shipmentTip={shipmentTip}
      shipmentPricing={shipmentPricing}
      editShipment={editShipment}
      openCancelDeliveryPage={openCancelDeliveryPage}
      openEditOrAddTipModal={openEditOrAddTipModal}
    />
  );
};

export default withRouter(OrderDetailsContainer);
