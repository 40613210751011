// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import { Body, Illustration, LgSpacing, MdSpacing, XlSpacing } from '@alto/design-system';
import { useAddToCart, useRemoveFromCart } from '@alto/features';
// eslint-disable-next-line @alto/no-pocky-import
import { Button, Column, H1, Link, Modal, ModalContent, ModalFooter, ParentWidthColumn, Row } from '@alto/pocky';
import { type NeedlePrescription } from '@alto/scriptdash/alto/patient_app/checkout_flow/v1/checkout_flow_endpoint';
import { PrescriptionStatusMap } from '@alto/scriptdash/alto/patient_app/types/v1/prescription_status';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Link as RRLink } from 'react-router';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import {
  sendNewYorkFertilityNeedlePromptCompleted,
  sendNewYorkFertilityNeedlePromptViewed,
} from '~shared/actions/cart';
import { closeModal } from '~shared/actions/modal';
import rx from '~shared/assets/images/needle_meds.png';
import { useCartData } from '~shared/features/cart/hooks/useCartData';
import { prescriptionToItemKey } from '~shared/features/checkout/helpers';
import { getEnterCartData } from '~shared/features/checkout/selectors/getCheckoutFlow';
import { formatListMaxTwo } from '~shared/helpers/list';
import getIsModalShowing from '~shared/selectors/modal/getIsModalShowing';
import getModalType from '~shared/selectors/modal/getModalType';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { type ReduxStateShared } from '~shared/types';
import NeedleRowList, { type NeedleRowPrescription } from './NeedleRowList';

const StyledColumn = styled(Column)`
  margin-top: -${SPACING.STATIC.MD.px};
`;

const StyledText = styledNative(Body)`
  font-weight: 400;
`;

const StyledParentWidthColumn = styled(ParentWidthColumn)`
  padding-right: ${SPACING.STATIC.MD.px};
`;

const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: none;
    margin-bottom: -${SPACING.STATIC.MD.px};
  }
`;

const TitleText = styled(H1)`
  color: ${COLORS.TEXT_COLORS.PRIMARY};
  text-align: center;
  white-space: pre-wrap;
  margin-top: -${SPACING.STATIC.XL.px};
`;

const SubTitleText = styledNative(Body)`
  font-size: 20px;
`;

type Props = {
  readonly onClose: () => void;
};

const addButtonClickedOption = { all_items_button_clicked: false, individual_item_button_clicked: false };
const { READY } = PrescriptionStatusMap;

const parseNeedlePrescription = (prescription: NeedlePrescription) => ({
  id: prescription.id,
  status: READY,
  image_url: prescription.image_url ?? '',
  quantity: prescription.quantity,
  price: prescription.price,
  name: prescription.medication_name,
  family_member_name: '',
  auto_refill_eligible: false,
  manual_coupon_eligible: false,
});

export default function AddingNeedlesModal({ onClose }: Props) {
  const dispatch = useDispatchShared();
  const { handleAddToCart } = useAddToCart();
  const { handleRemoveFromCart } = useRemoveFromCart();

  const showAddingNeedlesModal = useSelectorShared(
    (state: ReduxStateShared) => getIsModalShowing(state) && getModalType(state) === 'ADDING_NEEDLES_MODAL',
  );

  const enterCartData = useSelectorShared(getEnterCartData);

  const { cartItems, cartSize } = useCartData();

  const needlePrescriptions = useMemo(() => enterCartData?.needle_prompt?.needle_prescriptions ?? [], [enterCartData]);
  const requireNeedlePrescription = enterCartData?.needle_prompt?.require_needle_prescriptions ?? [];

  const requireMedicationNames = () => {
    const medicationNames = requireNeedlePrescription.map(
      (medication: { medication_name: string }) => medication.medication_name,
    );

    return formatListMaxTwo(medicationNames);
  };

  const parsedNeedlePrescriptions = useMemo(
    () => needlePrescriptions.map<NeedleRowPrescription>(parseNeedlePrescription),
    [needlePrescriptions],
  );

  const allAdded = () => {
    const needleItemsAddedToCart =
      needlePrescriptions && cartSize
        ? cartItems.filter((item) =>
            needlePrescriptions.some((flow_data_item) => flow_data_item.id === item.resource_id),
          )
        : [];
    return needleItemsAddedToCart.length === needlePrescriptions.length;
  };

  const shouldShowAllOpButton = () => {
    return needlePrescriptions.length > 1;
  };

  const atLeastOneAdded = () => {
    const needleItemsAddedToCart =
      needlePrescriptions && cartSize
        ? cartItems.filter((item) =>
            needlePrescriptions.some((flow_data_item) => flow_data_item.id === item.resource_id),
          )
        : [];
    return needleItemsAddedToCart.length > 0;
  };

  const addAll = () => {
    addButtonClickedOption.all_items_button_clicked = true;
    needlePrescriptions.map(async (prescription: { id: number }) => {
      await handleAddToCart({ resource_id: prescription.id, resource_type: 'Prescription' });
    });
  };

  const removeAll = useCallback(() => {
    addButtonClickedOption.all_items_button_clicked = true;
    parsedNeedlePrescriptions.map((prescription) => {
      handleRemoveFromCart({ item: prescriptionToItemKey(prescription.id) });
    });
  }, [handleRemoveFromCart, parsedNeedlePrescriptions]);

  const closeModalContinue = () => {
    addButtonClickedOption.individual_item_button_clicked =
      atLeastOneAdded() && (!addButtonClickedOption.all_items_button_clicked || !allAdded());
    dispatch(
      sendNewYorkFertilityNeedlePromptCompleted(
        addButtonClickedOption.all_items_button_clicked,
        addButtonClickedOption.individual_item_button_clicked,
      ),
    );
    dispatch(closeModal('ADDING_NEEDLES_MODAL'));
  };

  useEffect(() => {
    dispatch(sendNewYorkFertilityNeedlePromptViewed());
  }, [dispatch, enterCartData]);

  if (!showAddingNeedlesModal) return null;
  return (
    <StyledModal
      showing={showAddingNeedlesModal}
      onClose={onClose}
    >
      <StyledColumn horizontallyAlignContent>
        <Illustration
          size="lg"
          source={rx}
          accessibilityLabel="Illustration of paper prescription"
        />
        <XlSpacing />
        <TitleText>Remember to add needles</TitleText>
      </StyledColumn>
      <MdSpacing />
      <ModalContent textAlignment="left">
        <StyledText color={COLORS.TEXT_COLORS.GREY}>
          {requireMedicationNames()} will not come with needles. Be sure to order everything you need for your
          medications.
        </StyledText>
        <MdSpacing />
        <LgSpacing />
        <StyledParentWidthColumn evenlySpaceContent>
          <Column>
            <SubTitleText color={COLORS.TEXT_COLORS.PRIMARY}>Available Needles</SubTitleText>
          </Column>
          {!allAdded() && shouldShowAllOpButton() && <Link onClick={addAll}>Add all</Link>}
          {allAdded() && shouldShowAllOpButton() && <Link onClick={removeAll}>Remove all</Link>}
        </StyledParentWidthColumn>
        <LgSpacing />
        <NeedleRowList prescriptions={parsedNeedlePrescriptions} />
      </ModalContent>
      <ModalFooter>
        <RRLink to="/checkout">
          <Row
            horizontallyAlignContent
            evenlySpaceContent
            spacing={SPACING.STATIC.MD.rem}
          >
            {atLeastOneAdded() && (
              <Button
                full
                kind="primary"
                onClick={closeModalContinue}
              >
                Continue
              </Button>
            )}
            {!atLeastOneAdded() && <Link onClick={closeModalContinue}>No thanks, I have what I need</Link>}
          </Row>
        </RRLink>
      </ModalFooter>
    </StyledModal>
  );
}
