import { TypescriptUtils } from '~shared/TypescriptUtils';
import { getAddress } from '~shared/features/addresses/selectors/getAddress';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { CONFIRM, WAIVE } from '~shared/features/checkout/constants';
import { getIsOrderPickupMethod, getOrder } from '~shared/features/checkout/selectors/getOrder';
import { getOrderIndex } from '~shared/features/checkout/selectors/getOrdersByIndex';
import { type ValidationError } from '~shared/features/checkout/types';
import { isCourier, isMail } from '~shared/helpers/order';
import { useSelectorShared } from '~shared/store';
import { useCheckoutAddressInfoKeys } from './useCheckoutAddressInfoKeys';

export const useCheckoutAddressErrors = () => {
  const order = useSelectorShared(getOrder);
  const orderIndex = useSelectorShared(getOrderIndex);

  const address = useSelectorShared((state) => getAddress(state, { addressID: order?.address_id }));
  const { deliveryMethods } = useCartDeliveryMethods();
  const isOrderPickupMethod = useSelectorShared(getIsOrderPickupMethod);
  const homeToSignConfirmation = order?.home_to_sign_confirmation;
  const { keys: addressInfoKeys } = useCheckoutAddressInfoKeys();
  const errors: ValidationError[] = [];

  // FIXME: apply separate home to sign confirmations for split shipments (mail/courier case)
  // do not block checkout for split shipment if the first shipment is a compound medication being delivered my mail
  const isCourierShipment = isCourier(deliveryMethods[orderIndex]?.mode);
  const isMailShipment = isMail(deliveryMethods[orderIndex]?.mode);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  TypescriptUtils.objectKeys(addressInfoKeys).forEach((addressInfoKey) => {
    switch (addressInfoKey) {
      case 'no_address_selected': {
        errors.push({
          key: 'no_address_selected',
          message: 'Please select an address.',
        });
        break;
      }

      case 'out_of_zone': {
        errors.push({
          key: 'out_of_zone',
          message: 'Sorry, this address is currently outside of our service area.',
        });
        break;
      }

      case 'expensive': {
        // signature is required when there is a control rx in cart; home to sign confirmation is not shown
        if (addressInfoKeys.has_controls) {
          break;
        }

        // do not require a safe place for pickup
        if (isOrderPickupMethod) {
          break;
        }

        const confirmationMissing = ![CONFIRM, WAIVE].includes(homeToSignConfirmation || '');
        if (confirmationMissing) {
          errors.push({
            key: 'no_waive_sig_selected',
            message: 'Please indicate whether you will be home to sign.',
          });
        }

        if (isCourierShipment && !address?.safe_place && homeToSignConfirmation === WAIVE) {
          errors.push({
            key: 'no_safe_place_with_waive_sig_selected',
            message: 'You cannot waive signature requirements without updating your delivery instructions.',
          });
        }

        break;
      }

      case 'no_safe_place': {
        if (addressInfoKeys.has_controls || addressInfoKeys.expensive || addressInfoKeys.mail_only_zone) {
          break;
        }

        // do not require a safe place for mailed shipments (single or split shipments...i.e. compound med and another med) or pickup
        if (isMailShipment || isOrderPickupMethod) {
          break;
        }

        const confirmationMissing = ![CONFIRM, WAIVE].includes(homeToSignConfirmation ?? '');
        if (confirmationMissing) {
          errors.push({
            key: 'no_waive_sig_selected',
            message: 'Please indicate whether someone will be present to receive delivery.',
          });
        }

        if (isCourierShipment && !address?.safe_place && homeToSignConfirmation === WAIVE) {
          errors.push({
            key: 'no_safe_place_with_waive_sig_selected',
            message: 'If you will not be present, you must indicate that packages may be left at this address.',
          });
        }

        break;
      }

      default:
    }
  });

  return { addressValidationErrors: errors };
};
