// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  AltoIcon,
  Description,
  InlineAlert,
  LgSpacing,
  ListItem,
  MdPadding,
  MdSpacing,
  ThirdPartyIcon,
} from '@alto/design-system';
import React, { useCallback, useContext, useState } from 'react';
import { Platform } from 'react-native';
import { type PaymentMethodType } from '~shared/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { AddPaymentCardActionSheet } from './AddPaymentCardActionSheet';
import { PlaidListNavigation } from './PlaidListNavigation';

type Props = {
  readonly handleNativePay?: (paymentMethodType: PaymentMethodType) => void;
  readonly paymentMethodType: PaymentMethodType;
};

export const AddPaymentMethodActionSheet = ({ handleNativePay, paymentMethodType }: Props) => {
  const { trackEvent } = useAnalytics();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const showApplePay = Platform.OS === 'ios';

  const handlePaymentCardPress = useCallback(() => {
    trackEvent({
      event: EVENTS.ADD_PAYMENT_METHOD_TYPE_SELECTED,
      params: { type: 'credit_card' },
    });
    setActiveActionSheet(<AddPaymentCardActionSheet paymentMethodType={paymentMethodType} />);
  }, [paymentMethodType, setActiveActionSheet, trackEvent]);

  const handleNativePayPress = useCallback(() => {
    if (handleNativePay) {
      trackEvent({
        event: EVENTS.ADD_PAYMENT_METHOD_TYPE_SELECTED,
        params: { type: 'apple_pay' },
      });
      handleNativePay(paymentMethodType);
    }
  }, [handleNativePay, paymentMethodType, trackEvent]);

  return (
    <ActionSheetV2
      title="Add new method"
      analyticsName="add payment method"
    >
      {errorMessage ? (
        <MdPadding
          topPadding={SPACING.STATIC.NONE}
          bottomPadding={SPACING.STATIC.NONE}
        >
          <InlineAlert type="error">
            <Description>{errorMessage}</Description>
          </InlineAlert>
          <MdSpacing />
        </MdPadding>
      ) : null}
      <ListItem
        LeftContent={<AltoIcon name="creditcard" />}
        title="Credit or Debit Card"
        RightContent={<AltoIcon name="chevronright-small" />}
        onPress={handlePaymentCardPress}
      />
      {showApplePay ? (
        <ListItem
          LeftContent={<ThirdPartyIcon name="applepay" />}
          RightContent={<AltoIcon name="chevronright-small" />}
          title="Apple Pay"
          onPress={handleNativePayPress}
        />
      ) : null}
      <PlaidListNavigation
        paymentMethodType={paymentMethodType}
        setErrorMessage={setErrorMessage}
      />
      <LgSpacing />
    </ActionSheetV2>
  );
};
