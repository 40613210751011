// @owners { team: patients-team }
import {
  ActionSheetContext,
  AltoIcon,
  Description,
  InlineAlert,
  LgPadding,
  ListDescription,
  ListItem,
  Tag,
} from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useContext, useEffect } from 'react';
import {
  type PlaidLinkError,
  type PlaidLinkOnEventMetadata,
  type PlaidLinkOnExitMetadata,
  type PlaidLinkOnSuccessMetadata,
  type PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link';
import { createBankAccountPaymentMethod } from '~shared/actions/paymentMethods';
// eslint-disable-next-line import/no-deprecated
import { createPlaidLinkToken } from '~shared/actions/plaid';
import { PLAID_ENV, PLAID_PUBLIC_KEY } from '~shared/config';
import { type PaymentMethodType } from '~shared/constants';
import { useAnalytics } from '~shared/hooks';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { getPlaidEvent } from '~shared/lib/analytics/src/getPlaidEvent';
import { queries } from '~shared/queries/query-keys';
import { Sentry } from '~shared/sentry';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useCreateLinkToken } from '../../plaid';

type Props = {
  readonly paymentMethodType: PaymentMethodType;
  readonly setErrorMessage: (errorMessage: string) => void;
};

export const PlaidListNavigation = ({ paymentMethodType, setErrorMessage }: Props) => {
  const queryClient = useQueryClient();
  const { value: isPlaidLinkTokenEnabled, isLoading } = Experimentation.useFeatureFlag('plaid_link_tokens');
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const { closeActionSheet } = useContext(ActionSheetContext);
  const { createLinkToken } = useCreateLinkToken();
  const { token } = useSelectorShared((state) => state.paymentMethods.plaidLinkToken);

  // get plaid link token if one isn't in redux store
  useEffect(() => {
    if (token || isLoading) return;

    if (isPlaidLinkTokenEnabled) {
      createLinkToken();
    } else {
      // eslint-disable-next-line import/no-deprecated
      dispatch(createPlaidLinkToken());
    }
  }, [createLinkToken, dispatch, isLoading, isPlaidLinkTokenEnabled, token]);

  const sendPlaidAnalytics = useCallback(
    (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => {
      // @ts-expect-error TS(2322): eventName should be PlaidLinkStableEvent which is included in PlaidEventName (Delete me to see the full error)
      const event = getPlaidEvent(eventName, metadata);

      if (event) {
        dispatch(sendAnalyticsEvent(event));
      }
    },
    [dispatch],
  );

  const onSuccess = useCallback(
    async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      const success = await dispatch(
        createBankAccountPaymentMethod({
          publicToken,
          accountId: metadata.accounts[0].id,
          institutionName: metadata.institution?.name || '',
          paymentMethodType,
        }),
      );

      if (success) {
        closeActionSheet();
        queryClient.invalidateQueries({ queryKey: queries.paymentMethods.fetchAll._def });
        trackEvent({
          event: EVENTS.PAYMENT_METHOD_CREATED,
          params: {
            newStripeFormEnabled: false,
            type: 'PlaidData',
          },
        });
        setErrorMessage('');
      } else {
        setErrorMessage(
          'There was an error creating your payment method. Please try again or message support if the problem persists.',
        );
        trackEvent({
          event: EVENTS.CREATE_PAYMENT_METHOD_FAILED,
          params: {
            newStripeFormEnabled: false,
          },
        });
      }
    },
    [closeActionSheet, dispatch, paymentMethodType, queryClient, setErrorMessage, trackEvent],
  );

  const handlePlaidExit = (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
    if (!error) return;

    if (error.error_code === 'INVALID_LINK_TOKEN') {
      createLinkToken();
      return;
    }

    Sentry.captureMessage('Error linking account via Plaid', {
      contexts: { 'Plaid Error': { error, metadata } },
      level: 'warning',
    });
    setErrorMessage(`${error.display_message}. Please try again or message support if the problem persists.`);
  };

  // Use PlaidLinkOptionsWithLinkToken type when feature flag enabled, otherwise PlaidLinkOptionsWithPublicKey type
  const config = isPlaidLinkTokenEnabled
    ? {
        onEvent: sendPlaidAnalytics,
        onSuccess,
        onExit: handlePlaidExit,
        token,
      }
    : {
        clientName: 'Alto',
        env: PLAID_ENV,
        onEvent: sendPlaidAnalytics,
        onSuccess,
        product: ['auth'],
        publicKey: PLAID_PUBLIC_KEY,
      };

  const { ready, open, error } = usePlaidLink(config);

  const handleItemPress = useCallback(() => {
    trackEvent({ event: EVENTS.ADD_PAYMENT_METHOD_TYPE_SELECTED, params: { type: 'bank_account' } });
    open();
  }, [open, trackEvent]);

  if (!ready) return null;

  if (error) {
    return (
      <LgPadding>
        <InlineAlert type="error">
          <Description>
            Whoops! Something went wrong while loading Plaid. Please try again later, or message us if the problem
            doesn't resolve.
          </Description>
        </InlineAlert>
      </LgPadding>
    );
  }

  return (
    <ListItem
      LeftContent={<AltoIcon name="bank" />}
      RightContent={<AltoIcon name="chevronright-small" />}
      title="Bank Account"
      tags={[
        <Tag
          label="Save 1%"
          key="save"
        />,
      ]}
      descriptions={<ListDescription>Save 1% on all orders without insurance</ListDescription>}
      onPress={handleItemPress}
    />
  );
};
