// @owners { team: patients-team }
// eslint-disable-next-line import/no-deprecated
import { saveOrder as saveOrderAction } from '~shared/actions/cart';
import { useDispatchShared } from '~shared/store';
import { type APIError } from '~shared/types/APIError';
import { useHandleCreateOrderError } from './useHandleCreateOrderError';
import { useHandleCreateOrderSuccess } from './useHandleCreateOrderSuccess';

type SaveOrderSuccessResponse = { shipment_id: number | null; split_shipment_ids: number[] | null };
type SaveOrderErrorResponse = { error: APIError };
const isSaveOrderError = (
  response: SaveOrderSuccessResponse | SaveOrderErrorResponse,
): response is SaveOrderErrorResponse => 'error' in response;

export const useSaveOrder = () => {
  const dispatch = useDispatchShared();
  const { handleCreateOrderSuccess } = useHandleCreateOrderSuccess();
  const { handleCreateOrderError } = useHandleCreateOrderError();

  const saveOrderApiCall = async () => {
    // eslint-disable-next-line import/no-deprecated
    const response = await dispatch(saveOrderAction());

    if (isSaveOrderError(response)) {
      handleCreateOrderError(response.error);
      return response;
    }

    const shipmentIds = response.shipment_id !== null ? [response.shipment_id] : response.split_shipment_ids;

    handleCreateOrderSuccess({ shipmentIds });

    return response as SaveOrderSuccessResponse;
  };

  return { saveOrderApiCall };
};
