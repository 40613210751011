// @owners { team: patients-team }
import {
  type BACKGROUND_COLORS_VALUES,
  type BaseIconName,
  COLORS,
  type ColorVariant,
} from '@alto/design-library-tokens';

export type Instructions = {
  iconName: BaseIconName;
  text: string;
  type?: ColorVariant;
  backgroundColor?: BACKGROUND_COLORS_VALUES;
};

export const emptyInstruction: Instructions = {
  iconName: 'box',
  text: '',
};

export const pickUpInstruction: Instructions = {
  iconName: 'box',
  text: "You're picking up this package",
  type: 'success',
};

export const signatureRequiredInstruction: Instructions = {
  iconName: 'signpaper',
  text: 'Someone needs to be present to sign for this delivery',
  type: 'grey',
  backgroundColor: COLORS.BACKGROUND_COLORS.WARNING_LIGHTEST,
};

export const photoIDRequiredInstruction: Instructions = {
  iconName: 'idcard',
  text: '18+ photo ID & signature required',
  type: 'grey',
  backgroundColor: COLORS.BACKGROUND_COLORS.WARNING_LIGHTEST,
};

export const presenceRequiredInstruction: Instructions = {
  iconName: 'handbox',
  text: 'Someone needs to be present to receive this delivery',
  type: 'grey',
  backgroundColor: COLORS.BACKGROUND_COLORS.WARNING_LIGHTEST,
};

export const leavePackageInstruction: Instructions = {
  iconName: 'homelock',
  text: 'No need to be present for delivery',
  type: 'grey',
};
