import { type BACKGROUND_COLORS_VALUES, COLORS, SIZES, SPACING } from '@alto/design-library-tokens';
import React, { type ComponentType, type ReactElement, type ReactNode } from 'react';
import { FlatList, type FlatListProps, type ListRenderItem, View, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import { useScreenSize } from '../../../utils';
import { PressableAltoIcon } from '../../buttons';
import { MdPadding, Row } from '../../containers';
import { FullViewEmptyState, type FullViewEmptyStateProps } from '../../empty-states';
import { RectLoader } from '../../loaders';
import { MdSpacing, Separator } from '../../separators';
import { H1 } from '../../typography';
import { ContentOffsetNavBarSpacing, PageRoot } from './BasePage';
import { NavBar, type NavBarProps } from './NavBar';
import { PageHeaderSection } from './PageHeaderSection';

const MaxWidthContent = styled(View)<{ isMDScreenOrBigger: boolean }>`
  align-self: center;
  flex-shrink: 1;
  width: 100%;
  ${({ isMDScreenOrBigger }) => isMDScreenOrBigger && `max-width: ${SIZES.PAGE.CONTENT_WIDTH.LG};`}
`;

const Root = ({
  children,
  backgroundColor = COLORS.BACKGROUND_COLORS.WHITE,
}: {
  readonly children: ReactNode;
  readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
}) => {
  const { isMDScreenOrBigger } = useScreenSize();

  return (
    <PageRoot
      backgroundColor={backgroundColor}
      isMDScreenOrBigger={isMDScreenOrBigger}
    >
      {children}
    </PageRoot>
  );
};

type HeaderRootProps = Omit<NavBarProps, 'position' | 'LeftPressableAltoIcon'> &
  (
    | {
        /**
         * Header background color.
         * @defaultValue `COLORS.BACKGROUND_COLORS.GREY_LIGHTEST`
         */
        readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
        readonly children: React.ReactNode;
        readonly dismissIcon?: never;
        readonly onDismiss?: never;
      }
    | {
        /**
         * Header background color.
         * @defaultValue `COLORS.BACKGROUND_COLORS.GREY_LIGHTEST`
         */
        readonly backgroundColor?: BACKGROUND_COLORS_VALUES;
        readonly children: React.ReactNode;

        /**
         * Dismiss icon if you need to close the page or route back.
         * @defaultValue `'chevronleft'`
         */
        readonly dismissIcon?: 'chevronleft' | 'close';

        /**
         * Dismiss handler for closing the page or routing back.
         */
        readonly onDismiss: () => void;
      }
  );

const NavBarPlaceholder = () => {
  const { top } = useSafeAreaInsets();

  return (
    <ContentOffsetNavBarSpacing
      testID="header-offset"
      topInset={top}
    />
  );
};

const HeaderRoot = ({
  backgroundColor = COLORS.BACKGROUND_COLORS.GREY_LIGHTEST,
  CenterContent,
  children,
  dismissIcon = 'chevronleft',
  onDismiss,
  RightPressable,
}: HeaderRootProps) => {
  if (onDismiss) {
    return (
      <PageHeaderSection backgroundColor={backgroundColor}>
        <View testID="header-nav-bar">
          <NavBar
            backgroundColor={backgroundColor}
            CenterContent={CenterContent}
            LeftPressableAltoIcon={
              onDismiss ? (
                <PressableAltoIcon
                  name={dismissIcon}
                  onPress={onDismiss}
                  accessibilityLabel={dismissIcon === 'chevronleft' ? 'Press to go back' : 'Press to close'}
                />
              ) : undefined
            }
            position="relative"
            RightPressable={RightPressable}
          />
        </View>
        {children}
        <MdSpacing />
      </PageHeaderSection>
    );
  }

  return (
    <PageHeaderSection backgroundColor={backgroundColor}>
      <NavBarPlaceholder />
      {children}
      <MdSpacing />
    </PageHeaderSection>
  );
};

const HeaderContentRoot = ({ children }: { readonly children: ReactNode }) => {
  return (
    <MdPadding
      topPadding={SPACING.STATIC.XS}
      bottomPadding={SPACING.STATIC.XS}
    >
      {children}
    </MdPadding>
  );
};

const HeaderTitle = ({ children }: { readonly children: string }) => {
  return (
    <Row flexShrink={1}>
      <H1>{children}</H1>
    </Row>
  );
};

const EmptyState = ({
  ctaText,
  description,
  image,
  onPressCTA,
  onPressTertiaryCTA,
  tertiaryCtaText,
  title,
}: FullViewEmptyStateProps) => {
  const { isMDScreenOrBigger } = useScreenSize();

  return (
    <MaxWidthContent isMDScreenOrBigger={isMDScreenOrBigger}>
      <FullViewEmptyState
        ctaText={ctaText}
        description={description}
        image={image}
        onPressCTA={onPressCTA}
        onPressTertiaryCTA={onPressTertiaryCTA}
        tertiaryCtaText={tertiaryCtaText}
        title={title}
      />
    </MaxWidthContent>
  );
};

const withMaxWidthItem = <T extends object>(
  renderItem: ListRenderItem<T> | null | undefined,
  isMDScreenOrBigger: boolean,
): ListRenderItem<T> => {
  // eslint-disable-next-line react/prop-types,react/display-name
  return ({ item, index, separators }) => {
    return (
      <MaxWidthContent isMDScreenOrBigger={isMDScreenOrBigger}>
        {renderItem?.({ item, index, separators })}
      </MaxWidthContent>
    );
  };
};

const withMaxWidthSeparator = <P extends object>(
  SeparatorComponent: ComponentType<P>,
  isMDScreenOrBigger: boolean,
): ComponentType<P> => {
  // eslint-disable-next-line react/display-name
  return (props: P): ReactElement => {
    return (
      <MaxWidthContent isMDScreenOrBigger={isMDScreenOrBigger}>
        <SeparatorComponent {...props} />
      </MaxWidthContent>
    );
  };
};

const ContentFlatList = ({ renderItem, ItemSeparatorComponent, ...props }: FlatListProps<any>) => {
  const { isMDScreenOrBigger } = useScreenSize();
  const modifiedRenderItem = withMaxWidthItem(renderItem, isMDScreenOrBigger);
  const maxWidthItemSeparatorComponent = ItemSeparatorComponent
    ? withMaxWidthSeparator(ItemSeparatorComponent, isMDScreenOrBigger)
    : null;
  return (
    <FlatList
      {...props}
      renderItem={modifiedRenderItem}
      ItemSeparatorComponent={maxWidthItemSeparatorComponent}
    />
  );
};

const ContentLoader = ({ listItemHeight }: { readonly listItemHeight: number }) => {
  // we want to show a loader for the full height of the screen
  const { height } = useWindowDimensions();
  const loaderItemAmount = Math.floor(height / listItemHeight);
  const { isMDScreenOrBigger } = useScreenSize();

  return (
    <MaxWidthContent isMDScreenOrBigger={isMDScreenOrBigger}>
      {[...Array(loaderItemAmount)].map((_, index) => (
        <React.Fragment key={`med-loader-${index}`}>
          <RectLoader
            accessibilityLabel="Loading..."
            height={listItemHeight}
          />
          <Separator />
        </React.Fragment>
      ))}
    </MaxWidthContent>
  );
};

/**
 * The `ListPage` is our page wrapper specifically used for lists. Start by using a `<ListPage.Root>`.
 *
 * @see https://design-system-storybook.docs.alto.com/?path=/docs/pages-listpage--default
 *
 * This is an example of the basic structure of a List Page:
 * @example
 * <ListPage.Root>
 *  <ListPage.Content.FlatList
 *    ListHeaderComponent={
 *      <ListPage.Header.Root>
 *        <ListPage.Header.Content.Root>
 *          <ListPage.Header.Content.Title />
 *        </ListPage.Header.Content.Root>
 *      </ListPage.Header.Root>
 *    }
 *    renderItem={(item) => <ListPage.Content.ListItem {...item} />}
 *  />
 * </ListPage.Root>
 */
export const ListPage = {
  /**
   * The root wrapper of the List Page. All other components should be contained under this.
   *
   * This is an example of the basic structure of a List Page:
   * @example
   * <ListPage.Root>
   *  <ListPage.Content.FlatList
   *    ListHeaderComponent={
   *      <ListPage.Header.Root>
   *        <ListPage.Header.Content.Root>
   *          <ListPage.Header.Content.Title />
   *        </ListPage.Header.Content.Root>
   *      </ListPage.Header.Root>
   *    }
   *    renderItem={(item) => <ListPage.Content.ListItem {...item} />}
   *  />
   * </ListPage.Root>
   */
  Root,

  /**
   * List Page header. Start by using a `<ListPage.Header.Root>`. This
   * should be used as the `ListHeaderComponent` prop of the `FlatList`.
   *
   * The header component structure is as follows:
   * @example
   * <ListPage.Header.Root>
   *  <ListPage.Header.Content.Root>
   *    <ListPage.Header.Content.Title />
   *  </ListPage.Header.Content.Root>
   * </ListPage.Header.Root>
   */
  Header: {
    /**
     * The root wrapper of the List Page header.
     *
     * The header component structure is as follows:
     * @example
     * <ListPage.Header.Root>
     *  <ListPage.Header.Content.Root>
     *    <ListPage.Header.Content.Title />
     *  </ListPage.Header.Content.Root>
     * </ListPage.Header.Root>
     */
    Root: HeaderRoot,

    /**
     * List Page header content. Start by using a `<ListPage.Header.Content.Root>`.
     */
    Content: {
      /**
       * List Page header content root.
       *
       * The header content component structure is as follows:
       * @example
       *  <ListPage.Header.Content.Root>
       *    <ListPage.Header.Content.Title />
       *  </ListPage.Header.Content.Root>
       */
      Root: HeaderContentRoot,

      /**
       * List Page header title.
       */
      Title: HeaderTitle,
    },
  },

  /**
   * List Page content.
   */
  Content: {
    /**
     * The empty state component for the List Page that has the same props as the `FullViewEmptyState`.
     */
    EmptyState,

    /**
     * List Page content `FlatList`, which follows the same API as the React Native `FlatList`.
     * @see https://reactnative.dev/docs/flatlist
     */
    FlatList: ContentFlatList,

    /**
     * List Page content loader. You must pass in the height of the list item.
     */
    Loader: ContentLoader,
  },
};
