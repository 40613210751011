import { ConfirmationTypeMap } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/confirmation_type';
import { type Section } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/section';
import { SectionTypeMap } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/section_type';
import { type UnconfirmedShipment } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/unconfirmed_shipment';
import { PatientTaskTypeMap } from '@alto/scriptdash/alto/patient_app/patient_tasks/types/v1/patient_task_type';
import { PrescriptionStatusMap } from '@alto/scriptdash/alto/patient_app/types/v1/prescription_status';
import { isMail, isPickup } from '~shared/helpers/order';

type BackgroundColor = 'WARNING_LIGHTER' | 'TERTIARY_LIGHTEST';
type FillHomescreenBanner = {
  backgroundColor: BackgroundColor;
  copy: string;
};

const getConfirmShipmentTitle = (shipment: UnconfirmedShipment | null | undefined, patientName: string) => {
  if (!shipment) {
    // should not happen
    return `Hi ${patientName}, welcome to Alto`;
  }
  const { confirmation_types } = shipment;
  const isOnlyPriceConfirmation =
    confirmation_types.length === 1 && confirmation_types[0] === ConfirmationTypeMap.PATIENT_PAY;
  return `Hi ${patientName}, confirm your ${isOnlyPriceConfirmation ? 'price' : 'order details'}`;
};

// This function is responsible of the color and copy on the homescreen banner
export const fillHomescreenBanner = (
  sections: Section[],
  patientName: string,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): FillHomescreenBanner => {
  // We exclude using APP_PROMOS when determining the section banner
  const filteredSections = sections ? sections.filter((section) => section.type !== 'APP_PROMOS') : [];

  if (filteredSections[0]) {
    switch (filteredSections[0].type) {
      case SectionTypeMap.PATIENT_TASK:
        // eslint-disable-next-line sonarjs/no-nested-switch
        switch (filteredSections[0].patient_tasks?.[0].type) {
          case PatientTaskTypeMap.CONFIRM_SHIPMENT: {
            return {
              backgroundColor: 'WARNING_LIGHTER',
              copy: getConfirmShipmentTitle(filteredSections[0].patient_tasks[0].unconfirmed_shipment, patientName),
            };
          }

          case PatientTaskTypeMap.UPDATE_PAYMENT_METHOD: {
            return {
              backgroundColor: 'WARNING_LIGHTER',
              copy: `Hi ${patientName}, please update your payment info`,
            };
          }

          case PatientTaskTypeMap.NEEDS_INSURANCE: {
            return {
              backgroundColor: 'WARNING_LIGHTER',
              copy: `Hi ${patientName}, please update your insurance`,
            };
          }
        }

        break;

      case SectionTypeMap.UPCOMING_SHIPMENTS: {
        const shipment = filteredSections[0].shipments?.[0];
        const shipmentStatus = shipment?.status;
        const isMethodMail = isMail(shipment?.delivery_method_struct?.mode);

        if (shipmentStatus === 'shipped' && isMethodMail) {
          return {
            backgroundColor: 'TERTIARY_LIGHTEST',
            copy: `Hi ${patientName}, we've shipped your order`,
          };
        }

        return {
          backgroundColor: 'TERTIARY_LIGHTEST',
          copy: `Hi ${patientName}, we’re preparing your order`,
        };
      }

      case SectionTypeMap.DELIVERED_SHIPMENTS: {
        const shipment = filteredSections[0].shipments?.[0];
        const isMethodPickup = isPickup(shipment?.delivery_method_struct?.mode);

        if (isMethodPickup) {
          return {
            backgroundColor: 'TERTIARY_LIGHTEST',
            copy: `Hi ${patientName}, your order has been picked up!`,
          };
        }

        return {
          backgroundColor: 'TERTIARY_LIGHTEST',
          copy: `Hi ${patientName}, your order has arrived!`,
        };
      }

      case SectionTypeMap.NEW_PRESCRIPTIONS: {
        const firsStatus = filteredSections[0].prescriptions?.[0].status;

        if (firsStatus === PrescriptionStatusMap.READY) {
          return {
            backgroundColor: 'TERTIARY_LIGHTEST',
            copy: `Hi ${patientName}, your medication is ready to go`,
          };
        }

        return {
          backgroundColor: 'TERTIARY_LIGHTEST',
          copy: `Hi ${patientName}, you have a new prescription`,
        };
      }

      case SectionTypeMap.PENDING_TRANSFERS: {
        return {
          backgroundColor: 'TERTIARY_LIGHTEST',
          copy: `Hi ${patientName}, we’re working on your transfer`,
        };
      }

      case SectionTypeMap.OUT_OF_REFILLS: {
        return {
          backgroundColor: 'TERTIARY_LIGHTEST',
          copy: `Hi ${patientName}, your medication is out of refills`,
        };
      }

      case SectionTypeMap.DUE_FOR_REFILL: {
        return {
          backgroundColor: 'TERTIARY_LIGHTEST',
          copy: `Hi ${patientName}, your refill is ready to schedule`,
        };
      }

      default:
        break;
    }
  }

  return {
    backgroundColor: 'TERTIARY_LIGHTEST',
    copy: `Hi ${patientName}, welcome to Alto!`,
  };
};
