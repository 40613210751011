// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetContext, Border, Card, H2, LgPadding, PresentationListItem, Pressable } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { useNavigation } from '@alto/navigation';
import React, { useContext } from 'react';
import { ASSISTANT_PRESSED_EVENTS } from '~shared/features/alto-assistant/analytics/constants';
import {
  ASSISTANT_CATEGORIES,
  ASSISTANT_CHAT_ORIGINS,
  ASSISTANT_MESSAGE_SUBJECTS,
  MESSAGE_PROMPTS,
} from '~shared/features/alto-assistant/constants';
import { getItemMedNames } from '~shared/features/checkout/helpers';
import { useCourierAgeRestriction } from '~shared/features/checkout/hooks/useCourierAgeRestriction';
import { formatPaymentMethod } from '~shared/features/payment-methods/helpers';
import { isShipmentEditable } from '~shared/features/shipments/helpers/isShipmentEditable';
import { type ShipmentWithItemsAndAdditionalFields } from '~shared/features/shipments/types';
import { formatRelativeDate, formatWindowLong } from '~shared/helpers/date';
import { isExpensive } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useGetShipmentPaymentMethodsWithDetails } from '~shared/queries/query-keys/shipmentPaymentMethods';
import { useGetDeliveryMethodsByShipmentIDs } from '../../../../delivery-methods';
import { CreditCardRow, HomeToSignRow, LocationRow } from '../../../../info-rows';
import { UpcomingDeliveryActionSheet } from './UpcomingDeliveryActionSheet';

type Props = {
  readonly shipment: ShipmentWithItemsAndAdditionalFields;
};

type DeliveryChosenParams = {
  editable: boolean;
  delivery_ids?: number[];
  shipmentID: number;
};

export const UpcomingDeliveryCard = ({ shipment }: Props) => {
  const { trackEvent } = useAnalytics();
  const { navigate } = useNavigation();
  const {
    address,
    date,
    deliver_after,
    deliver_before,
    delivery_ids,
    home_to_sign_confirmation,
    id,
    is_pickup,
    items,
    safe_place,
    status,
    facility_id,
  } = shipment;

  const { deliveryMethodsByShipmentID } = useGetDeliveryMethodsByShipmentIDs([id]);
  const deliveryMethod = deliveryMethodsByShipmentID[id];
  const isEditable = isShipmentEditable(shipment);
  const shipmentPaymentMethods = useGetShipmentPaymentMethodsWithDetails({ shipmentID: id });
  const selectedPaymentMethods = shipmentPaymentMethods.filter((spm) => spm.shipment_id === id);
  const paymentMethodString = selectedPaymentMethods.map((spm) => formatPaymentMethod(spm.paymentMethod)).join(' and ');
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const courierAgeRestrictionEnabled = useCourierAgeRestriction(facility_id, deliveryMethod?.mode);

  const trackUpcomingDeliveryChosen = ({ delivery_ids, shipmentID, editable }: DeliveryChosenParams) => {
    trackEvent({
      event: EVENTS.ASSISTANT_COMPONENT_PRESSED,
      params: {
        name: ASSISTANT_PRESSED_EVENTS.UPCOMING_DELIVERY_CHOSEN,
        category: ASSISTANT_CATEGORIES.DELIVERIES,
        is_past_delivery: false,
        editable,
        shipmentID,
        delivery_ids,
      },
    });
  };

  const navigateToMessages = () => {
    trackUpcomingDeliveryChosen({ delivery_ids, shipmentID: id, editable: isEditable });
    navigate('RouteAssistantMessage', {
      origin: ASSISTANT_CHAT_ORIGINS.ALTO_ASSISTANT_CHOOSE_DELIVERY_MENU,
      category: 'deliveries.upcoming_delivery',
      messagePrompt: MESSAGE_PROMPTS.ORDER_IN_PROGRESS,
      subject: ASSISTANT_MESSAGE_SUBJECTS.UPCOMING_DELIVERY,
    });
  };

  const showDeliveryAssistant = () => {
    trackUpcomingDeliveryChosen({ delivery_ids, shipmentID: id, editable: isEditable });
    setActiveActionSheet(<UpcomingDeliveryActionSheet shipment={shipment} />);
  };

  const itemMedNames = getItemMedNames(items);
  const hasControlledItems = items.some((item) => item.isControl);
  const { value: useNewThreshold } = Experimentation.useFeatureFlag('use_new_expensive_threshold');
  const hasExpensiveItems = isExpensive(
    items.map((item) => item.prescription),
    useNewThreshold,
  );
  const handlePress = isEditable ? showDeliveryAssistant : navigateToMessages;

  return (
    // In order to have whole card be pressable, need to wrap Pressable in same radius as Card to keep rounded corners
    <Border
      radius="LG"
      hideTop
      hideBottom
      hideLeft
      hideRight
    >
      <Pressable
        onPress={handlePress}
        testID="upcoming-shipment-card"
      >
        <Card>
          <LgPadding
            leftPadding={SPACING.STATIC.NONE}
            rightPadding={SPACING.STATIC.NONE}
          >
            <LgPadding
              topPadding={SPACING.STATIC.NONE}
              bottomPadding={SPACING.STATIC.MD}
            >
              <H2>
                {formatRelativeDate(date || '')}
                {deliver_before && deliver_after ? `, ${formatWindowLong(deliver_after, deliver_before)}` : null}
              </H2>
            </LgPadding>
            <>
              <CreditCardRow copy={paymentMethodString} />
              {address ? (
                <LocationRow
                  address={address}
                  isPickup={is_pickup}
                  showMap={status !== 'delivered'}
                />
              ) : null}
              {itemMedNames ? (
                <PresentationListItem
                  iconName="pillbottle"
                  text={itemMedNames}
                />
              ) : null}
              <HomeToSignRow
                hasControlledMedication={hasControlledItems}
                hasExpensiveMedication={hasExpensiveItems}
                courierAgeRestrictionEnabled={courierAgeRestrictionEnabled}
                homeToSignConfirmation={home_to_sign_confirmation}
                safePlace={!!safe_place}
                method={deliveryMethod}
              />
            </>
          </LgPadding>
        </Card>
      </Pressable>
    </Border>
  );
};
