// @owners { team: patients-team }
import { COLORS, SIZES } from '@alto/design-library-tokens';
import { Body, LandingPage, LoadingEmptyState, MdSpacing, Row, XlPadding } from '@alto/design-system';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';
import { Image } from '~web/features/essentials/primitives';
import { useQueryCompletePageContent } from '~web/features/info-request/hooks';
import { useStatusBarColor } from '~web/features/info-request/hooks/useStatusBarColor';

type Props = {
  params: {
    hubReferralID: string;
  };
};

const CompletePageComponent = (props: Props) => {
  const { hubReferralID: hubReferralIDParam } = props.params || {};
  const hubReferralID = Number(hubReferralIDParam || 0);
  const { trackPageView } = useAnalytics();
  const dispatch = useDispatchShared();
  const { isFetching, completeHeader, completeBody, completeImageUrl, redirectURL } = useQueryCompletePageContent({
    hubReferralID,
  });

  useStatusBarColor(COLORS.BACKGROUND_COLORS.GREY_LIGHTEST);

  useEffect(() => {
    trackPageView({
      event: EVENTS.INFO_REQUEST__COMPLETE_PAGE_VIEWED,
      params: { hubReferralID },
    });
  }, [trackPageView, hubReferralID]);

  useEffect(() => {
    if (redirectURL) {
      dispatch(push(redirectURL));
    }
  }, [redirectURL, dispatch]);

  if (isFetching) {
    return (
      <XlPadding>
        <LoadingEmptyState />
      </XlPadding>
    );
  }

  return (
    <LandingPage
      withoutWebFramingElementHeights
      HeaderBodyCopy={<Body>{completeBody}</Body>}
      backgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      title={completeHeader || ''}
    >
      {completeImageUrl ? (
        <>
          <MdSpacing />
          <Row center>
            <Image
              src={completeImageUrl}
              alt={IMG_ALT_TEXTS.infoRequestCompletion}
              width={SIZES.ILLUSTRATION.XL.px}
            />
          </Row>
        </>
      ) : undefined}
    </LandingPage>
  );
};

export const CompletePage = withRouter(CompletePageComponent);
