// @owners { team: patients-team }
import { COLORS, SPACING } from '@alto/design-library-tokens';
import {
  Button,
  Column,
  Description,
  InlineAlert,
  InputCheckbox,
  InputText,
  MdPadding,
  MdSpacing,
  XlPadding,
  XsSpacing,
} from '@alto/design-system';
import { isAfter, isValid, parse } from 'date-fns';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Field, type FieldProps, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { PRIVACY_POLICY_URL, PRIVACY_PRACTICES_URL, TERMS_OF_SERVICE_URL } from '~shared/constants';
import { DATE_FORMATS, formatDateInput, getAge } from '~shared/helpers/date';
import { getErrorMessage } from '~shared/helpers/helper';
import { mapFormikFieldToInputProps } from '~shared/helpers/mapFieldToInputProps';
import { useClaimAccountViewed } from '~shared/lib/analytics/src/hooks/useClaimAccountViewed';
import { useSelectorShared } from '~shared/store';
import { createValidator, validatePresentWithMessage } from '~shared/validations';
import { PhoneSupport } from '~web/constants';

const validate = createValidator({
  last_name: validatePresentWithMessage('Last name'),
  date_of_birth: (value: string) => {
    const parsedDate = parse(value, DATE_FORMATS.DATE_INPUT_FORMAT, 0);

    if (value === '' || !isValid(parsedDate)) {
      return 'Please enter a valid date of birth in the MM/DD/YYYY format';
    }

    if (isAfter(parsedDate, new Date())) {
      return 'Please enter a date before today';
    }

    return null;
  },
  terms: (value: boolean) => {
    return value ? null : 'Please indicate that you’ve read and accept the terms above';
  },
});

type VerifyWrapperProps = {
  readonly children: React.ReactNode;
  readonly tempPassword: string;
};

const VerifyWrapper = ({ children, tempPassword }: VerifyWrapperProps) => {
  useClaimAccountViewed(tempPassword);

  return <>{children}</>;
};

export type Values = {
  last_name: string;
  date_of_birth: string;
  terms: boolean;
};

const initialValues = {
  last_name: '',
  date_of_birth: '',
  terms: false,
};

export type VerifyProps = {
  readonly disabled?: boolean;
  readonly onVerify: (userValues: Values) => Promise<void>;
  readonly excludeHeaderImage?: boolean;
  readonly tempPassword?: string;
};

export const Verify = ({ onVerify, disabled, tempPassword }: VerifyProps) => {
  const [dirty, setDirty] = useState(false);
  const validatingUserError = useSelectorShared((state) => state.ui.errors.validatingUserError);
  const verifyLoading = useSelectorShared((state) => state.ui.loading.validatingUserLoading);
  const infoRequestFlow = window.location.pathname.startsWith('/info/');

  return (
    <VerifyWrapper tempPassword={tempPassword ?? ''}>
      <Formik
        initialValues={initialValues}
        onSubmit={onVerify}
        validate={validate}
        validateOnBlur={dirty}
        validateOnChange={dirty}
        enableReinitialize
      >
        {(formikProps) => {
          const age = getAge(formikProps.values.date_of_birth);
          const termsCheckError = formikProps.errors.terms;
          return (
            <Form
              {...formikProps}
              noValidate
            >
              <Column>
                <Field name="last_name">
                  {({ field, meta }: FieldProps<Values['last_name']>) => {
                    return (
                      <InputText
                        label="Last name"
                        placeholder="Last name"
                        {...mapFormikFieldToInputProps(field, meta)}
                        error={meta.error}
                        required
                      />
                    );
                  }}
                </Field>
                <MdPadding />
                <Field name="date_of_birth">
                  {({ field, meta }: FieldProps<Values['date_of_birth']>) => (
                    <InputText
                      label="Date of birth"
                      aria-label="Enter your date of birth here in numbers, for example as 02 28 1991"
                      placeholder={DATE_FORMATS.DATE_INPUT_PLACEHOLDER}
                      {...mapFormikFieldToInputProps(field, meta)}
                      onChange={(date: any) => {
                        date.target.value = formatDateInput(date.target.value);
                        field.onChange(date);
                      }}
                      error={meta.error}
                      required
                    />
                  )}
                </Field>
                {!infoRequestFlow && !!age && age < 18 ? (
                  <>
                    <MdPadding
                      leftPadding={SPACING.STATIC.NONE}
                      rightPadding={SPACING.STATIC.NONE}
                    >
                      <InlineAlert>
                        <Description fontFamily="semibold">Are you under 18?</Description>
                        <Description>
                          If so, please have your parent or guardian give us a call at {PhoneSupport}.
                        </Description>
                      </InlineAlert>
                    </MdPadding>
                  </>
                ) : null}
                <XlPadding
                  leftPadding={SPACING.STATIC.NONE}
                  rightPadding={SPACING.STATIC.NONE}
                >
                  <InputCheckbox
                    name="terms"
                    label=""
                    fullBleed
                    value={formikProps.values.terms}
                    onPress={() => {
                      formikProps.setFieldValue('terms', !formikProps.values.terms);
                    }}
                    noHighlight
                    descriptions={
                      <Description>
                        I have read and accept the{' '}
                        <a
                          href={TERMS_OF_SERVICE_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </a>
                        ,{' '}
                        <a
                          href={PRIVACY_POLICY_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                        , and{' '}
                        <a
                          href={PRIVACY_PRACTICES_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Notice of Privacy Practices
                        </a>
                        .
                      </Description>
                    }
                  />
                  {termsCheckError ? (
                    <>
                      <XsSpacing />
                      <Description color={COLORS.TEXT_COLORS.DANGER}>{termsCheckError}</Description>
                    </>
                  ) : null}
                </XlPadding>
                <Button
                  disabled={disabled}
                  loading={verifyLoading}
                  loadingLabel="Loading..."
                  label="Continue"
                  onPress={() => {
                    setDirty(true);
                    formikProps.handleSubmit();
                  }}
                  width="full"
                />
                {validatingUserError ? (
                  <>
                    <MdSpacing />
                    <InlineAlert type="error">
                      <Description>
                        {getErrorMessage(validatingUserError) ||
                          'Verification failed - please check your info and try again.'}
                      </Description>
                    </InlineAlert>
                  </>
                ) : null}
              </Column>
            </Form>
          );
        }}
      </Formik>
    </VerifyWrapper>
  );
};
